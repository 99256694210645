.c-chargeTable {
  @at-root {
    & {
      width: 100%;
      text-align: left;
      border: 0;
      border-top: 0.5px solid $color--gray-light;
      border-collapse: collapse;
      font-size: rem(14);
      line-height: 1.4;

      th {
        padding: 10px;
        border-right: 0.5px solid $color--white-dark;
        border-bottom: 0.5px solid $color--gray-light;
        background-color: $color--white-dark-dark;
        font-weight: 500;
      }
      td {
        padding: 15px;
        background-color: #fff;
        border-right: 0.5px solid $color--white-dark;
        border-bottom: 0.5px solid $color--gray-light;
        background-color: $color--white-dark-light;
      }
      th,
      td {
        min-width: 84px;
      }
    }
  }
}