@charset "utf-8";

.estimate-01__content {
  section {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  h3 {
    margin-bottom: 20px;
    font-size: rem(20);
    font-weight: 700;
    line-height: 1.12;
  }
  p {
    font-size: rem(14);
  }
  .introduction {
    margin-bottom: 40px;

    @include bp('md') {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .introduction-logo {
    max-width: 283px;
    margin: auto;

    @include bp('md') {
      flex: 0 0 283px;
    }
  }
  .introduction-text {
    @include bp('md') {
      flex: 1;
      padding-left: 20px;
    }
  }

}
