
.u-listType--disc {
  li {
    display: flex;
    &::before {
      content: "・";
    }
  }
}

.u-listType--any {
  li {
    display: flex;
    & > .mark {
      &:first-child {
        flex: 0 0 1em;
        margin-right: 0.5em;
      }
    }
  }
}