.c-boxRadio {
  @at-root {

    & {
      position: relative;
      display: inline-block;
      min-width: 100px;
      padding: em(14) em(20) em(14) em(60);
      background-color: #fff;
      line-height: 1.3;
      user-select: none;
    }
    .c-boxRadio__label {
      display: inline-block;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 20px;
        display: inline-block;
        width: 1em;
        height: 1em;
        background: transparent url('../img/common/icon/radio.svg') 0 0 no-repeat;
        background-size: cover;
        font-size: rem(16);
        transform: translateY(-50%);

      }
    }

    & > input {
      appearance: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      font-size: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 5px;
      border: 1px solid #000;
      outline: none;
      cursor: pointer;

      @include focus() {
        border-width: 2px;
      }
      &:checked {
        & + .c-boxRadio__label {
          &::before {
            background-image: url('../img/common/icon/radio-checked.svg');
          }
        }
      }
    }
  }
}