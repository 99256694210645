.non-customer-edit {
  .ec-halfInput {
    & > .customer-form {
      width: calc(50% - 10px);
    }
  }
  .customer-address_pref {
    input {
      @include input-style();
      width: 100%;
      max-width: rem(264);
    }
  }
  .customer-phone_number {
    input {
      @include input-style();
      width: 100%;
    }
  }
}