.c-datalist {
  @at-root {
    & {
      width: 100%;
      text-align: left;
      border: 0;
      border-top: 0.5px solid $color--gray-light;
      border-collapse: collapse;
      font-size: rem(14);
      line-height: 1.4;

    }
    .c-datalist__item {

      @include bp('md') {
        display: flex;
      }

      dt {
        flex: 1;
        padding: 20px 10px;
        border-bottom: 1.5px solid $color--gray-light;
        background-color: $color--white-dark-dark;
        font-weight: 500;
      }
      dd {
        flex: 1;
        padding: 20px 10px;
        background-color: $color--white-dark-light;
        border-bottom: 0.5px solid $color--gray-light;

        @include bp('md') {
          padding-right: 20px;
          padding-left: 20px;
        }
      }
    }
  }

  @at-root {
    &.-input {
      dd {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
}