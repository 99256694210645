.front_page { //p-home
  @at-root {
    .p-home__sectionHeading {
      margin-bottom: 60px;

      @include bp('md') {
        margin-bottom: 30px;
      }
    }
    .p-home__listLink {
      margin-top: 34px;
      padding-right: 20px;
      text-align: right;

      @include bp('md') {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 6px;
      }

      a {
        display: inline-block;
        color: inherit;
        text-decoration: none;

        @include focus() {
          color: $color--red;
        }
      }
      i {
        margin-left: 10px;
        color: $color--red;
      }
    }
    .p-home__sectionSearch {
      margin-bottom: 60px;
    }
    .p-home__sectionCategory {
      margin-bottom: 60px;
    }
    .p-home__sectionRecommend {
      position: relative;
      margin-bottom: 60px;
    }
    .p-home__sectionEstimate {
      position: relative;
      margin-bottom: 80px;
    }
    .p-home__sectionNews {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}