.l-headerLogin {
  @at-root {
    // & {}
    .l-headerLogin__item {
      display: block;
      color: #000;
      font-size: rem(14);
      text-decoration: none;

      @include focus() {
        text-decoration: underline;
      }

      &:first-child {
        margin-bottom: 4px;
      }
      & > i {
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
}