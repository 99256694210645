.c-searchInput {
  @at-root {
    & {
      position: relative;
      font-size: rem(14);

      input {
        width: 100%;
        padding-right: rem(24);
        @include input-style();
      }
      i {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }
}