.p-drawerMenuButton {
  @at-root {
    & {
      border: 0;
      background-color: transparent;
    }
    .p-drawerMenuButton__icon {
      display: block;
      margin-bottom: 2px;
      font-size: rem(25);
    }
    .p-drawerMenuButton__label {
      display: block;
      font-size: rem(11);
    }
  }
}