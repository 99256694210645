/*
住所一覧

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [マイページ内 お届け先編集](http://demo3.ec-cube.net/mypage/delivery)

Markup:
include /assets/tmpl/elements/17.1.address.pug
+ec-addressList
+ec-addressRole

sg-wrapper:
<div class="ec-addressRole">
  <sg-wrapper-content/>
</div>

Styleguide 17.1

*/
.ec-addressRole {
  @at-root {
    .ec-addressRole__item {
      border-top: 1px dotted $color--gray;
    }
    .ec-addressRole__actions {
      margin-top: 32px;
      padding-bottom: 20px;
      border-bottom: 1px dotted $color--gray;
    }
  }
}

.ec-addressList {
  @at-root {
    .ec-addressList__item {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid $color--gray;

      @include bp('md') {
        padding: 30px 20px;
      }
      &:first-child {
        border-top: 1px solid $color--gray;
      }
    }
    .ec-addressList__remove {
      margin-right: 10px;
      padding: 10px;
      color: $color--gray;
      font-size: rem(14);
      text-decoration: none;
      text-align: center;

      @include bp('md') {
        margin-right: 20px;
      }
      .ec-icon img {
        width: 1em;
        height: 1em;
      }
    }
    .ec-addressList__address {
      flex: 1;
      width: 80%;
      font-size: rem(14);
    }
    .ec-addressList__addressName {
      margin-bottom: 20px;
      font-size: rem(18);
      font-weight: 700;
      line-height: 1.3;
    }
    .ec-addressList__action {
      flex: 0 0 rem(62);
      align-self: flex-end;

      a {
        display: block;
        width: rem(62);
        font-size: rem(14);
        text-align: center;
      }
    }
  }
}
