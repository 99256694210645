.p-cautionBox {
  @at-root {
    & {
      padding: 16px;
      border: 2px solid $color--red;
      font-size: rem(14);
    }
    .p-cautionBox__title {
      color: $color--red;
      font-size: rem(14);
      font-weight: 700;
    }
  }
}