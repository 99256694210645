.p-tryEstimate {
  @at-root {
    & {
      max-width: 890px;
      margin: 100px auto 0;
      padding: 60px 40px;
      background-color: $color--white-dark;
    }
    .p-tryEstimate__heading {
      margin-bottom: 40px;
      color: $color--red;
      font-size: rem(24);
      text-align: center;
    }
    .p-tryEstimate__text {
      font-size: rem(14);
    }
    .p-tryEstimate__box {
      margin-top: 30px;
      border-top: 1px solid $color--red;
      text-align: center;
    }
    .p-tryEstimate__link {
      margin-top: 60px;

      a {
        position: relative;
        display: block;
        max-width: rem(384);
        margin: auto;
        padding: 24px 36px;
        font-size: rem(18);

        i {
          position: absolute;
          top: 50%;
          right: 30px;
          transform: translateY(-50%);
        }
      }
    }
  }
}