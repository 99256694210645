.c-dashBtn {
  @at-root {

    & {
      position: relative;
      display: block;
      padding: 10px 30px;
      border-radius: 4px;
      background-color: $color--white-dark;
      box-shadow: 1px 2px 4px rgba(0,0,0,0.2);
      color: inherit;
      font-size: rem(14);
      text-decoration: none;
      text-align: center;
      user-select: none;

      @include focus() {
        box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
      }
      i {
        position: absolute;
        top: 50%;
        right: 10px;
        display: block;
        color: $color--gray;
        transform: translateY(-50%);
      }
    }
  }
}