// @use "sass:math";

.p-guideMenu {
  @at-root {
    & {
      border: 1px solid $color--gray;
      border-top: 0;
      border-right: 0;

      @include bp('md') {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .p-guideMenu__item {
      border: 1px solid $color--gray;
      border-bottom: 0;
      border-left: 0;
      background-color: #fff;

      @include bp('md') {
        flex: 1 0 100%;
        max-width: 50%;
      }
      @include bp('md-lg') {
        max-width: 25%;
      }
      a {
        position: relative;
        display: block;
        padding: 15px 30px;
        color: inherit;
        font-size: rem(14);
        text-decoration: none;

        @include focus() {
          background-color: $color--white-dark;
        }
        .arrow-icon {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);

          &::before {
            display: inline-block;
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}