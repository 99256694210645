@charset "UTF-8";
@import url("../../../node_modules/normalize.css/normalize.css");
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  color: #000;
  font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Yu Gothic", sans-serif;
  font-weight: 500;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, p, dl {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  width: 100%;
  height: auto;
}

*::before,
*::after {
  box-sizing: inherit;
}

main {
  display: block;
}

svg,
img {
  max-width: 100%;
  vertical-align: bottom;
}

figure {
  margin: 0;
}

option {
  padding: 0;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

li {
  list-style: none;
}

dd {
  margin-left: 0;
}

i,
address {
  font-style: normal;
}

a,
button,
label {
  cursor: pointer;
}

/* reset fieldset and legend */
legend {
  display: table;
  padding: 0;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0.01em 0 0 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

@font-face {
  font-family: "sppec-icon";
  src: url("../fonts/sppec-icon.ttf?6vfyeb") format("truetype"), url("../fonts/sppec-icon.woff?6vfyeb") format("woff"), url("../fonts/sppec-icon.svg?6vfyeb#sppec-icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=iconm-], [class*=" iconm-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "sppec-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconm-exclamation-circle:before {
  content: "";
}

.iconm-external-link:before {
  content: "";
}

.iconm-heart:before {
  content: "";
}

.iconm-cross-bold:before {
  content: "";
}

.iconm-cross:before {
  content: "";
}

.iconm-email:before {
  content: "";
}

.iconm-home:before {
  content: "";
}

.iconm-lock:before {
  content: "";
}

.iconm-menu:before {
  content: "";
}

.iconm-person:before {
  content: "";
}

.iconm-search:before {
  content: "";
}

.iconm-cart:before {
  content: "";
}

.iconm-arrow:before {
  content: "";
}

.iconm-double-arrow:before {
  content: "";
}

.iconm-hard-hat:before {
  content: "";
  color: #4b4b4b;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/common/icon/ajax-loader.gif") center center no-repeat;
}

/* Icons */
/* Arrows */
/* Dots */
/* Dots */
.slick-dotted.slick-slider {
  padding-bottom: 32px;
}

.slick-dots {
  position: absolute;
  bottom: 0;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  line-height: 1;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin: 0 8px;
  padding: 0;
  font-size: 12px;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  width: 1em;
  height: 1em;
  outline: none;
  color: transparent;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: currentColor;
  text-align: center;
  color: #CF0027;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: #CF0027;
  opacity: 0.75;
}

.l-headerLogin__item {
  display: block;
  color: #000;
  font-size: 0.875rem;
  text-decoration: none;
}
.l-headerLogin__item:hover, .l-headerLogin__item:focus {
  text-decoration: underline;
}
.l-headerLogin__item:first-child {
  margin-bottom: 4px;
}
.l-headerLogin__item > i {
  display: inline-block;
  margin-right: 8px;
}

.l-headerMenu {
  background-color: #F5F5F5;
}
.l-headerMenu__list {
  display: flex;
  justify-content: center;
  max-width: 660px;
  margin: auto;
}

.l-headerMenu__item {
  flex: 0 0 calc(100% / 4);
  position: relative;
  padding-right: 2px;
  text-align: center;
}
.l-headerMenu__item::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 2px;
  height: 60px;
  background-color: #C8C8C8;
  transform: translateY(-50%);
}
.l-headerMenu__item i {
  display: block;
  min-height: 40px;
  margin-bottom: 4px;
}
.l-headerMenu__item a {
  display: block;
  padding: 18px 15px 14px;
  color: inherit;
  font-size: 0.875rem;
  text-decoration: none;
  line-height: 1.3;
}
.l-headerMenu__item a:hover, .l-headerMenu__item a:focus {
  background-color: #fff;
}
.l-headerMenu__item:last-child {
  padding-right: 0;
}
.l-headerMenu__item:last-child::after {
  content: none;
}
.l-headerMenu__item.-products img {
  max-width: 33px;
}
.l-headerMenu__item.-estimate i {
  padding-top: 4px;
}
.l-headerMenu__item.-estimate img {
  max-width: 22px;
}
.l-headerMenu__item.-siteinfo i {
  padding-top: 8px;
}
.l-headerMenu__item.-siteinfo img {
  max-width: 29px;
}
.l-headerMenu__item.-guide i {
  padding-top: 7px;
}
.l-headerMenu__item.-guide img {
  max-width: 15px;
}

.l-drawerMenu {
  max-width: 308px;
}

.l-drawerMenu__header {
  display: flex;
  align-items: center;
  padding: 35px 20px;
}

.l-drawerMenu__headerLogo {
  max-width: 96px;
  margin-right: 45px;
}
.l-drawerMenu__headerLogo a {
  transition: opacity 0.2s;
}
.l-drawerMenu__headerLogo a:hover, .l-drawerMenu__headerLogo a:focus {
  opacity: 0.65;
}

.l-drawerMenu__headerCartLink {
  flex: 1;
  max-width: 128px;
}
.l-drawerMenu__headerCartLink a {
  width: 100%;
  font-size: 0.875rem;
  text-align: center;
}

.l-mypageLayout__header {
  margin-bottom: 60px;
  color: #BF0030;
  text-align: center;
}
.l-mypageLayout__header::after {
  content: "";
  display: block;
  width: 184px;
  height: 4px;
  margin: 26px auto 0;
  background-color: currentColor;
}

.l-mypageLayout__headerLabel {
  color: inherit;
  font-size: 1.5rem;
  font-weight: 700;
}

.l-mypageLayout__nav {
  margin-bottom: 60px;
}

.l-mypageLayout__greeting {
  margin-bottom: 60px;
  font-size: 0.875rem;
  text-align: center;
}

.l-mypageNav__list {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #000;
  border-right: 0;
  border-bottom: 0;
}

.l-mypageNav__item {
  flex: 1 0 auto;
  width: 100%;
  max-width: 50%;
  border: 1px solid #000;
  border-top: 0;
  border-left: 0;
  text-align: center;
}
@media screen and (min-width: 48em) {
  .l-mypageNav__item {
    max-width: 20%;
  }
}
.l-mypageNav__item a {
  display: block;
  padding: 15px;
  color: inherit;
  font-size: 0.875rem;
  text-decoration: none;
  user-select: none;
}
.l-mypageNav__item a:hover, .l-mypageNav__item a:focus {
  text-decoration: underline;
}
.l-mypageNav__item.is-current {
  color: #BF0030;
}

.l-page__header {
  border-bottom: 2px solid #CF0027;
}

.l-page__container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  max-width: 1192px;
  margin: 50px auto 0;
}
@media screen and (min-width: 85.375em) {
  .l-page__container {
    flex-direction: row;
  }
}

.l-page__containerMain {
  width: 100%;
  margin: 0 auto 150px;
}

.l-page__containerSide {
  flex: 0 0 auto;
  width: 100%;
  max-width: 304px;
  padding-right: 40px;
  margin-bottom: 150px;
}
@media screen and (min-width: 85.375em) {
  .l-page__containerSide + .l-page__containerMain {
    max-width: calc(100% - 264px);
  }
}

.l-page__breadcrumbBottom {
  padding: 15px 20px;
  background-color: #F5F5F5;
}
.l-page__breadcrumbBottom .p-breadcrumb {
  max-width: 1192px;
  margin: auto;
}

.l-page__drawerMenu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 90%;
  max-width: 308px;
  height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
  transform: translateX(-101%);
  transition: all 0.3s;
}
.l-page__drawerMenu.is-active {
  transform: translateX(0);
}

.l-page__drawerMenuClose {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1100;
  display: none;
  border: 0;
  background-color: transparent;
  color: #fff;
  font-size: 1.25rem;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.65);
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}
.l-page__drawerMenuClose.is-active {
  display: inline-block;
}

.l-page__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100%;
  height: 100vh;
  visibility: hidden;
  background: transparent;
  opacity: 0;
  transform: translateX(0);
  transition: all 0.3s;
}

@media screen and (max-width: 47.99875em) {
  body.front_page .l-page__container {
    margin-top: 0;
  }
}

body.have_curtain .l-page__overlay {
  display: block;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  visibility: visible;
}

.l-siteFooter {
  border-top: 6px solid #BF0030;
  background-color: #F5F5F5;
}
.l-siteFooter__inner {
  max-width: 920px;
  padding: 74px 20px 22px;
  margin: auto;
}
@media screen and (min-width: 85.375em) {
  .l-siteFooter__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media screen and (min-width: 85.375em) {
  .l-siteFooter__head {
    max-width: 24rem;
  }
}

.l-siteFooter__text {
  color: #787878;
  font-size: 0.6875rem;
}

.l-siteFooter__logo {
  max-width: 96px;
  padding-bottom: 20px;
}

.l-siteFooter__logoBorder {
  width: 100%;
  height: 1px;
  margin-bottom: 20px;
  border-bottom: 1px solid #CF0027;
}

.l-siteFooter__nav {
  margin-top: 60px;
  margin-bottom: 100px;
}
@media screen and (min-width: 25.875em) {
  .l-siteFooter__nav {
    display: flex;
  }
}
@media screen and (min-width: 85.375em) {
  .l-siteFooter__nav {
    margin-top: 0;
  }
}

.l-siteFooter__menu {
  margin-bottom: 40px;
}
@media screen and (min-width: 25.875em) {
  .l-siteFooter__menu {
    min-width: 182px;
  }
}
.l-siteFooter__menu:last-child {
  margin-bottom: 0;
}

.l-siteFooter__menuTitle {
  margin-bottom: 20px;
  font-size: 1rem;
}

.l-siteFooter__menuList {
  padding-left: 10px;
}
.l-siteFooter__menuList li {
  margin-bottom: 12px;
  font-size: 0.875rem;
}
.l-siteFooter__menuList li:last-child {
  margin-bottom: 0;
}
.l-siteFooter__menuList a {
  color: inherit;
  text-decoration: none;
}
.l-siteFooter__menuList a:hover, .l-siteFooter__menuList a:focus {
  text-decoration: underline;
}

.l-siteFooter__copyright {
  width: 100%;
  color: #787878;
  font-size: 0.6875rem;
}
@media screen and (min-width: 25.875em) {
  .l-siteFooter__copyright {
    text-align: center;
  }
}

@media screen and (min-width: 85.375em) {
  .l-sidebar {
    max-width: 308px;
  }
}
@media screen and (min-width: 85.375em) {
  .l-sidebar > div {
    margin-bottom: 30px;
  }
}

.p-navSearch {
  padding: 36px 20px;
  background-color: #F5F5F5;
}
.p-navSearch > div {
  max-width: 400px;
  margin: auto;
}

.l-siteHeader {
  max-width: 1366px;
  margin: auto;
}
@media screen and (max-width: 62.5em) {
  .l-siteHeader {
    position: relative;
    padding-top: 32px;
  }
}

.l-siteHeader__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

.l-siteHeader__notice {
  height: 2rem;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 4px;
  background-color: #BF0030;
  color: #fff;
  font-size: 0.75rem;
  text-align: center;
  line-height: 2rem;
}
@media screen and (max-width: 62.5em) {
  .l-siteHeader__notice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
  }
}
@media screen and (min-width: 48em) {
  .l-siteHeader__notice {
    padding-right: 30px;
    padding-left: 30px;
    font-size: 0.875rem;
  }
}

.l-siteHeader__logo {
  flex: 0 1 auto;
  max-width: 94px;
}
@media screen and (min-width: 50em) {
  .l-siteHeader__logo {
    max-width: 140px;
  }
}
@media screen and (min-width: 62.5em) {
  .l-siteHeader__logo {
    margin-left: auto;
  }
}

@media screen and (min-width: 48em) {
  .l-siteHeader__login {
    margin-right: 30px;
    margin-left: auto;
  }
}

.l-siteHeader.-withoutUserData .l-siteHeader__inner {
  height: 90px;
}
@media screen and (min-width: 50em) {
  .l-siteHeader.-withoutUserData .l-siteHeader__logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.c-attentionBox {
  padding: 20px 16px;
  border-radius: 6px;
  background-color: #F5F5F5;
}

.c-attentionBox__heading {
  position: relative;
  margin-bottom: 10px;
  padding: 4px 10px;
  background-color: #787878;
  color: #fff;
}
.c-attentionBox__heading .heading-label {
  font-size: 0.875rem;
}

.c-attentionBox__text {
  font-size: 0.8125rem;
}

.c-attentionBox.-theme-caution .c-attentionBox__heading {
  padding-left: 30px;
  background-color: #BF0030;
}
.c-attentionBox.-theme-caution .c-attentionBox__heading::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 8px;
  display: block;
  font-family: "sppec-icon" !important;
  transform: translateY(-50%);
}
.c-attentionBox.-theme-caution .c-attentionBox__text {
  color: #BF0030;
}

.c-btn {
  display: inline-block;
  border-radius: 0.25rem;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: inherit;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
  padding: 10px;
  border-radius: 4px;
  border: 0;
  background-color: #F5F5F5;
}
.c-btn:hover, .c-btn:focus {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.c-btn > i {
  display: inline-block;
  vertical-align: middle;
}

.c-btn.-red {
  background-color: #BF0030;
  color: #fff;
}

.c-btn.-gray {
  background-color: #787878;
  color: #fff;
}

.c-btn.-gray-light {
  background-color: #C8C8C8;
  color: #787878;
}

.c-btn.-btn-md {
  width: 100%;
  max-width: 17.75rem;
}

.c-btn.-btn-lg {
  width: 100%;
  max-width: 20.875rem;
}
@media screen and (min-width: 48em) {
  .c-btn.-btn-lg {
    max-width: 24rem;
  }
}

.c-back {
  border: 0;
  background-color: transparent;
  color: #787878;
  text-decoration: none;
  transition: opacity 0.2s;
}
.c-back:hover, .c-back:focus {
  opacity: 0.65;
}
.c-back > i::before {
  display: inline-block;
  margin-right: 16px;
  margin-top: -0.25em;
  transform: scale(-1);
  vertical-align: middle;
}

.c-card {
  height: 100%;
  padding: 1px 6px 6px;
}

.c-card__anchor {
  display: block;
  color: inherit;
  text-decoration: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.24);
  transition: box-shadow 0.2s;
}
.c-card__anchor:hover, .c-card__anchor:focus {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);
}

.c-card__top {
  position: relative;
  padding: 20px;
  background-color: #fff;
  text-align: center;
}

.c-card__tags {
  position: absolute;
  top: 5px;
  left: 5px;
}

.c-card__tagsItem {
  display: inline-block;
  margin-right: 2px;
  padding: 2px 8px;
  border: 2px solid currentColor;
  background-color: rgba(255, 255, 255, 0.5);
  color: #BF0030;
  font-size: 0.6875rem;
  text-align: center;
  line-height: 1.4;
}

.c-card__visual {
  display: inline-block;
  max-width: 160px;
  height: 160px;
}
.c-card__visual img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-card__bottom {
  padding: 20px;
  background-color: #F5F5F5;
  line-height: 1.4;
}

.c-card__name {
  margin-bottom: 5px;
  font-size: 0.875rem;
}

.c-card__price {
  font-size: 1.125rem;
}
.c-card__price span {
  display: inline-block;
  margin-left: 4px;
}

.c-card__detail {
  display: block;
  max-width: 120px;
  margin: 18px auto 0;
  padding: 4px 20px;
  border-radius: 0.875rem;
  border: 1px solid #000;
  font-size: 0.75rem;
  text-align: center;
}

.c-chargeTable {
  width: 100%;
  text-align: left;
  border: 0;
  border-top: 0.5px solid #C8C8C8;
  border-collapse: collapse;
  font-size: 0.875rem;
  line-height: 1.4;
}
.c-chargeTable th {
  padding: 10px;
  border-right: 0.5px solid #F5F5F5;
  border-bottom: 0.5px solid #C8C8C8;
  background-color: #E6E6E6;
  font-weight: 500;
}
.c-chargeTable td {
  padding: 15px;
  background-color: #fff;
  border-right: 0.5px solid #F5F5F5;
  border-bottom: 0.5px solid #C8C8C8;
  background-color: #FAFAFA;
}
.c-chargeTable th,
.c-chargeTable td {
  min-width: 84px;
}

.c-dashBtn {
  position: relative;
  display: block;
  padding: 10px 30px;
  border-radius: 4px;
  background-color: #F5F5F5;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: inherit;
  font-size: 0.875rem;
  text-decoration: none;
  text-align: center;
  user-select: none;
}
.c-dashBtn:hover, .c-dashBtn:focus {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.c-dashBtn i {
  position: absolute;
  top: 50%;
  right: 10px;
  display: block;
  color: #787878;
  transform: translateY(-50%);
}

.c-datalist {
  width: 100%;
  text-align: left;
  border: 0;
  border-top: 0.5px solid #C8C8C8;
  border-collapse: collapse;
  font-size: 0.875rem;
  line-height: 1.4;
}

@media screen and (min-width: 48em) {
  .c-datalist__item {
    display: flex;
  }
}
.c-datalist__item dt {
  flex: 1;
  padding: 20px 10px;
  border-bottom: 1.5px solid #C8C8C8;
  background-color: #E6E6E6;
  font-weight: 500;
}
.c-datalist__item dd {
  flex: 1;
  padding: 20px 10px;
  background-color: #FAFAFA;
  border-bottom: 0.5px solid #C8C8C8;
}
@media screen and (min-width: 48em) {
  .c-datalist__item dd {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.c-datalist.-input dd {
  padding-top: 15px;
  padding-bottom: 15px;
}

.c-itemSlider__visual {
  margin-bottom: 20px;
}
.c-itemSlider__visual .slide-item {
  position: relative;
}
.c-itemSlider__visual .slide-item::before {
  content: "";
  display: block;
  padding-top: 79.175%;
}
.c-itemSlider__visual .slide-item img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-itemSlider__nav .slide-thumb {
  margin-right: 4px;
  margin-left: 4px;
}
.c-itemSlider__nav .slide-thumb {
  border: 2px solid transparent;
  cursor: pointer;
}
.c-itemSlider__nav .slick-current {
  border-color: #BF0030;
}
.c-itemSlider__nav .slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 100;
  display: block;
  height: 24px;
  border: 0;
  background-color: transparent;
  color: #fff;
  font-size: 0;
  text-align: center;
  transform: translateY(-50%);
  opacity: 0.75;
}
.c-itemSlider__nav .slick-arrow::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
  background: #fff url("../img/common/icon/arrow-dropright-circle.svg") 0 0 no-repeat;
}
.c-itemSlider__nav .slick-prev {
  left: 0;
}
.c-itemSlider__nav .slick-prev::before {
  transform: scale(-1);
}
.c-itemSlider__nav .slick-next {
  right: 0;
}
.c-itemSlider__nav .slick-disabled {
  opacity: 0.35;
}

.c-listItem {
  position: relative;
}

.c-listItem__top {
  position: relative;
}

.c-listItem__anchor {
  display: block;
  color: inherit;
  text-decoration: none;
}

.c-listItem__tags {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 12;
}

.c-listItem__tagsItem {
  display: inline-block;
  margin-right: 2px;
  padding: 2px 8px;
  border: 2px solid currentColor;
  background-color: #F5F5F5;
  color: #BF0030;
  font-size: 0.6875rem;
  text-align: center;
  line-height: 1.4;
}

.c-listItem__visual::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.c-listItem__visual img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-listItem__bottom {
  padding-top: 20px;
  line-height: 1.4;
}

.c-listItem__name {
  margin-bottom: 10px;
  font-size: 0.875rem;
  font-weight: 700;
}

.c-listItem__price {
  font-size: 0.875rem;
}
.c-listItem__price span {
  display: inline-block;
  margin-left: 4px;
}

.c-listItem__delete {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 20;
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background-color: #787878;
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.4);
  color: #fff;
  font-size: 0.75rem;
  text-decoration: none;
  line-height: 1.75rem;
  text-align: center;
}

.c-listItem__anchor:hover .c-listItem__bottom, .c-listItem__anchor:focus .c-listItem__bottom {
  text-decoration: underline;
}

.ec-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
}

.ec-modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.ec-modal-wrap {
  position: relative;
  width: 100%;
  max-width: 90%;
  margin: auto;
  padding: 60px 20px;
  background-color: #fff;
  box-shadow: 3px 6px 12px rgba(0, 0, 0, 0.3);
}
@media screen and (min-width: 48em) {
  .ec-modal-wrap {
    max-width: 586px;
    padding: 60px 40px;
  }
}

.ec-modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  border: 0;
  background-color: transparent;
  color: #787878;
}

.ec-modal-actions {
  margin: -15px -5px;
  text-align: center;
}
.ec-modal-actions a, .ec-modal-actions button {
  display: inline-block;
  min-width: 224px;
  margin: 15px 5px;
  padding: 15px 40px;
  font-size: 1.125rem;
}

.c-navHeader {
  padding: 16px 30px;
  background-color: #BF0030;
  color: #fff;
  line-height: 1.4;
}
.c-navHeader .label-en {
  margin-bottom: 4px;
  font-size: 0.875rem;
}
.c-navHeader .label-ja {
  font-size: 1rem;
  font-weight: 700;
}

.c-navHeader.-theme-gray {
  background-color: #787878;
}

.c-newsItem {
  background-color: #fff;
}

.c-newsItem__head {
  position: relative;
  padding: 10px 40px 10px 20px;
}
.c-newsItem__head[role=button] {
  cursor: pointer;
}
@media screen and (min-width: 48em) {
  .c-newsItem__head {
    display: flex;
  }
}

.c-newsItem__headDate {
  color: #787878;
  font-size: 0.875rem;
}
@media screen and (min-width: 48em) {
  .c-newsItem__headDate {
    margin-right: 60px;
  }
}

.c-newsItem__headTitle {
  font-size: 0.875rem;
  font-weight: 500;
}

.c-newsItem__headIcon {
  position: absolute;
  top: 50%;
  right: 20px;
  display: block;
  transform: translateY(-50%);
}
.c-newsItem__headIcon i::before {
  display: inline-block;
  transform: rotate(90deg);
  transition: transform 0.2s;
}

.c-newsItem__content__inner {
  padding: 10px 20px;
  font-size: 0.875rem;
}
.c-newsItem__content__inner a {
  color: inherit;
}
.c-newsItem__content__inner a:hover, .c-newsItem__content__inner a:focus {
  text-decoration: none;
}

.c-newsItem[aria-expanded=true] .c-newsItem__headIcon i::before {
  transform: rotate(-90deg);
}

.c-pdflink {
  position: relative;
  display: inline-block;
  padding: 9px 30px 9px 60px;
  border-radius: 4px;
  border: 1px solid #000;
  background: linear-gradient(#fff, #f0f0f0);
  color: inherit;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
}
.c-pdflink::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 28px;
  display: block;
  width: 17px;
  height: 20px;
  background: transparent url("../img/common/icon/pdf.svg") 0 0 no-repeat;
  transform: translateY(-50%);
}
.c-pdflink:hover, .c-pdflink:focus {
  background: linear-gradient(#f0f0f0, #fff);
}

.c-textLink {
  color: inherit;
  font-size: inherit;
  text-decoration: underline;
}
.c-textLink:hover, .c-textLink:focus {
  text-decoration: none;
}

.c-textLink.-primary {
  color: #0057A9;
}

.c-videoFrame {
  position: relative;
  max-width: 560px;
}
.c-videoFrame::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.c-videoFrame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-boxRadio {
  position: relative;
  display: inline-block;
  min-width: 100px;
  padding: 0.875em 1.25em 0.875em 3.75em;
  background-color: #fff;
  line-height: 1.3;
  user-select: none;
}

.c-boxRadio__label {
  display: inline-block;
}
.c-boxRadio__label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 20px;
  display: inline-block;
  width: 1em;
  height: 1em;
  background: transparent url("../img/common/icon/radio.svg") 0 0 no-repeat;
  background-size: cover;
  font-size: 1rem;
  transform: translateY(-50%);
}

.c-boxRadio > input {
  appearance: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #000;
  outline: none;
  cursor: pointer;
}
.c-boxRadio > input:hover, .c-boxRadio > input:focus {
  border-width: 2px;
}
.c-boxRadio > input:checked + .c-boxRadio__label::before {
  background-image: url("../img/common/icon/radio-checked.svg");
}

.c-checkText {
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
  user-select: none;
}
.c-checkText > input {
  margin-right: 10px;
}

.c-checkText__link {
  display: inline-block;
  margin-right: 5px;
  color: inherit;
}
.c-checkText__link:hover, .c-checkText__link:focus {
  text-decoration: none;
}
.c-checkText__link i {
  display: inline-block;
  margin-left: 5px;
  font-size: 0.875em;
}

.c-checkbox {
  cursor: pointer;
}

.c-halfInput {
  display: flex;
  max-width: 100%;
}
@media screen and (min-width: 48em) {
  .c-halfInput {
    max-width: 464px;
  }
}
.c-halfInput input {
  width: 100%;
}
.c-halfInput > *:first-child {
  margin-right: 10px;
}
@media screen and (min-width: 25.875em) {
  .c-halfInput > *:first-child {
    margin-right: 20px;
  }
}

.p-prefSelect {
  max-width: 15.625rem;
}

.c-select {
  padding: 0.5rem 10px;
  border-radius: 5px;
  border: 1px solid #000;
  font-size: inherit;
}
.c-select.-full {
  width: 100%;
}

.c-searchInput {
  position: relative;
  font-size: 0.875rem;
}
.c-searchInput input {
  width: 100%;
  padding-right: 1.5rem;
  max-width: 100%;
  padding: 0.5625rem 14px;
  line-height: 1.4;
  border-radius: 0.3125rem;
  border: 1px solid #000;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.4);
}
.c-searchInput input::placeholder {
  color: #C8C8C8;
  font-size: 0.875rem;
}
.c-searchInput i {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.c-textbox {
  max-width: 100%;
  padding: 0.5625rem 14px;
  line-height: 1.4;
  border-radius: 0.3125rem;
  border: 1px solid #000;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.4);
}
.c-textbox::placeholder {
  color: #C8C8C8;
  font-size: 0.875rem;
}
.c-textbox.-full {
  width: 100%;
}

.c-textarea {
  max-width: 100%;
  padding: 0.5625rem 14px;
  line-height: 1.4;
  border-radius: 0.3125rem;
  border: 1px solid #000;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.4);
}
.c-textarea::placeholder {
  color: #C8C8C8;
  font-size: 0.875rem;
}
.c-textarea.-full {
  width: 100%;
}

.p-zipInput input {
  max-width: 13.875rem;
}

.c-headingPrimary {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 889px;
  min-height: 120px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 40px;
  border: 4px solid currentColor;
  background-color: #fff;
  color: #BF0030;
  text-align: center;
}
.c-headingPrimary::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 36px 36px 0 0;
  border-color: transparent;
  border-top-color: currentColor;
}

.c-headingPrimary__label {
  font-size: 1.5rem;
  font-weight: 700;
}

.c-headingSecondary {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 4px solid #BF0030;
  line-height: 1.4;
}
.c-headingSecondary .label-en {
  display: inline-block;
  margin-right: 5px;
  color: #BF0030;
  font-size: 1.25rem;
}
.c-headingSecondary .label-ja {
  display: inline-block;
  font-weight: 700;
  font-size: 1.25rem;
}

.c-headingTertiary {
  border-left: 4px solid #BF0030;
  background-color: #F5F5F5;
}

.c-headingTertiary__label {
  padding: 12px 20px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

.c-headingQuaternary {
  display: flex;
  align-items: flex-end;
  min-height: 1.6875rem;
  padding-left: 15px;
  border-left: 4px solid #BF0030;
}
.c-headingQuaternary .label-en {
  margin-right: 5px;
  margin-bottom: -0.13em;
  color: #BF0030;
  font-size: 1.125rem;
}
.c-headingQuaternary .label-ja {
  font-size: 1.125rem;
  font-weight: 700;
}

.c-headingQuinary {
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: #BF0030;
  font-size: 1rem;
  font-weight: 700;
  border-bottom: 2px solid #BF0030;
}

.p-breadcrumb__item {
  display: inline-block;
  color: #787878;
  font-size: 0.6875rem;
}
.p-breadcrumb__item + .p-breadcrumb__item::before {
  content: "";
  display: inline-block;
  margin-right: 4px;
  margin-left: 4px;
  font-family: "sppec-icon";
  font-size: 0.5rem;
}
.p-breadcrumb__item a {
  color: inherit;
  text-decoration: none;
}
.p-breadcrumb__item a:hover, .p-breadcrumb__item a:focus {
  text-decoration: underline;
}
.p-breadcrumb__item span {
  color: #000;
}

.p-cautionBox {
  padding: 16px;
  border: 2px solid #BF0030;
  font-size: 0.875rem;
}

.p-cautionBox__title {
  color: #BF0030;
  font-size: 0.875rem;
  font-weight: 700;
}

.p-cartNavi {
  position: relative;
}

.p-cartNavi__toggle {
  position: relative;
  padding: 20px 20px 8px;
  border-radius: 0.25rem;
  border: 0;
  background-color: #F5F5F5;
}
.p-cartNavi__toggle:hover, .p-cartNavi__toggle:focus {
  opacity: 0.65;
}
@media screen and (min-width: 48em) {
  .p-cartNavi__toggle {
    display: flex;
    align-items: center;
    min-width: 180px;
    padding: 13px 15px;
  }
}

.p-cartNavi__toggleIcon {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.p-cartNavi__togglelabel {
  display: block;
  font-size: 0.6875rem;
  text-align: center;
}

.p-cartNavi__toggleBadge {
  display: inline-block;
  min-width: 26px;
  height: 1rem;
  margin-right: 4px;
  margin-left: 8px;
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 0.5rem;
  background-color: #BF0030;
  color: #fff;
  font-size: 0.625rem;
  line-height: 1rem;
  text-align: center;
}
@media screen and (max-width: 47.99875em) {
  .p-cartNavi__toggleBadge {
    position: absolute;
    top: 3px;
    right: 3px;
  }
}

.p-cartNavi__togglePrice {
  flex: 1;
  font-size: 1.125rem;
  text-align: right;
}
.p-cartNavi__togglePrice .price-currency-unit {
  display: inline-block;
  font-size: 0.625rem;
}

.p-cartNavi__content {
  position: absolute;
  right: 0;
  z-index: 20;
  width: 100%;
  min-width: 270px;
  max-width: 270px;
  margin-top: 10px;
  padding: 16px;
  background: #F5F5F5;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.p-cartNavi__content::before {
  content: "";
  position: absolute;
  top: -9px;
  right: 20px;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 12px 8px;
  border-color: transparent transparent #F5F5F5 transparent;
}

.p-cartNavi__action {
  max-width: 160px;
  margin: auto;
  font-size: 0.875rem;
  text-align: center;
}
.p-cartNavi__action a {
  display: block;
  margin-bottom: 10px;
}
.p-cartNavi__action button {
  width: 100%;
}

.p-cartNavi__content {
  display: none;
}
.p-cartNavi__content.is-active {
  display: block;
}

.p-cartNaviItem {
  margin-bottom: 16px;
  padding-bottom: 32px;
  border-bottom: 1px solid #E8E8E8;
  overflow: hidden;
}

.p-cartNaviItem__image {
  float: left;
  width: 40%;
}
.p-cartNaviItem__image img {
  width: 100%;
}

.p-cartNaviItem__content {
  float: right;
  width: 60%;
  padding-left: 16px;
  text-align: left;
}

.p-cartNaviItem__contentTitle {
  margin-bottom: 4px;
  font-size: 0.875rem;
}

.p-cartNaviItem__contentPrice {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 0.875rem;
}

.p-cartNaviItem__contentTax {
  margin-bottom: 4px;
  display: inline-block;
  font-weight: 400;
  font-size: 0.75rem;
}

.p-cartNaviItem__contentNumber {
  font-size: 0.75rem;
}

.p-contactBtn {
  position: relative;
  display: block;
  padding-left: 60px;
  overflow: hidden;
  border-radius: 4px;
  border: 0;
  background-color: #BF0030;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
}
.p-contactBtn:hover, .p-contactBtn:focus {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.p-contactBtn__head {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 60px;
  height: 100%;
  background-color: #9F0028;
}
.p-contactBtn__head i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-contactBtn__body {
  display: block;
  padding: 18px 20px;
}

.p-drawerMenuButton {
  border: 0;
  background-color: transparent;
}

.p-drawerMenuButton__icon {
  display: block;
  margin-bottom: 2px;
  font-size: 1.5625rem;
}

.p-drawerMenuButton__label {
  display: block;
  font-size: 0.6875rem;
}

.p-formAction__btn .c-btn, .p-formAction__btn .btn-middle {
  position: relative;
  display: block;
  margin: auto;
  padding: 24px 36px;
  font-size: 1.125rem;
}

.p-formAction__btn {
  text-align: center;
}
.p-formAction__btn .btn-middle {
  max-width: 20.875rem;
}
@media screen and (min-width: 48em) {
  .p-formAction__btn .btn-middle {
    max-width: 24rem;
  }
}
.p-formAction__btn i {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.p-formAction__text {
  margin-top: 40px;
  text-align: center;
}

.p-favBtn {
  display: inline-block;
  border-radius: 0.25rem;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: inherit;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #787878;
  background-color: #787878;
  color: #fff;
}
.p-favBtn > i {
  display: inline-block;
  vertical-align: middle;
}

.p-favBtn:disabled {
  background-color: #fff;
  color: #787878;
}

.p-guideMenu {
  border: 1px solid #787878;
  border-top: 0;
  border-right: 0;
}
@media screen and (min-width: 48em) {
  .p-guideMenu {
    display: flex;
    flex-wrap: wrap;
  }
}

.p-guideMenu__item {
  border: 1px solid #787878;
  border-bottom: 0;
  border-left: 0;
  background-color: #fff;
}
@media screen and (min-width: 48em) {
  .p-guideMenu__item {
    flex: 1 0 100%;
    max-width: 50%;
  }
}
@media screen and (min-width: 56.25em) {
  .p-guideMenu__item {
    max-width: 25%;
  }
}
.p-guideMenu__item a {
  position: relative;
  display: block;
  padding: 15px 30px;
  color: inherit;
  font-size: 0.875rem;
  text-decoration: none;
}
.p-guideMenu__item a:hover, .p-guideMenu__item a:focus {
  background-color: #F5F5F5;
}
.p-guideMenu__item a .arrow-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.p-guideMenu__item a .arrow-icon::before {
  display: inline-block;
  transform: rotate(90deg);
}

.p-loginForm {
  max-width: 505px;
  margin: auto;
}

.p-loginForm__actions {
  max-width: 17.75rem;
  margin: 30px auto 0;
  font-size: 1rem;
}
.p-loginForm__actions button {
  display: block;
  width: 100%;
  padding: 18px;
}
.p-loginForm__actions a {
  display: block;
  font-size: 0.875rem;
  margin-top: 5px;
}

.p-orderDetail__date {
  margin-bottom: 20px;
  font-size: 1.125rem;
  font-weight: 700;
}

.p-orderHistory {
  border-bottom: 1px solid #787878;
}
@media screen and (min-width: 48em) {
  .p-orderHistory {
    display: flex;
  }
}
.p-orderHistory:first-child {
  border-top: 1px solid #787878;
}

.p-orderHistory__header {
  position: relative;
}
@media screen and (min-width: 48em) {
  .p-orderHistory__header {
    flex: 1;
    max-width: 300px;
  }
}
@media screen and (min-width: 85.375em) {
  .p-orderHistory__header {
    max-width: 384px;
  }
}
.p-orderHistory__header::after {
  content: "";
  display: block;
  width: calc(100% - 40px);
  height: 1px;
  margin: auto;
  background-color: #787878;
}
@media screen and (min-width: 48em) {
  .p-orderHistory__header::after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: calc(100% - 60px);
    margin: 0;
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 48em) {
  .p-orderHistory__detail {
    flex: 1;
  }
}

.p-orderHistoryHeader {
  height: 100%;
  padding: 30px 20px;
  background-color: #FAFAFA;
}

.p-orderHistoryHeader__date {
  margin-bottom: 20px;
  font-size: 1.125rem;
  font-weight: 700;
}

.p-orderHistoryHeader__data {
  margin-bottom: 20px;
}

.p-orderHistoryHeader__dataItem {
  display: flex;
  font-size: 0.875rem;
}
.p-orderHistoryHeader__dataItem dt {
  font-weight: 500;
}
.p-orderHistoryHeader__dataItem dt::after {
  content: ":";
  margin-right: 0.5em;
  margin-left: 0.5em;
}

.p-orderHistoryHeader__action {
  max-width: 182px;
}

.p-orderHistoryDetail {
  display: flex;
  padding: 30px 20px;
  border-bottom: 1px dashed #C8C8C8;
}
.p-orderHistoryDetail:last-child {
  border-bottom: 0;
}
@media screen and (min-width: 48em) {
  .p-orderHistoryDetail {
    padding: 30px 40px;
  }
}

.p-orderHistoryDetail__img {
  flex: 0 0 auto;
  max-width: 100px;
  margin-right: 20px;
}
@media screen and (min-width: 48em) {
  .p-orderHistoryDetail__img {
    max-width: 120px;
    margin-right: 30px;
  }
}

.p-orderHistoryDetail__title {
  margin-bottom: 20px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

.p-orderHistoryDetail__price {
  font-size: 0.875rem;
}

.p-sidemenu {
  border: 1px solid #787878;
  border-bottom: 0;
}

.p-sidemenu__item {
  border-bottom: 1px solid #787878;
}
.p-sidemenu__item a {
  display: block;
  padding: 15px 36px;
  color: inherit;
  font-size: 0.875rem;
  text-decoration: none;
  line-height: 1.4;
}
.p-sidemenu__item a:hover, .p-sidemenu__item a:focus {
  background-color: #F5F5F5;
}

.p-sidemenu__icon {
  display: inline-block;
  width: 30px;
  margin-right: 18px;
  text-align: center;
}
.p-sidemenu__icon img {
  vertical-align: middle;
}
.p-sidemenu__icon.-paper-products {
  padding: 0 2.5px;
}
.p-sidemenu__icon.-film-products {
  padding: 0 2.5px;
}
.p-sidemenu__icon.-packing-materials {
  padding: 0 3px;
}
.p-sidemenu__icon.-paper {
  padding: 0 0.5px;
}
.p-sidemenu__icon.-eco {
  padding: 0 2px;
}
.p-sidemenu__icon.-measures {
  padding: 0 5px;
}
.p-sidemenu__icon.-other {
  padding: 0 3px;
}
.p-sidemenu__icon.-estimate {
  padding: 0 7.5px;
}

.p-searchBox .search-category {
  margin-bottom: 10px;
}
.p-searchBox .search-category select {
  padding: 0.5rem 10px;
  border-radius: 5px;
  border: 1px solid #000;
  font-size: inherit;
  width: 100%;
  font-size: 0.875rem;
}
.p-searchBox .search-keyword {
  margin-bottom: 15px;
}
.p-searchBox .search-button {
  max-width: 128px;
  margin-left: auto;
  font-size: 0.875rem;
}
.p-searchBox .search-button button {
  width: 100%;
}
.p-searchBox .search-text {
  display: none;
}

.p-searchBox__title {
  margin-bottom: 20px;
  line-height: 1.4;
}
.p-searchBox__title .label-en {
  font-family: "Ropa Sans", sans-serif;
  color: #BF0030;
  font-size: 0.875rem;
}
.p-searchBox__title .label-ja {
  font-size: 1rem;
  font-weight: 700;
}

.p-tryEstimate {
  max-width: 890px;
  margin: 100px auto 0;
  padding: 60px 40px;
  background-color: #F5F5F5;
}

.p-tryEstimate__heading {
  margin-bottom: 40px;
  color: #BF0030;
  font-size: 1.5rem;
  text-align: center;
}

.p-tryEstimate__text {
  font-size: 0.875rem;
}

.p-tryEstimate__box {
  margin-top: 30px;
  border-top: 1px solid #BF0030;
  text-align: center;
}

.p-tryEstimate__link {
  margin-top: 60px;
}
.p-tryEstimate__link a {
  position: relative;
  display: block;
  max-width: 24rem;
  margin: auto;
  padding: 24px 36px;
  font-size: 1.125rem;
}
.p-tryEstimate__link a i {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.p-homeCategoryNav__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 10px;
}
@media screen and (min-width: 48em) {
  .p-homeCategoryNav__list {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
}

.p-homeCategoryNavItem {
  display: block;
  height: 100%;
  padding: 24px 20px;
  border: 1px solid #787878;
  color: inherit;
  text-decoration: none;
  transition: opacity 0.2s;
}
.p-homeCategoryNavItem:hover, .p-homeCategoryNavItem:focus {
  opacity: 0.65;
}

.p-homeCategoryNavItem__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 74px;
  min-height: 61px;
  margin: 0 auto 16px;
}
@media screen and (min-width: 48em) {
  .p-homeCategoryNavItem__icon {
    margin-bottom: 0;
  }
}

.p-homeCategoryNavItem__name {
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 700;
}

.p-homeCategoryNavItem__description {
  font-size: 0.75rem;
}

.p-homeCategoryNavItem.-paper-products .p-homeCategoryNavItem__icon img {
  max-width: 69px;
}

.p-homeCategoryNavItem.-film-products .p-homeCategoryNavItem__icon img {
  max-width: 69px;
}

.p-homeCategoryNavItem.-packing-materials .p-homeCategoryNavItem__icon img {
  max-width: 69px;
}

.p-homeCategoryNavItem.-paper .p-homeCategoryNavItem__icon img {
  max-width: 74px;
}

.p-homeCategoryNavItem.-eco .p-homeCategoryNavItem__icon img {
  max-width: 66px;
}

.p-homeCategoryNavItem.-measures .p-homeCategoryNavItem__icon img {
  max-width: 51px;
}

.p-homeCategoryNavItem.-other .p-homeCategoryNavItem__icon img {
  max-width: 38px;
}

.p-homeCategoryNavItem.-estimate .p-homeCategoryNavItem__icon img {
  max-width: 44px;
}

.p-homeNews {
  padding: 40px 30px;
  border-radius: 6px;
  background-color: #F5F5F5;
}

.p-homeNews__header {
  margin-bottom: 20px;
}

.p-homeNews__item {
  margin-bottom: 10px;
}

.p-homeHeroArea {
  margin-bottom: 40px;
}
@media screen and (min-width: 85.375em) {
  .p-homeHeroArea {
    display: flex;
  }
}

.p-homeHeroArea__slider {
  width: 100%;
  max-width: 900px;
  margin: 0 auto 40px;
  overflow: hidden;
}
@media screen and (min-width: 85.375em) {
  .p-homeHeroArea__slider {
    max-width: 586px;
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.p-homeHeroArea__banners {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -8px;
  margin-bottom: -8px;
}
@media screen and (min-width: 85.375em) {
  .p-homeHeroArea__banners {
    flex: 0 0 284px;
    display: block;
    margin: 0;
  }
}
.p-homeHeroArea__banners a {
  display: block;
  max-width: 284px;
  margin: 8px;
}
@media screen and (min-width: 85.375em) {
  .p-homeHeroArea__banners a {
    flex: 0 0 284px;
    margin: 0 0 16px 0;
  }
}

.p-homeProductsSlider.slick-initialized .slide-item {
  width: 100%;
  max-width: 284px;
  padding-right: 6px;
  padding-left: 6px;
}
.p-homeProductsSlider:not(.slick-initialized) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
}
@media screen and (max-width: 85.37375em) {
  .p-homeProductsSlider:not(.slick-initialized) {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.p-homeProductsSlider .slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 100;
  display: block;
  width: 40px;
  height: 100px;
  padding: 10px;
  border: 0;
  background-color: #BF0030;
  color: #fff;
  font-size: 0;
  text-align: center;
  transform: translateY(-50%);
}
.p-homeProductsSlider .slick-arrow::before {
  content: "";
  display: inline-block;
  font-family: "sppec-icon" !important;
  font-size: 1rem;
}
.p-homeProductsSlider .slick-prev {
  left: 0;
  border-radius: 0 6px 6px 0;
}
.p-homeProductsSlider .slick-prev::before {
  transform: rotate(180deg);
  margin-left: -0.25em;
}
.p-homeProductsSlider .slick-next {
  right: 0;
  border-radius: 6px 0 0 6px;
}
.p-homeProductsSlider .slick-next::before {
  margin-right: -0.25em;
}
.p-homeProductsSlider .slick-disabled {
  opacity: 0.35;
}

.p-homeSearch {
  max-width: 100%;
  margin: auto;
  padding: 40px 30px;
  border-radius: 6px;
  background-color: #F5F5F5;
}
@media screen and (min-width: 48em) {
  .p-homeSearch {
    display: flex;
    align-items: center;
    padding: 30px 40px 30px 30px;
  }
}
@media screen and (min-width: 48em) {
  .p-homeSearch .searchform {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
.p-homeSearch .search-text {
  display: none;
}
.p-homeSearch .search-container {
  margin-left: auto;
}
@media screen and (min-width: 48em) {
  .p-homeSearch .search-container {
    width: 100%;
    max-width: 354px;
  }
}
.p-homeSearch .search-category {
  display: none;
}
@media screen and (max-width: 47.99875em) {
  .p-homeSearch .search-keyword {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 48em) {
  .p-homeSearch .search-keyword {
    margin-left: auto;
  }
}
.p-homeSearch .search-button {
  width: 100%;
  max-width: 128px;
  margin-left: auto;
  font-size: 0.875rem;
}
@media screen and (min-width: 48em) {
  .p-homeSearch .search-button {
    margin-left: 40px;
  }
}
.p-homeSearch .search-button button {
  width: 100%;
}

@media screen and (max-width: 47.99875em) {
  .p-homeSearch__header {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 48em) {
  .p-homeSearch__header {
    margin-right: 30px;
  }
}

.p-homeSignon {
  padding: 10px;
  background-color: #F5F5F5;
  text-align: center;
}
.p-homeSignon a {
  width: 100%;
  max-width: 182px;
  margin: 5px;
  font-size: 0.875rem;
}

.p-detailCart__priceRegular {
  font-size: 1rem;
}

.p-detailCart__priceRegularTax {
  font-size: 0.625em;
}

.p-detailCart__price {
  margin-bottom: 20px;
  color: #BF0030;
  font-size: 28px;
  font-weight: 700;
}

.p-detailCart__priceTax {
  font-size: 0.625em;
}

.p-detailCart__actions {
  max-width: 16.25rem;
  margin-bottom: 30px;
}

.p-detailCart__quantity {
  margin-top: 30px;
}
.p-detailCart__quantity dt,
.p-detailCart__quantity dd {
  display: inline-block;
}
.p-detailCart__quantity dt {
  margin-right: 50px;
  color: #BF0030;
  font-size: 1rem;
  font-weight: 700;
}
.p-detailCart__quantity dd {
  max-width: 6.25rem;
}
.p-detailCart__quantity dd > input {
  padding-right: 0;
}

.p-detailCart__note {
  margin-bottom: 20px;
  color: #BF0030;
  font-size: 0.875rem;
}

.p-detailCart__addCart {
  max-width: 23.375rem;
  margin-bottom: 30px;
  color: #fff;
  font-size: 1.125rem;
  text-align: center;
}
.p-detailCart__addCart button {
  display: block;
  width: 100%;
  padding: 24px;
}
.p-detailCart__addCart i {
  font-size: 1.375rem;
}

.p-detailCart__addFav {
  max-width: 17.25rem;
  color: #fff;
  font-size: 0.875rem;
  text-align: center;
}
.p-detailCart__addFav button {
  display: block;
  width: 100%;
}

.p-detailTags__item {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
}
.p-detailTags__item .tag-label {
  display: inline-block;
  margin-right: 2px;
  padding: 2px 8px;
  border: 2px solid currentColor;
  background-color: transparent;
  color: #BF0030;
  font-size: 0.6875rem;
  line-height: 1.4;
  text-align: center;
}
.p-detailTags__item a {
  text-decoration: none;
  transition: opacity 0.2s;
}
.p-detailTags__item a:hover, .p-detailTags__item a:focus {
  opacity: 0.65;
}

.p-listCategories__item {
  display: inline-block;
  margin: 5px 2px;
  padding: 1px 2px 4px;
}
@media screen and (min-width: 48em) {
  .p-listCategories__item {
    min-width: 11.375rem;
    margin: 5px 10px;
  }
}

.p-listConditions {
  overflow: hidden;
}

.p-listConditions__controls {
  margin-bottom: 20px;
  font-size: 0.75rem;
}
@media screen and (min-width: 48em) {
  .p-listConditions__controls {
    float: right;
  }
}
.p-listConditions__controls .disp-number {
  width: 5rem;
  margin-right: 8px;
  padding: 0.3125rem 10px;
}
.p-listConditions__controls .order-by {
  width: 8.75rem;
  padding: 0.3125rem 10px;
}

.p-listConditions__result {
  font-size: 0.875rem;
}
@media screen and (min-width: 48em) {
  .p-listConditions__result {
    float: left;
  }
}

.p-listHeading {
  border-left: 4px solid #BF0030;
  background-color: #F5F5F5;
}

.p-listHeading__label {
  padding: 25px 40px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
}

.p-pagination .ec-pager__item span, .ec-pager__item .p-pagination span, .p-pagination .ec-pager__item a, .ec-pager__item .p-pagination a, .p-pagination .p-pagination__item span, .p-pagination__item .p-pagination span, .p-pagination .p-pagination__item a, .p-pagination__item .p-pagination a {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.625rem;
  height: 2.625rem;
  padding: 4px;
  border-radius: 5px;
  color: inherit;
  text-decoration: none;
  line-height: 1;
  user-select: none;
}
.p-pagination {
  margin-top: 80px;
}
@media screen and (min-width: 48em) {
  .p-pagination {
    margin-top: 130px;
  }
}

.p-pagination__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.p-pagination__item {
  margin: 0 4px 8px;
  color: #787878;
  font-size: 0.875rem;
}
@media screen and (min-width: 48em) {
  .p-pagination__item {
    margin: 0 6px 10px;
  }
}
.p-pagination__item a {
  background-color: #C8C8C8;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
}
.p-pagination__item a:hover, .p-pagination__item a:focus {
  background-color: #BF0030;
  color: #fff;
}
.p-pagination__item.-dot {
  margin-right: 4px;
  margin-left: 4px;
}
.p-pagination__item.-dot span {
  background-color: transparent;
  box-shadow: none;
}
@media screen and (min-width: 48em) {
  .p-pagination__item.-first a, .p-pagination__item.-prev a, .p-pagination__item.-next a, .p-pagination__item.-last a {
    min-width: 60px;
  }
}
.p-pagination__item.is-current span {
  background-color: #BF0030;
  color: #fff;
}

.p-productsList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px 10px;
}
@media screen and (min-width: 48em) {
  .p-productsList {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 40px 30px;
  }
}

.p-relationCategory__header {
  margin-bottom: 40px;
}

.p-relationCategory__category {
  margin-bottom: 20px;
}
.p-relationCategory__category:last-child {
  margin-bottom: 0;
}
.p-relationCategory__category .category-items {
  margin-top: 6px;
  margin-left: -2px;
}
@media screen and (min-width: 48em) {
  .p-relationCategory__category .category-items {
    margin-left: -10px;
  }
}
.p-relationCategory__category .category-item {
  display: inline-block;
  margin: 5px 2px;
  padding: 1px 2px 4px;
}
@media screen and (min-width: 48em) {
  .p-relationCategory__category .category-item {
    min-width: 11.375rem;
    margin: 5px 10px;
  }
}

.p-sectionRecommend__header {
  margin-bottom: 40px;
}

.p-sectionRecommend__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px 10px;
}
@media screen and (min-width: 48em) {
  .p-sectionRecommend__list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 40px 30px;
  }
}

.p-sectionSameCategory__header {
  margin-bottom: 40px;
}

.p-sectionSameCategory__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px 10px;
}
@media screen and (min-width: 48em) {
  .p-sectionSameCategory__list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 40px 30px;
  }
}

.u-align--center {
  text-align: center !important;
}

.u-align--left {
  text-align: left !important;
}

.u-align--right {
  text-align: right !important;
}

/* margin */
@media screen and (min-width: 25.875em) {
  .u-disappear--sm {
    display: none !important;
    visibility: hidden !important;
  }
}

@media screen and (max-width: 25.87375em) {
  .u-disappearLess--sm {
    display: none !important;
    visibility: hidden !important;
  }
}

@media screen and (min-width: 25.875em) and (max-width: 47.99875em) {
  .u-disappearOnly--sm {
    display: none !important;
    visibility: hidden !important;
  }
}

@media screen and (min-width: 48em) {
  .u-disappear--md {
    display: none !important;
    visibility: hidden !important;
  }
}

@media screen and (max-width: 47.99875em) {
  .u-disappearLess--md {
    display: none !important;
    visibility: hidden !important;
  }
}

@media screen and (min-width: 48em) and (max-width: 85.37375em) {
  .u-disappearOnly--md {
    display: none !important;
    visibility: hidden !important;
  }
}

@media screen and (min-width: 85.375em) {
  .u-disappear--lg {
    display: none !important;
    visibility: hidden !important;
  }
}

@media screen and (max-width: 85.37375em) {
  .u-disappearLess--lg {
    display: none !important;
    visibility: hidden !important;
  }
}

.u-ff--ropa {
  font-family: "Ropa Sans", sans-serif;
}

.u-fz--10 {
  font-size: 0.625rem !important;
}

.u-fz--11 {
  font-size: 0.6875rem !important;
}

.u-fz--12 {
  font-size: 0.75rem !important;
}

.u-fz--14 {
  font-size: 0.875rem !important;
}

.u-fz--16 {
  font-size: 1rem !important;
}

.u-fz--18 {
  font-size: 1.125rem !important;
}

.u-fz--20 {
  font-size: 1.25rem !important;
}

.u-fz--24 {
  font-size: 1.5rem !important;
}

.u-fw--bold {
  font-weight: 700 !important;
}

.u-fw--medium {
  font-weight: 500 !important;
}

.u-fw--normal {
  font-weight: normal !important;
}

.u-listType--disc li {
  display: flex;
}
.u-listType--disc li::before {
  content: "・";
}

.u-listType--any li {
  display: flex;
}
.u-listType--any li > .mark:first-child {
  flex: 0 0 1em;
  margin-right: 0.5em;
}

/* margin-bottom */
.u-mb--1em {
  margin-bottom: 1em;
}

.u-mb--5 {
  margin-bottom: 5px;
}

.u-mb--10 {
  margin-bottom: 10px;
}

.u-mb--20 {
  margin-bottom: 20px;
}

.u-mb--30 {
  margin-bottom: 30px;
}

.u-mb--40 {
  margin-bottom: 40px;
}

.u-mb--50 {
  margin-bottom: 50px;
}

.u-mb--60 {
  margin-bottom: 60px;
}

.u-mb--80 {
  margin-bottom: 80px;
}

.u-mb--100 {
  margin-bottom: 100px;
}

/* margin top */
.u-mt--1em {
  margin-top: 1em;
}

.u-mt--5 {
  margin-top: 5px;
}

.u-mt--10 {
  margin-top: 10px;
}

.u-mt--20 {
  margin-top: 20px;
}

.u-mt--30 {
  margin-top: 30px;
}

.u-mt--40 {
  margin-top: 40px;
}

/* margin side */
.u-mr--1em {
  margin-right: 1em;
}

.u-mr--5 {
  margin-right: 5px;
}

.u-mr--10 {
  margin-right: 10px;
}

.u-mr--15 {
  margin-right: 15px;
}

.u-mr--20 {
  margin-right: 20px;
}

.u-ml--1em {
  margin-left: 1em;
}

.u-ml--5 {
  margin-left: 5px;
}

.u-ml--10 {
  margin-left: 10px;
}

.u-ml--15 {
  margin-left: 15px;
}

.u-ml--20 {
  margin-left: 20px;
}

@media screen and (max-width: 85.37375em) {
  .u-mobileGutter {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.u-opacityHover {
  transition: opacity 0.2s;
}
.u-opacityHover:hover, .u-opacityHover:focus {
  opacity: 0.65;
}

.u-tableResponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.ec-404Role {
  font-size: 0.875rem;
  line-height: 1.8;
}

.ec-404Role__image {
  max-width: 398px;
  margin: 0 auto 50px;
}

.ec-404Role__button a {
  position: relative;
  display: block;
  margin: auto;
  padding: 24px 36px;
  font-size: 1.125rem;
  font-weight: bold;
}
.ec-404Role__button i {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

/*
お届け先の複数指定

お届け先の複数指定に関するコンポーネントを定義します。

ex [マイページ　お届け先の複数指定](http://demo3.ec-cube.net/shopping/shipping_multiple)
(商品購入画面 → 「お届け先を追加する」を押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAddAddress

Styleguide 15.2.6
*/
.ec-AddAddress {
  padding: 0 10px;
}
@media screen and (min-width: 48em) {
  .ec-AddAddress {
    margin: 0 10%;
  }
}
.ec-AddAddress .ec-AddAddress__info {
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
}
.ec-AddAddress .ec-AddAddress__add {
  border-top: 1px solid #f4f4f4;
  padding-top: 20px;
  margin-bottom: 20px;
}
.ec-AddAddress .ec-AddAddress__item {
  display: table;
  padding: 16px;
  background: #f4f4f4;
  margin-bottom: 16px;
}
.ec-AddAddress .ec-AddAddress__itemThumb {
  display: table-cell;
  min-width: 160px;
  width: 20%;
}
.ec-AddAddress .ec-AddAddress__itemThumb img {
  width: 100%;
}
.ec-AddAddress .ec-AddAddress__itemtContent {
  display: table-cell;
  vertical-align: middle;
  padding-left: 16px;
  font-size: 16px;
}
.ec-AddAddress .ec-AddAddress__itemtTitle {
  font-weight: bold;
  margin-bottom: 10px;
}
.ec-AddAddress .ec-AddAddress__itemtSize {
  margin-bottom: 10px;
}
.ec-AddAddress .ec-AddAddress__select {
  margin-bottom: 5px;
}
.ec-AddAddress .ec-AddAddress__selectAddress {
  display: inline-block;
}
.ec-AddAddress .ec-AddAddress__selectAddress label {
  font-size: 16px;
  font-weight: normal;
}
.ec-AddAddress .ec-AddAddress__selectAddress select {
  min-width: 100%;
}
@media screen and (min-width: 48em) {
  .ec-AddAddress .ec-AddAddress__selectAddress select {
    min-width: 350px;
  }
}
.ec-AddAddress .ec-AddAddress__selectNumber {
  display: inline-block;
  margin-left: 30px;
}
.ec-AddAddress .ec-AddAddress__selectNumber label {
  font-size: 16px;
  font-weight: normal;
}
.ec-AddAddress .ec-AddAddress__selectNumber input {
  display: inline-block;
  margin-left: 10px;
  width: 80px;
}
.ec-AddAddress .ec-AddAddress__actions .ec-blockBtn--action {
  margin-bottom: 8px;
}
.ec-AddAddress .ec-AddAddress__new {
  margin-bottom: 20px;
}

/*
住所一覧

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [マイページ内 お届け先編集](http://demo3.ec-cube.net/mypage/delivery)

Markup:
include /assets/tmpl/elements/17.1.address.pug
+ec-addressList
+ec-addressRole

sg-wrapper:
<div class="ec-addressRole">
  <sg-wrapper-content/>
</div>

Styleguide 17.1

*/
.ec-addressRole__item {
  border-top: 1px dotted #787878;
}

.ec-addressRole__actions {
  margin-top: 32px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #787878;
}

.ec-addressList__item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #787878;
}
@media screen and (min-width: 48em) {
  .ec-addressList__item {
    padding: 30px 20px;
  }
}
.ec-addressList__item:first-child {
  border-top: 1px solid #787878;
}

.ec-addressList__remove {
  margin-right: 10px;
  padding: 10px;
  color: #787878;
  font-size: 0.875rem;
  text-decoration: none;
  text-align: center;
}
@media screen and (min-width: 48em) {
  .ec-addressList__remove {
    margin-right: 20px;
  }
}
.ec-addressList__remove .ec-icon img {
  width: 1em;
  height: 1em;
}

.ec-addressList__address {
  flex: 1;
  width: 80%;
  font-size: 0.875rem;
}

.ec-addressList__addressName {
  margin-bottom: 20px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.3;
}

.ec-addressList__action {
  flex: 0 0 3.875rem;
  align-self: flex-end;
}
.ec-addressList__action a {
  display: block;
  width: 3.875rem;
  font-size: 0.875rem;
  text-align: center;
}

/*
生年月日選択

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　生年月日選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-birth
  select
    option ----
    option 1960
    option 1961
    option 1962
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...

Styleguide 3.2.5
*/
.ec-birth {
  display: flex;
  align-items: center;
}
.ec-birth select {
  padding: 0.5rem 10px;
  border-radius: 5px;
  border: 1px solid #000;
  font-size: inherit;
  flex: 1;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: 28%;
  max-width: 120px;
  margin-bottom: 5px;
}
.ec-birth select:focus {
  box-shadow: none;
}
.ec-birth span {
  margin-right: 5px;
  margin-left: 5px;
}
@media screen and (min-width: 25.875em) {
  .ec-birth span {
    margin-right: 10px;
    margin-left: 10px;
  }
}

/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
.ec-cartRole__error {
  width: 100%;
  text-align: center;
}
.ec-cartRole__error .ec-alert-warning {
  max-width: 80%;
  display: inline-block;
}

.ec-cartRole__totalText {
  margin-bottom: 0;
  padding: 16px 0 6px;
  width: 100%;
  text-align: center;
  font-weight: normal;
}
@media screen and (min-width: 48em) {
  .ec-cartRole__totalText {
    margin-bottom: 30px;
    padding: 0;
  }
}

.ec-cartRole__cart {
  margin: 0;
  width: 100%;
}

.ec-cartRole__actions {
  text-align: right;
  width: 100%;
}

.ec-cartRole__total {
  padding: 15px 0 30px;
  font-weight: bold;
  font-size: 16px;
}

.ec-cartRole__totalAmount {
  margin-left: 30px;
  color: #BF0030;
  font-size: 20px;
}
@media screen and (min-width: 48em) {
  .ec-cartRole__totalAmount {
    font-size: 24px;
  }
}

.ec-cartRole__actions .action-go {
  position: relative;
  display: block;
  width: 100%;
  max-width: 20.875rem;
  margin: 0 auto 30px;
  padding: 24px 36px;
  font-size: 1.125rem;
  text-align: center;
}
@media screen and (min-width: 48em) {
  .ec-cartRole__actions .action-go {
    margin-right: initial;
  }
}
.ec-cartRole__actions .action-go i {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}
.ec-cartRole__actions .action-cancel {
  color: #787878;
  text-decoration: none;
}
.ec-cartRole__actions .action-cancel i {
  display: inline-block;
  margin-left: 15px;
}

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品をを表示するテーブル枠です。

ex [カートページ　テーブル部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable {
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%;
}
@media screen and (min-width: 48em) {
  .ec-cartTable {
    border-top: none;
  }
}

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品を表示するテーブルのヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)


Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.3
*/
.ec-cartHeader {
  display: none;
  width: 100%;
  background: #F5F5F5;
}
@media screen and (min-width: 48em) {
  .ec-cartHeader {
    display: table-row;
  }
}

.ec-cartHeader__label {
  display: table-cell;
  padding: 16px;
  text-align: center;
  background: #F4F3F0;
  overflow-x: hidden;
  font-weight: bold;
}

/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/
.ec-cartRow {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  padding-left: 44px;
  padding-bottom: 90px;
  border-bottom: 1px solid #C8C8C8;
  font-size: 0.875rem;
}
@media screen and (min-width: 48em) {
  .ec-cartRow {
    display: table-row;
    padding: 0;
    border-bottom: 0;
  }
}
@media screen and (min-width: 48em) {
  .ec-cartRow li {
    display: table-cell;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #C8C8C8;
    vertical-align: middle;
  }
}

.ec-cartRow__delColumn {
  width: 8.3333333%;
  text-align: center;
}
@media screen and (max-width: 47.99875em) {
  .ec-cartRow__delColumn {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    transform: translateY(-50%);
  }
}
.ec-cartRow__delColumn .ec-icon {
  display: block;
  padding: 15px;
  color: #787878;
  font-size: 0.875rem;
  text-decoration: none;
}

.ec-cartRow__contentColumn {
  flex: 1;
}

.ec-cartRow__contentColumnInner {
  display: flex;
}

.ec-cartRow__img {
  max-width: 80px;
  margin-right: 20px;
}
@media screen and (min-width: 48em) {
  .ec-cartRow__img {
    max-width: 120px;
    margin-right: 40px;
    vertical-align: top;
  }
}

.ec-cartRow__summary {
  width: calc(100% - 100px);
}
@media screen and (min-width: 48em) {
  .ec-cartRow__summary {
    max-width: 320px;
    vertical-align: top;
  }
}
.ec-cartRow__summary .ec-cartRow__name {
  margin-bottom: 10px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;
}
.ec-cartRow__summary .ec-cartRow__name .product-name {
  margin-bottom: 10px;
}
.ec-cartRow__summary .ec-cartRow__unitPrice {
  margin-bottom: 20px;
}
.ec-cartRow__summary .ec-cartRow__sutbtotalSP {
  position: absolute;
  bottom: 30px;
  right: 20px;
  display: block;
  width: calc(100% - 64px);
  padding: 20px;
  background-color: #F5F5F5;
  line-height: 1;
  text-align: right;
}

.ec-cartRow__amountColumn {
  width: 100%;
  margin-bottom: 20px;
}
@media screen and (min-width: 48em) {
  .ec-cartRow__amountColumn {
    width: 16.66666667%;
  }
}

.ec-cartRow__amountContainer {
  position: relative;
  width: 100%;
  max-width: 116px;
  margin-left: 100px;
}
@media screen and (min-width: 48em) {
  .ec-cartRow__amountContainer {
    margin: auto;
    max-width: 76px;
  }
}

.ec-cartRow__amount {
  margin-bottom: 10px;
  text-align: center;
}
@media screen and (max-width: 47.99875em) {
  .ec-cartRow__amount {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.ec-cartRow__amountUpDown {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 116px;
}

.ec-cartRow__amountUpButton {
  margin: 0 2px;
  display: inline-block;
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  text-align: center;
  background: #fff;
}

.ec-cartRow__amountDownButton, .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled, .ec-cartRow__amountDownButtonDisabled {
  margin: 0 2px;
  display: inline-block;
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  text-align: center;
  background: #fff;
}

.ec-cartRow__amountDownButtonDisabled {
  cursor: default;
  opacity: 0.5;
}

.ec-cartRow__subtotalColumn {
  display: none;
  text-align: right;
  width: 16.66666667%;
}
@media screen and (min-width: 48em) {
  .ec-cartRow__subtotalColumn {
    display: table-cell;
    padding-right: 20px;
  }
}

/*
カート内商品(商品が１の場合)

商品が１の場合はカート商品を減らす「-」ボタンの無効化状態になります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRowOnly

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.5
*/
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
  cursor: default;
}

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.6
*/
.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #F99;
  margin-bottom: 20px;
}
.ec-alert-warning .ec-alert-warning__icon {
  display: inline-block;
  margin-right: 1rem;
  width: 20px;
  height: 20px;
  color: #fff;
  fill: #fff;
  vertical-align: top;
}
.ec-alert-warning .ec-alert-warning__text {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
}

/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.7
*/
/*
水平定義リスト

シンプルな定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　水平定義リスト部分](http://demo3.ec-cube.net/help/about)

Markup:
dl.ec-definitions
    dt 店名
    dd EC-CUBE3 DEMO SHOP
dl.ec-definitions
    dt 会社名
    dd EC-CUBE3
dl.ec-definitions--soft
    dt 所在地
    dd 〒 550-0001

Styleguide 1.3.1
*/
.ec-definitions, .ec-definitions--soft {
  margin: 5px 0;
  display: block;
}
.ec-definitions dt, .ec-definitions--soft dt, .ec-definitions dd, .ec-definitions--soft dd {
  display: inline-block;
  margin: 0;
  font-size: 0.875rem;
}
.ec-definitions dt, .ec-definitions--soft dt {
  font-weight: bold;
}
.ec-definitions dt::after, .ec-definitions--soft dt::after {
  content: ":";
  margin-right: 0.5em;
  margin-left: 0.5em;
}

.ec-definitions--soft dt {
  font-weight: normal;
}

/*
画像レイアウト

画像とテキストを水平に並べるレイアウトです。

画像は20%で表示されます。

ex [注文履歴 ログイン後→注文履歴ボタンを押下](http://demo3.ec-cube.net/mypage)

Markup:
.ec-imageGrid
  .ec-imageGrid__img: img(src="http://demo3.ec-cube.net/upload/save_image/0701113537_559351f959620.jpeg")
  .ec-imageGrid__content
    p.ec-font-bold ホーローマグ
    p ¥ 1,728 x 1

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.2.1
*/
.ec-imageGrid {
  display: flex;
  width: 100%;
}

.ec-imageGrid__img {
  width: 120px;
  margin-right: 20px;
}
.ec-imageGrid__img img {
  width: 100%;
}

.ec-imageGrid__content {
  font-size: 0.875rem;
}

/*
注文履歴詳細 お問い合わせ

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　お問い合わせ(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderConfirm
    .ec-rectHeading
      h2 お問い合わせ
    p 記載なし

Styleguide 15.2.5
*/
.ec-orderConfirm {
  margin-bottom: 40px;
  font-size: 0.875rem;
}
.ec-orderConfirm .ec-input textarea {
  height: 96px;
}

/*
注文履歴詳細 支払情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　支払情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderPayment
    .ec-rectHeading
      h2 お支払方法
    p 支払方法： 郵便振替

Styleguide 15.2.4
*/
.ec-orderPayment {
  margin-bottom: 60px;
  font-size: 0.875rem;
}
.ec-orderPayment .ec-rectHeading {
  margin-bottom: 40px;
  border-left: 4px solid #BF0030;
  background-color: #F5F5F5;
}
.ec-orderPayment .ec-rectHeading h2 {
  padding: 12px 20px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}
.ec-orderPayment .dl_table dl {
  margin-bottom: 10px;
}
.ec-orderPayment .dl_table dt,
.ec-orderPayment .dl_table dd {
  display: inline-block;
  vertical-align: middle;
}
.ec-orderPayment .dl_table dt::after {
  content: ":";
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.ec-orderPayment .ec-radio div p {
  margin-top: 5px;
}
.ec-orderPayment .ec-radio #shopping_order_Payment_6 ~ p {
  margin-bottom: 20px;
}
.ec-orderPayment .ec-radio #shopping_order_Payment_6 ~ p img {
  max-width: 568px;
}
.ec-orderPayment .ec-radio #shopping_order_Payment_5 ~ p img {
  max-width: 298px;
}

/*
注文詳細 配送情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　配送情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderDelivery

Styleguide 15.2.3
*/
.ec-orderDelivery {
  margin-bottom: 60px;
}

.ec-orderDelivery__title {
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 18px;
  position: relative;
}

.ec-orderDelivery__change {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.875rem;
}

.ec-orderDelivery__item {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #C8C8C8;
}
.ec-orderDelivery__item:last-child {
  border-bottom: 1px solid #C8C8C8;
}

.ec-orderDelivery__address {
  margin: 30px 0 40px;
}
.ec-orderDelivery__address p {
  margin: 0;
  font-size: 0.875rem;
}

.ec-orderDelivery__actions .delivery-actions-item {
  display: flex;
  align-items: center;
}
.ec-orderDelivery__actions dt {
  flex: 1;
  max-width: 100px;
  padding-right: 10px;
}
.ec-orderDelivery__actions dd {
  flex: 1;
  max-width: 24rem;
  font-size: 0.875rem;
}

.ec-orderPayment__select > div {
  margin-bottom: 20px;
}
.ec-orderPayment__select > div:last-child {
  margin-bottom: 0;
}
.ec-orderPayment__select label {
  width: 100%;
  max-width: 24rem;
}

/*
注文内容確認

カート内 注文内容確認に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/shopping)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderRole

Styleguide 15.2
*/
@media screen and (min-width: 85.375em) {
  .ec-orderRole {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
  }
}

.ec-inlineBtn {
  font-weight: normal;
}

.ec-orderRole__detail {
  padding: 0;
  width: 100%;
  margin-bottom: 60px;
}
@media screen and (min-width: 85.375em) {
  .ec-orderRole__detail {
    max-width: calc(100% - 384px);
    margin-bottom: 0;
    padding-right: 20px;
  }
}

.ec-orderRole__summary {
  width: 100%;
}
.ec-orderRole__summary .ec-inlineBtn {
  display: inline-block;
}
@media screen and (min-width: 85.375em) {
  .ec-orderRole__summary {
    max-width: 384px;
  }
  .ec-orderRole__summary .ec-inlineBtn {
    display: none;
  }
}

.ec-borderedList {
  margin-bottom: 20px;
}
@media screen and (min-width: 85.375em) {
  .ec-borderedList {
    border-top: none;
  }
}
.ec-borderedList li {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ec-orderMails {
  margin-bottom: 60px;
}

.ec-orderMails__item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #787878;
  font-size: 0.875rem;
}
.ec-orderMails__item:last-child {
  margin-bottom: 0;
}

.ec-orderMails__time {
  margin: 0;
}

.ec-orderMails__body {
  display: none;
}

.ec-orderMail__time {
  margin: 0;
}

.ec-orderMail__body {
  display: none;
}

.ec-orderMail__time {
  margin-bottom: 4px;
}

.ec-orderMail__link {
  margin-bottom: 4px;
}
.ec-orderMail__link a {
  color: #0092C4;
  text-decoration: none;
  cursor: pointer;
}
.ec-orderMail__link a:hover {
  color: #33A8D0;
}

.ec-orderMail__close {
  margin-top: 10px;
}
.ec-orderMail__close a {
  color: #0057A9;
  font-size: inherit;
  text-decoration: underline;
}
.ec-orderMail__close a:hover, .ec-orderMail__close a:focus {
  text-decoration: none;
}

/*
注文履歴詳細 お客様情報

マイページ 注文詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAccount

Styleguide 15.2.2
*/
.ec-orderAccount {
  margin-bottom: 60px;
  font-size: 0.875rem;
}
.ec-orderAccount p {
  margin-bottom: 0;
}

.ec-orderAccount__change {
  display: inline-block;
  margin-left: 10px;
  float: right;
}

.ec-orderAccount__account {
  margin-bottom: 16px;
}

@media screen and (min-width: 48em) {
  .non-customer-edit dt {
    max-width: 242px;
  }
}

.ec-zipInput {
  margin-bottom: 20px;
}

.mod-button {
  margin-top: 20px;
}
.mod-button .ec-inlineBtn {
  margin-right: 20px;
  min-width: 100px;
}

/*
注文履歴詳細 オーダ情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderInfo

Styleguide 15.2.1
*/
.ec-orderOrder {
  margin-bottom: 60px;
  font-size: 0.875rem;
}

/*
カートヘッダ

購入完了までの手順や、現在の状態を表示します。

ul 要素を用いたリスト要素としてマークアップします。

ex [カートページ　ヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-progress

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3.1
*/
.ec-progress {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: auto;
  padding-left: 5px;
}
@media screen and (min-width: 25.875em) {
  .ec-progress {
    padding-left: 20px;
    padding-right: 10px;
  }
}

.ec-progress__item {
  display: inline-flex;
  align-items: center;
  width: 182px;
  height: 60px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  overflow: hidden;
  color: #787878;
  font-size: 0.875rem;
  background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22182%22%20height%3D%2260%22%20viewBox%3D%220%200%20182%2060%22%3E%3Cpath%20d%3D%22M0%2C0H172l10%2C30L172%2C60H0Z%22%20fill%3D%22%23c8c8c8%22%2F%3E%3C%2Fsvg%3E") 0 0 no-repeat;
  background-size: contain;
}
@media screen and (max-width: 25.87375em) {
  .ec-progress__item {
    width: 146px;
    height: 48px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 0.75rem;
    line-height: 1.3;
  }
}
.ec-progress__number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.ec-progress__label {
  display: inline-block;
}

.is-complete {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22182%22%20height%3D%2260%22%20viewBox%3D%220%200%20182%2060%22%3E%3Cpath%20d%3D%22M0%2C0H172l10%2C30L172%2C60H0Z%22%20fill%3D%22%23BF0030%22%2F%3E%3C%2Fsvg%3E");
}
.is-complete .ec-progress__number {
  color: #BF0030;
}
.is-complete .ec-progress__label {
  color: #fff;
}

.ec-pagerRole .ec-pager__item span, .ec-pager__item .ec-pagerRole span, .ec-pagerRole .ec-pager__item a, .ec-pager__item .ec-pagerRole a, .ec-pagerRole .p-pagination__item a, .p-pagination__item .ec-pagerRole a, .ec-pagerRole .p-pagination__item span, .p-pagination__item .ec-pagerRole span {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.625rem;
  height: 2.625rem;
  padding: 4px;
  border-radius: 5px;
  color: inherit;
  text-decoration: none;
  line-height: 1;
  user-select: none;
}
.ec-pagerRole {
  margin-top: 80px;
}
@media screen and (min-width: 48em) {
  .ec-pagerRole {
    margin-top: 130px;
  }
}

.ec-pager {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ec-pager__item {
  margin: 0 4px 8px;
  color: #787878;
  font-size: 0.875rem;
}
@media screen and (min-width: 48em) {
  .ec-pager__item {
    margin: 0 6px 10px;
  }
}
.ec-pager__item a {
  background-color: #C8C8C8;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
}
.ec-pager__item a:hover, .ec-pager__item a:focus {
  background-color: #BF0030;
  color: #fff;
}
.ec-pager__item.-dot {
  margin-right: 4px;
  margin-left: 4px;
}
.ec-pager__item.-dot span {
  background-color: transparent;
  box-shadow: none;
}
@media screen and (min-width: 48em) {
  .ec-pager__item.-first a, .ec-pager__item.-prev a, .ec-pager__item.-next a, .ec-pager__item.-last a {
    min-width: 60px;
  }
}
.ec-pager__item.-active span {
  background-color: #BF0030;
  color: #fff;
}

/*
総計

会計時の合計金額、総計を表示します。

ex [カートページ　統計部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-totalBox

Styleguide 7.3.8
*/
.ec-totalBox {
  background-color: #F5F5F5;
  padding: 40px 30px;
}

.ec-totalBox__spec {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.ec-totalBox__spec dt {
  font-weight: normal;
  text-align: left;
}
.ec-totalBox__spec dd {
  text-align: right;
}
.ec-totalBox__total {
  border-top: 2px solid #C8C8C8;
  margin-top: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: right;
  font-size: 0.875rem;
  font-weight: bold;
}

.ec-totalBox__paymentTotal {
  padding: 10px 0;
  text-align: right;
  font-size: 0.875rem;
  font-weight: bold;
}

.ec-totalBox__price {
  margin-left: 16px;
  color: #BF0030;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.2;
}
@media screen and (min-width: 48em) {
  .ec-totalBox__price {
    font-size: 1.5rem;
  }
}

.ec-totalBox__taxLabel {
  margin-left: 8px;
  font-size: 0.75rem;
}
@media screen and (min-width: 48em) {
  .ec-totalBox__taxLabel {
    font-size: 0.875rem;
  }
}

.ec-totalBox__taxRate {
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 8px;
  font-size: 0.625rem;
}
@media screen and (min-width: 48em) {
  .ec-totalBox__taxRate {
    font-size: 0.75rem;
  }
}
.ec-totalBox__taxRate dt {
  font-weight: normal;
  text-align: left;
  margin-right: 8px;
}
.ec-totalBox__taxRate dt::before {
  content: "[ ";
}
.ec-totalBox__taxRate dd {
  text-align: right;
}
.ec-totalBox__taxRate dd::after {
  content: " ]";
}

.ec-totalBox__action {
  margin-top: 20px;
  margin-bottom: -10px;
  text-align: center;
}
.ec-totalBox__action > * {
  width: 100%;
  max-width: 17.1875rem;
  margin: 10px;
  padding: 24px;
}

.ec-totalBox__btn {
  margin-top: 20px;
  margin-bottom: -10px;
  text-align: center;
}
.ec-totalBox__btn > * {
  width: 100%;
  max-width: 17.1875rem;
  margin: 10px;
  padding: 24px;
}

.ec-errorMessage {
  margin-top: 0.5rem;
  color: #BF0030;
  font-size: 0.75rem;
  font-weight: 700;
}

.ec-halfInput {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}
@media screen and (min-width: 48em) {
  .ec-halfInput {
    max-width: 464px;
  }
}
.ec-halfInput input {
  max-width: 100%;
  padding: 0.5625rem 14px;
  line-height: 1.4;
  border-radius: 0.3125rem;
  border: 1px solid #000;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.4);
}
.ec-halfInput input::placeholder {
  color: #C8C8C8;
  font-size: 0.875rem;
}
.ec-halfInput > input {
  width: calc(50% - 10px);
}
.ec-halfInput .ec-errorMessage {
  width: calc(50% - 10px);
}
.ec-halfInput > *:nth-child(odd) {
  margin-right: 10px;
}
@media screen and (min-width: 25.875em) {
  .ec-halfInput > *:nth-child(odd) {
    margin-right: 20px;
  }
}

.ec-input input {
  max-width: 100%;
  padding: 0.5625rem 14px;
  line-height: 1.4;
  border-radius: 0.3125rem;
  border: 1px solid #000;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.4);
  width: 100%;
}
.ec-input input::placeholder {
  color: #C8C8C8;
  font-size: 0.875rem;
}
.ec-input textarea {
  max-width: 100%;
  padding: 0.5625rem 14px;
  line-height: 1.4;
  border-radius: 0.3125rem;
  border: 1px solid #000;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.4);
  width: 100%;
}
.ec-input textarea::placeholder {
  color: #C8C8C8;
  font-size: 0.875rem;
}

.ec-required {
  margin-left: 1em;
  color: #BF0030;
  font-size: 0.75rem;
  font-weight: 700;
}

.ec-select select {
  padding: 0.5rem 10px;
  border-radius: 5px;
  border: 1px solid #000;
  font-size: inherit;
  width: 100%;
}
.ec-select.-prefInput {
  max-width: 16.5rem;
}

.ec-zipInput input {
  max-width: 100%;
  padding: 0.5625rem 14px;
  line-height: 1.4;
  border-radius: 0.3125rem;
  border: 1px solid #000;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.4);
  max-width: 13.875rem;
}
.ec-zipInput input::placeholder {
  color: #C8C8C8;
  font-size: 0.875rem;
}

.non-customer-edit .ec-halfInput > .customer-form {
  width: calc(50% - 10px);
}
.non-customer-edit .customer-address_pref input {
  max-width: 100%;
  padding: 0.5625rem 14px;
  line-height: 1.4;
  border-radius: 0.3125rem;
  border: 1px solid #000;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.4);
  width: 100%;
  max-width: 16.5rem;
}
.non-customer-edit .customer-address_pref input::placeholder {
  color: #C8C8C8;
  font-size: 0.875rem;
}
.non-customer-edit .customer-phone_number input {
  max-width: 100%;
  padding: 0.5625rem 14px;
  line-height: 1.4;
  border-radius: 0.3125rem;
  border: 1px solid #000;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.4);
  width: 100%;
}
.non-customer-edit .customer-phone_number input::placeholder {
  color: #C8C8C8;
  font-size: 0.875rem;
}

#gmo_payment_gateway_cvs_form h2 {
  margin-bottom: 20px;
  border-left: 4px solid #BF0030;
  background-color: #F5F5F5;
  padding: 12px 20px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

#gmo_payment_gateway_credit_form h2 {
  margin-bottom: 20px;
  border-left: 4px solid #BF0030;
  background-color: #F5F5F5;
  padding: 12px 20px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}
#gmo_payment_gateway_credit_form .dl_table dl {
  display: block !important;
  height: auto !important;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
#gmo_payment_gateway_credit_form .dl_table dl:first-child {
  border-top: 1px solid #ccc;
}
@media screen and (min-width: 48em) {
  #gmo_payment_gateway_credit_form .dl_table dl {
    display: flex !important;
    flex-wrap: nowrap;
    align-items: center;
  }
}
#gmo_payment_gateway_credit_form .dl_table dt, #gmo_payment_gateway_credit_form .dl_table dd {
  display: block !important;
}
#gmo_payment_gateway_credit_form .dl_table dt {
  flex: 1 0 auto;
  width: 100% !important;
  max-width: 170px;
  margin-bottom: 5px;
  padding-right: 5px;
}
#gmo_payment_gateway_credit_form .dl_table .ec-input input {
  margin-top: 5px;
}
#shopping_order_security_code {
  margin-bottom: 5px;
}

#gmo_payment_gateway_credit_confirm_form h2 {
  margin-bottom: 20px;
  border-left: 4px solid #BF0030;
  background-color: #F5F5F5;
  padding: 12px 20px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}
#gmo_payment_gateway_credit_confirm_form .dl_table dl {
  display: block !important;
  height: auto !important;
  margin-bottom: 10px;
}
@media screen and (min-width: 48em) {
  #gmo_payment_gateway_credit_confirm_form .dl_table dl {
    display: flex !important;
    flex-wrap: nowrap;
    align-items: center;
  }
}
#gmo_payment_gateway_credit_confirm_form .dl_table dt, #gmo_payment_gateway_credit_confirm_form .dl_table dd {
  display: block !important;
}
#gmo_payment_gateway_credit_confirm_form .dl_table dt {
  flex: 1 0 auto;
  width: 100% !important;
  max-width: 170px;
  margin-bottom: 5px;
  padding-right: 5px;
}
#gmo_payment_gateway_credit_confirm_form .dl_table .ec-input input {
  margin-top: 5px;
}
#shopping_order_security_code {
  margin-bottom: 5px;
}

.p-entry__content {
  max-width: 788px;
  margin: 0 auto 60px;
}
@media screen and (min-width: 25.875em) and (max-width: 85.37375em) {
  .p-entry__content {
    max-width: 828px;
  }
}

.p-entry__datalist {
  margin-bottom: 60px;
}
@media screen and (min-width: 25.875em) and (max-width: 85.37375em) {
  .p-entry__datalist {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.p-entry__datalist.-confirm dd {
  padding: 20px 20px 20px 10px;
}
@media screen and (min-width: 48em) {
  .p-entry__datalist dt {
    max-width: 243px;
  }
}

.p-contact__datalist {
  margin-bottom: 60px;
}
@media screen and (min-width: 25.875em) and (max-width: 85.37375em) {
  .p-contact__datalist {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.p-contact__datalist.-input dd {
  padding-top: 15px;
  padding-bottom: 15px;
}
@media screen and (min-width: 48em) {
  .p-contact__datalist dt {
    max-width: 243px;
  }
}

.p-forgot__content {
  max-width: 788px;
  margin: 0 auto 60px;
}
@media screen and (min-width: 25.875em) and (max-width: 85.37375em) {
  .p-forgot__content {
    max-width: 828px;
  }
}

.p-forgot__description {
  margin-bottom: 40px;
  font-size: 0.875rem;
}

.p-forgot__box {
  margin: auto;
  padding: 60px 20px;
  background-color: #F5F5F5;
}
@media screen and (min-width: 25.875em) {
  .p-forgot__box {
    padding: 60px 30px;
  }
}

.p-guide__nav {
  margin-bottom: 80px;
}
@media screen and (min-width: 48em) {
  .p-guide__nav {
    margin-bottom: 60px;
  }
}
.p-guide__nav .p-guide__navTitle {
  padding: 16px 30px;
  background-color: #BF0030;
  color: #fff;
  line-height: 1.4;
}
.p-guide__nav .p-guide__navTitle .label-en {
  margin-bottom: 4px;
  font-family: "Ropa Sans", sans-serif;
  font-size: 0.875rem;
}
@media screen and (min-width: 48em) {
  .p-guide__nav .p-guide__navTitle .label-en {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 5px;
  }
}
.p-guide__nav .p-guide__navTitle .label-ja {
  font-size: 1rem;
  font-weight: 700;
}
@media screen and (min-width: 48em) {
  .p-guide__nav .p-guide__navTitle .label-ja {
    display: inline-block;
  }
}

.p-guide__section {
  margin-bottom: 80px;
  font-size: 0.875rem;
}
.p-guide__section .logo-card-brand {
  max-width: 298px;
}
.p-guide__section .logo-convenience-store {
  max-width: 568px;
}

@media screen and (min-width: 48em) {
  .p-guide__shipping dt {
    max-width: 606px;
  }
}

.p-guide__chargeTypeBTable {
  display: block;
  width: 100%;
  height: 600px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.p-home__sectionHeading {
  margin-bottom: 60px;
}
@media screen and (min-width: 48em) {
  .p-home__sectionHeading {
    margin-bottom: 30px;
  }
}

.p-home__listLink {
  margin-top: 34px;
  padding-right: 20px;
  text-align: right;
}
@media screen and (min-width: 48em) {
  .p-home__listLink {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 6px;
  }
}
.p-home__listLink a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}
.p-home__listLink a:hover, .p-home__listLink a:focus {
  color: #BF0030;
}
.p-home__listLink i {
  margin-left: 10px;
  color: #BF0030;
}

.p-home__sectionSearch {
  margin-bottom: 60px;
}

.p-home__sectionCategory {
  margin-bottom: 60px;
}

.p-home__sectionRecommend {
  position: relative;
  margin-bottom: 60px;
}

.p-home__sectionEstimate {
  position: relative;
  margin-bottom: 80px;
}

.p-home__sectionNews {
  padding-right: 20px;
  padding-left: 20px;
}

.p-login__content {
  max-width: 788px;
  margin: auto;
}

.p-login__box {
  margin: auto;
  padding: 60px 20px;
  background-color: #F5F5F5;
}
@media screen and (min-width: 25.875em) {
  .p-login__box {
    padding: 60px 30px;
  }
}

.p-productsDetail__header {
  margin-bottom: 40px;
}
@media screen and (min-width: 48em) {
  .p-productsDetail__header {
    margin-bottom: 60px;
  }
}

.p-productsDetail__container {
  margin-bottom: 80px;
}
@media screen and (min-width: 85.375em) {
  .p-productsDetail__container {
    display: grid;
    grid-template-columns: 485px 384px;
    grid-column-gap: 20px;
  }
}

.p-productsDetail_wrapper::after, .p-productsDetail_wrapper::before {
  position: absolute;
  top: -2px;
  left: 50%;
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-color: transparent;
  transform: translateX(-50%);
}
@media screen and (max-width: 85.37375em) {
  .p-productsDetail_wrapper::after, .p-productsDetail_wrapper::before {
    content: "";
  }
}

.p-productsDetail__slider {
  margin-bottom: 60px;
}

.p-productsDetail__info {
  font-size: 0.875rem;
}
@media screen and (max-width: 85.37375em) {
  .p-productsDetail__info {
    margin-bottom: 60px;
  }
}

.p-productsDetail__description {
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 1px dotted #000;
  border-bottom: 1px dotted #000;
}

.p-productsDetail__freearea {
  margin-top: 20px;
  font-size: 0.875rem;
}

.p-productsDetail__data {
  margin-top: 20px;
}

@media screen and (max-width: 85.37375em) {
  .p-productsDetail__right {
    margin-top: 40px;
  }
}

.p-productsDetail__attention {
  margin-bottom: 40px;
}

.p-productsDetail_wrapper {
  position: relative;
}
@media screen and (max-width: 85.37375em) {
  .p-productsDetail_wrapper {
    padding: 60px 20px;
    border-top: 2px solid #BF0030;
    background-color: #F5F5F5;
  }
}
.p-productsDetail_wrapper::before {
  border-width: 12px 11px 0 11px;
  border-top-color: #BF0030;
}
.p-productsDetail_wrapper::after {
  border-width: 10px 9px 0 9px;
  border-top-color: #fff;
}

.p-productsDetail__attention > div + div {
  margin-top: 10px;
}

.p-mypageDelivery__addAddress {
  max-width: 17.75rem;
  margin-bottom: 40px;
}

.p-mypageFavorite__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 10px;
  max-width: 890px;
  margin: auto;
}
@media screen and (min-width: 48em) {
  .p-mypageFavorite__list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 85.375em) {
  .p-mypageFavorite__list {
    grid-gap: 40px 30px;
  }
}

.p-mypageWithdraw__content {
  max-width: 626px;
  margin: auto;
}

.p-mypageWithdraw__icon {
  max-width: 90px;
  margin: 0 auto 60px;
}

.p-mypageWithdraw__actions {
  max-width: 17.75rem;
  margin: auto;
}
.p-mypageWithdraw__actions .btn-a {
  width: 100%;
  padding: 18px;
}
.p-mypageWithdraw__actions .btn-b {
  width: 100%;
  margin-top: 40px;
}

.p-shoppingLogin__subTitle {
  margin-bottom: 40px;
  color: #BF0030;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
}

@media screen and (min-width: 48em) {
  .p-shoppingLogin__container {
    display: flex;
  }
}

.p-shoppingLogin__box {
  flex: 1;
  padding: 60px 30px;
  background-color: #F5F5F5;
}
.p-shoppingLogin__box.-login {
  margin-bottom: 60px;
}
@media screen and (min-width: 48em) {
  .p-shoppingLogin__box.-login {
    max-width: 788px;
    margin-right: 20px;
    margin-bottom: 0;
  }
}
.p-shoppingLogin__box.-guest {
  max-width: 384px;
  text-align: center;
}
.p-shoppingLogin__box .action-guest {
  width: 100%;
  max-width: 17.1875rem;
  padding: 18px;
  font-size: 1rem;
}

.p-shoppingNonmember__container {
  max-width: 788px;
  margin: auto;
}

.p-shoppingNonmember__datalist {
  margin-bottom: 60px;
}
.p-shoppingNonmember__datalist dt {
  max-width: 242px;
}

.p-about__siteLogo {
  max-width: 140px;
  margin: 0 auto 40px;
}

.p-about__description {
  margin-bottom: 60px;
  font-size: 0.875rem;
}

@media screen and (min-width: 48em) {
  .p-about__datalist dt {
    max-width: 242px;
  }
}

.p-privacy__section {
  margin-bottom: 60px;
  font-size: 0.875rem;
}
.p-privacy__section li {
  margin-bottom: 1em;
}
.p-privacy__section li:last-child {
  margin-bottom: 0;
}

.p-tradelaw__datalist {
  font-size: 0.875rem;
}
@media screen and (min-width: 48em) {
  .p-tradelaw__datalist dt {
    max-width: 242px;
  }
}
.p-tradelaw__datalist li {
  margin-bottom: 0.25em;
}
.p-tradelaw__datalist li:last-child {
  margin-bottom: 0;
}

.estimate-01__content section {
  margin-bottom: 60px;
}
.estimate-01__content section:last-child {
  margin-bottom: 0;
}
.estimate-01__content h3 {
  margin-bottom: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.12;
}
.estimate-01__content p {
  font-size: 0.875rem;
}
.estimate-01__content .introduction {
  margin-bottom: 40px;
}
@media screen and (min-width: 48em) {
  .estimate-01__content .introduction {
    display: flex;
    flex-wrap: wrap;
  }
}
.estimate-01__content .introduction-logo {
  max-width: 283px;
  margin: auto;
}
@media screen and (min-width: 48em) {
  .estimate-01__content .introduction-logo {
    flex: 0 0 283px;
  }
}
@media screen and (min-width: 48em) {
  .estimate-01__content .introduction-text {
    flex: 1;
    padding-left: 20px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

ページ内で見出しとして機能する要素のスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.1
*/
/*
見出し

商品紹介等で利用される、一般的な見出しのスタイルです。

ex [商品詳細ページ　商品見出し部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-headingTitle マトリョーシカ

Styleguide 1.1.1
*/
.ec-headingTitle {
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: normal;
  color: #525263;
}

/*
ページヘッダ

各種ページで用いられるページヘッダのデザインです。

ex [利用規約ページ　ページヘッダ部](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-pageHeader
  h1 利用規約

Styleguide 1.1.2
*/
.ec-pageHeader h1 {
  margin: 0 0 8px;
  border-bottom: 1px dotted #ccc;
  border-top: 1px solid #ccc;
  padding: 8px 0 12px;
  font-size: 16px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-pageHeader h1 {
    border-top: none;
    border-bottom: 1px solid #ccc;
    margin: 10px 16px 48px;
    padding: 8px;
    font-size: 32px;
    font-weight: bold;
  }
}

/*
サブ見出し

利用規約など、文字主体のページで用いられるサブ見出しです。

ex [利用規約ページ サブ見出し部分](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-heading 第1条 (会員)

Styleguide 1.1.3
*/
.ec-heading {
  margin: 24px 0;
}

/*
サブ見出し(太字)

文字主体のページで用いられるサブ見出しの太字のスタイルです。

ex [プライバシーポリシー サブ見出し部分](http://demo3.ec-cube.net/help/privacy)

Markup:
.ec-heading-bold 個人情報の定義

Styleguide 1.1.4
*/
.ec-heading-bold {
  margin: 16px 0;
  font-size: 16px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-heading-bold {
    font-size: 18px;
  }
}

/*
背景付き見出し

マイページ注文履歴等で用いられる背景付きの見出しです。

ex [ご注文履歴詳細　背景付き見出し部分](http://demo3.ec-cube.net/mypage/history/1063)

Markup:
.ec-rectHeading
  h2 配送情報
.ec-rectHeading
  h2 お支払について

Styleguide 1.1.5
*/
.ec-rectHeading h1, .ec-rectHeading h2, .ec-rectHeading h3,
.ec-rectHeading h4, .ec-rectHeading h5, .ec-rectHeading h6 {
  background: #F5F5F5;
  padding: 8px 12px;
  font-size: 20px;
  font-weight: bold;
}

/*
メッセージ見出し

ユーザが行った操作に対する、完了報告やエラー表示のページで使用される見出しのスタイルです。

ex [注文完了 ログイン後、カートに商品を入れ注文完了まで行う](http://demo3.ec-cube.net/shopping/)

Markup:
.ec-reportHeading
  h2 ご注文ありがとうございました

Styleguide 1.1.6
*/
.ec-reportHeading {
  width: 100%;
  border-top: 1px dotted #ccc;
  margin: 20px 0 30px;
  padding: 0;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-reportHeading {
    border-top: 0;
    font-size: 32px;
  }
}
.ec-reportHeading h1, .ec-reportHeading h2, .ec-reportHeading h3,
.ec-reportHeading h4, .ec-reportHeading h5, .ec-reportHeading h6, .ec-reportHeading p {
  font-weight: bold;
  font-size: 24px;
}
@media only screen and (min-width: 768px) {
  .ec-reportHeading h1, .ec-reportHeading h2, .ec-reportHeading h3,
.ec-reportHeading h4, .ec-reportHeading h5, .ec-reportHeading h6, .ec-reportHeading p {
    font-size: 32px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
文字装飾

文字装飾をするためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.2
*/
/*
テキストリンク

テキストリンクのスタイルです。

Markup:
a(href="#").ec-link さくらのクラウド

Styleguide 1.2.1
*/
.ec-link {
  color: #0092C4;
  text-decoration: none;
  cursor: pointer;
}
.ec-link:hover {
  color: #33A8D0;
  text-decoration: none;
}

/*
テキスト（太字）

テキストを太くするためのスタイルです。

Markup:
p.ec-font-bold この季節にぴったりな商品をご用意しました

Styleguide 1.2.2
*/
.ec-font-bold {
  font-weight: bold;
}

/*
テキスト（グレー）

テキストをグレーにするためのスタイルです。

Markup:
p.ec-color-grey 青色が美しい職人が仕上げた吹きガラス

Styleguide 1.2.3
*/
.ec-color-grey {
  color: #9a947e;
}

/*
テキスト（赤）

テキストを赤にするためのスタイルです。

Markup:
p.ec-color-red ¥ 2,728 税込
p.ec-color-accent ¥ 2,728 税込

Styleguide 1.2.4
*/
.ec-color-red {
  color: #DE5D50;
}

.ec-color-accent {
  color: #DE5D50;
}

/*
フォントサイズ

フォントサイズを指定するためのスタイルです。

Markup:
.ec-font-size-1 さわやかな日差しが過ごしやすい季節
.ec-font-size-2 さわやかな日差しが過ごしやすい季節
.ec-font-size-3 さわやかな日差しが過ごしやすい季節
.ec-font-size-4 さわやかな日差しが過ごしやすい季節
.ec-font-size-5 さわやかな日差しが過ごしやすい季節
.ec-font-size-6 さわやかな日差しが過ごしやすい季節


Styleguide 1.2.5
*/
.ec-font-size-1 {
  font-size: 12px;
}

.ec-font-size-2 {
  font-size: 14px;
}

.ec-font-size-3 {
  font-size: 16px;
}

.ec-font-size-4 {
  font-size: 20px;
}

.ec-font-size-5 {
  font-size: 32px;
}

.ec-font-size-6 {
  font-size: 40px;
}

/*
テキスト水平位置

テキストをセンタリングするためのスタイルです。

Markup:
p.ec-text-ac さわやかな日差しが過ごしやすい季節

Styleguide 1.2.6
*/
.ec-text-ac {
  text-align: center;
}

/*
価格テキスト

価格を表示するテキストです。

価格文字にスペースを取るほか、税込み等の表示を小さくする効果もあります。

spanを用いたインライン要素として利用します。

Markup:
div(style="color:#DE5D50;font-size:28px")
    span.ec-price
      span.ec-price__unit ¥
      span.ec-price__price 1,280
      span.ec-price__tax 税込

Styleguide 1.2.7
*/
.ec-price .ec-price__unit {
  font-size: 18px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-price .ec-price__unit {
    font-size: 1em;
  }
}
.ec-price .ec-price__price {
  display: inline-block;
  padding: 0 0.3em;
  font-size: 18px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-price .ec-price__price {
    font-size: 1em;
  }
}
.ec-price .ec-price__tax {
  font-size: 12px;
}
@media only screen and (min-width: 768px) {
  .ec-price .ec-price__tax {
    font-size: 0.57em;
  }
}

/*
テキストの位置

テキストや、入れ子にしたインライン要素を
「左揃え」「中央揃え」「右揃え」に設定することができます。

Markup:
h3 左揃え
p.text-left
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?
br
h3 中央揃え
p.text-center
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?
br
h3 右揃え
p.text-right
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?

Styleguide 1.2.8
*/
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/*
メッセージテキスト

ユーザが行った操作に対する、完了報告やエラー表示のページで使用されるテキストのスタイルです。

ex [注文完了 （ログイン後、カートに商品を入れ注文完了まで行う）](http://demo3.ec-cube.net/shopping/)

Markup:
.ec-reportHeading
  h2 ご注文ありがとうございました
p.ec-reportDescription
      | ただいま、ご注文の確認メールをお送りさせていただきました。
      br
      | 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。
      br
      | 今後ともご愛顧賜りますようよろしくお願い申し上げます。


Styleguide 1.2.9
*/
.ec-reportDescription {
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
}

/*
テキスト下部のスペース

テキストの下に余白を追加することができます。 .ec-para-normalで16pxの余白をつけることができます。

Markup:
p.ec-para-normal 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。
p.ec-para-normal 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。

Styleguide 1.2.10
*/
.ec-para-normal {
  margin-bottom: 16px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
リスト

シンプルなリストを構成するためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.3
*/
/*
水平定義リスト

シンプルな定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　水平定義リスト部分](http://demo3.ec-cube.net/help/about)

Markup:
dl.ec-definitions
    dt 店名
    dd EC-CUBE3 DEMO SHOP
dl.ec-definitions
    dt 会社名
    dd EC-CUBE3
dl.ec-definitions--soft
    dt 所在地
    dd 〒 550-0001

Styleguide 1.3.1
*/
.ec-definitions, .ec-definitions--soft {
  margin: 5px 0;
  display: block;
}
.ec-definitions dt, .ec-definitions--soft dt, .ec-definitions dd, .ec-definitions--soft dd {
  display: inline-block;
  margin: 0;
}
.ec-definitions dt, .ec-definitions--soft dt {
  font-weight: bold;
}

.ec-definitions--soft dt {
  font-weight: normal;
}

/*
下線つき定義リスト

線が添えられた定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　下線つき定義リスト](http://demo3.ec-cube.net/help/about)

Markup:
.ec-borderedDefs
  dl
    dt 店名
    dd EC-CUBE3 DEMO SHOP
  dl
    dt 会社名
    dd EC-CUBE3
  dl
    dt 所在地
    dd 〒550 - 0001

Styleguide 1.3.2
*/
.ec-borderedDefs {
  width: 100%;
  border-top: 1px dotted #ccc;
  margin-bottom: 16px;
}
.ec-borderedDefs dl {
  display: flex;
  border-bottom: 1px dotted #ccc;
  margin: 0;
  padding: 10px 0 0;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .ec-borderedDefs dl {
    flex-wrap: nowrap;
    padding: 15px 0 4px;
  }
}
.ec-borderedDefs dt, .ec-borderedDefs dd {
  padding: 0;
}
.ec-borderedDefs dt {
  font-weight: normal;
  width: 100%;
  padding-top: 0;
}
@media only screen and (min-width: 768px) {
  .ec-borderedDefs dt {
    padding-top: 14px;
    width: 30%;
  }
}
.ec-borderedDefs dd {
  padding: 0;
  width: 100%;
  line-height: 2.5;
}
@media only screen and (min-width: 768px) {
  .ec-borderedDefs dd {
    width: 70%;
    line-height: 3;
  }
}
.ec-borderedDefs p {
  line-height: 1.4;
}

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0;
}
.ec-list-chilled dt, .ec-list-chilled dd {
  display: table-cell;
  border-bottom: 1px dotted #ccc;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .ec-list-chilled dt, .ec-list-chilled dd {
    padding: 16px 0;
  }
}
.ec-list-chilled dt {
  width: 30%;
}
.ec-list-chilled dd {
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .ec-list-chilled dd {
    padding: 16px;
  }
}

/*
ボーダーリスト

線が添えられたリストを表示します。

ex [当サイトについて　ボーダーリスト](http://demo3.ec-cube.net/help/about)

Markup:
ul.ec-borderedList
  li: p lorem
  li: p lorem
  li: p lorem


Styleguide 1.3.3
*/
.ec-borderedList {
  width: 100%;
  border-top: 0;
  list-style: none;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .ec-borderedList {
    border-top: 1px dotted #ccc;
  }
}
.ec-borderedList li {
  border-bottom: 1px dotted #ccc;
}

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0;
}
.ec-list-chilled dt, .ec-list-chilled dd {
  display: table-cell;
  border-bottom: 1px dotted #ccc;
  padding: 16px 0;
}
.ec-list-chilled dt {
  width: 30%;
}
.ec-list-chilled dd {
  padding: 16px;
}

/*
ボタンサイズ

ボタンサイズを変更するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.1
*/
/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

ex [トップページ　ボタン部分](http://demo3.ec-cube.net/)

Markup:
.ec-inlineBtn 住所検索
.ec-inlineBtn--primary もっと見る
.ec-inlineBtn--action カートに入れる
.ec-inlineBtn--cancel キャンセル

Styleguide 2.1.1
*/
.ec-inlineBtn {
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  border: 0;
  background-color: #F5F7F8;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: #525263;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
}
.ec-inlineBtn:hover, .ec-inlineBtn:focus {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.ec-inlineBtn.disabled, .ec-inlineBtn[disabled], fieldset[disabled] .ec-inlineBtn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ec-inlineBtn--primary {
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  border: 0;
  background-color: #5CB1B1;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
}
.ec-inlineBtn--primary:hover, .ec-inlineBtn--primary:focus {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.ec-inlineBtn--primary.disabled, .ec-inlineBtn--primary[disabled], fieldset[disabled] .ec-inlineBtn--primary {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ec-inlineBtn--action {
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  border: 0;
  background-color: #BF0030;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
}
.ec-inlineBtn--action:hover, .ec-inlineBtn--action:focus {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.ec-inlineBtn--action.disabled, .ec-inlineBtn--action[disabled], fieldset[disabled] .ec-inlineBtn--action {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ec-inlineBtn--cancel {
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  border: 0;
  background-color: #525263;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
}
.ec-inlineBtn--cancel:hover, .ec-inlineBtn--cancel:focus {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.ec-inlineBtn--cancel.disabled, .ec-inlineBtn--cancel[disabled], fieldset[disabled] .ec-inlineBtn--cancel {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*
ブロックボタン（全幅）

ボタンサイズは em で指定するため、テキストサイズの変更でボタンサイズを変更できます。

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
p: .ec-blockBtn 住所検索
p: .ec-blockBtn--primary もっと見る
p: .ec-blockBtn--action カートに入れる
p: .ec-blockBtn--cancel キャンセル

Styleguide 2.1.2
*/
.ec-blockBtn {
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  border: 0;
  background-color: #F5F7F8;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: #525263;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
}
.ec-blockBtn:hover, .ec-blockBtn:focus {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.ec-blockBtn.disabled, .ec-blockBtn[disabled], fieldset[disabled] .ec-blockBtn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ec-blockBtn--primary {
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  border: 0;
  background-color: #5CB1B1;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
}
.ec-blockBtn--primary:hover, .ec-blockBtn--primary:focus {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.ec-blockBtn--primary.disabled, .ec-blockBtn--primary[disabled], fieldset[disabled] .ec-blockBtn--primary {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ec-blockBtn--action {
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  border: 0;
  background-color: #BF0030;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
}
.ec-blockBtn--action:hover, .ec-blockBtn--action:focus {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.ec-blockBtn--action.disabled, .ec-blockBtn--action[disabled], fieldset[disabled] .ec-blockBtn--action {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ec-blockBtn--cancel {
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  border: 0;
  background-color: #525263;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
}
.ec-blockBtn--cancel:hover, .ec-blockBtn--cancel:focus {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.ec-blockBtn--cancel.disabled, .ec-blockBtn--cancel[disabled], fieldset[disabled] .ec-blockBtn--cancel {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*
アイコンボタン

SVGアイコンを用いたアイコンボタンです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 2.2
*/
/*
アイコンボタン

閉じるなどSVGアイコンを用いたボタン装飾で利用します。

ex [ログイン画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/login)

Markup:
a.ec-closeBtn
  .ec-icon
    img(src='/moc/icon/cross.svg', alt='close')

Styleguide 2.2.1
*/
.ec-closeBtn {
  cursor: pointer;
}
.ec-closeBtn .ec-icon img {
  display: inline-block;
  margin-right: 5px;
  width: 1em;
  height: 1em;
  position: relative;
  top: -1px;
  vertical-align: middle;
}

/*
アイコンボタン(○)

閉じるなどSVGアイコンを用いたボタン装飾で利用します。

ex [ログイン画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/login)



ex [お届け先編集画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/delivery)

Markup:
a.ec-closeBtn--circle
  .ec-icon
    img(src='/moc/icon/cross-white.svg', alt='close')

Styleguide 2.2.2
*/
.ec-closeBtn--circle {
  display: block;
  border: 0 none;
  padding: 0;
  margin: 0;
  text-shadow: none;
  box-shadow: none;
  border-radius: 50%;
  background: #B8BEC4;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  text-align: center;
}
.ec-closeBtn--circle .ec-icon img {
  display: block;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
その他のボタン

通常のボタンや、アイコンボタン以外のボタンを定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.3
*/
/*
ページトップボタン

ページトップボタンを表示します

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
.ec-blockTopBtn

Styleguide 2.3.1
*/
.ec-blockTopBtn {
  display: none;
  position: fixed;
  width: 120px;
  height: 40px;
  right: 0;
  bottom: 10px;
  cursor: pointer;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  opacity: 0.8;
  background-color: #9da3a9;
}
@media only screen and (min-width: 768px) {
  .ec-blockTopBtn {
    right: 30px;
    bottom: 30px;
  }
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
フォーム部品(テキスト)

テキストや数値の入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.1
*/
/*
フォーム

`.ec-input` 要素は全ての入力項目に関する標準的なコンポーネントクラスです。


ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-input
  input(type="number")
p.ec-input
  textarea(rows="6")

Styleguide 3.1.1
*/
/*
フォーム（text２つ）

姓名など2つ入力させたい入力項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-halfInput
  input(type="text")
  input(type="text")
p.ec-halfInput
  input(type="text")

Styleguide 3.1.2
*/
/*
数量ボタン

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [商品詳細画面　数量ボタン部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-numberInput
  span 数量
  input(type="number",value="0")

Styleguide 3.1.3
*/
/*
電話番号ボタン

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　電話番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-telInput
  input(type="text")

Styleguide 3.1.5
*/
/*
フォーム部品(その他)

フォーム部品でテキストの入力以外の動作要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 3.2
*/
/*
ラジオ（水平）

水平に並ぶラジオボタンフィールドです。

各要素をlabelでくくって、コーディングします。

ex [新規会員登録画面　性別選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-radio
  label
    input(type="radio")
    span 男性
  label
    input(type="radio")
    span 女性

Styleguide 3.2.2
*/
.ec-radio label {
  margin-right: 20px;
}
.ec-radio input {
  margin-right: 10px;
  margin-bottom: 10px;
}
.ec-radio span {
  font-weight: normal;
}

/*
ラジオ(垂直)

垂直に並ぶラジオボタンフィールドです。

各要素をlabelでくくって、コーディングします。

ex [購入画面 お支払方法](http://demo3.ec-cube.net/shopping)

Markup:
.ec-blockRadio
  label
    input(type="radio")
    span 郵便振替
  label
    input(type="radio")
    span 現金書留
  label
    input(type="radio")
    span 銀行振込
  label
    input(type="radio")
    span 代金引換

Styleguide 3.2.3
*/
.ec-blockRadio label {
  display: block;
}
.ec-blockRadio span {
  padding-left: 10px;
  font-weight: normal;
}

/*
セレクトボックス

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　都道府県選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-select
  select
    option 都道府県を選択
    option 北海道
    option 青森県
    option 岩手県
    option ...
.ec-select
  select
    option 選択して下さい
    option 公務員
    option コンサルタント
    option コンピュータ関連技術職
    option コンピュータ関連以外の技術職
    option ...

Styleguide 3.2.4
*/
/*
生年月日選択

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　生年月日選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-birth
  select
    option ----
    option 1960
    option 1961
    option 1962
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...

Styleguide 3.2.5
*/
/*
チェックボックス （水平）

水平に並ぶチェックボックス フィールドです。

各要素をlabelでくくって、コーディングします。

ex [新規会員登録画面　利用規約](http://demo3.ec-cube.net/entry)

Markup:
.ec-checkbox
  label
    input(type="checkbox")
    span 利用規約に同意する

Styleguide 3.2.6
*/
.ec-checkbox label {
  display: inline-block;
}
.ec-checkbox input {
  margin-bottom: 10px;
}
.ec-checkbox span {
  font-weight: normal;
}

/*
チェックボックス (垂直)

垂直に並ぶチェックボックス フィールドです。

各要素をlabelでくくって、コーディングします。

Markup:
.ec-blockCheckbox
  label
    input(type="checkbox")
    span 利用規約に同意する

Styleguide 3.2.7
*/
.ec-blockCheckbox label {
  display: block;
}
.ec-blockCheckbox span {
  font-weight: normal;
}

/*
アイコン

デフォルトテンプレートのアイコンは`.ec-icon`>`img`タグで使用することができます

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Markup:
include /assets/tmpl/elements/4.1.icon.pug
div(style="background-color: rgba(130,130,130,.15); padding: 20px;")
  +icon-all

Styleguide 4.1
*/
.ec-icon img {
  max-width: 80px;
  max-height: 80px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
グリッド

画面を12分割し、グリッドレイアウトに対応するためのスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.1
*/
/*
2分割グリッド

画面 ２分割の　グリッドです。
Bootstrap の col-sm-6 相当のグリッドを提供します。

Markup:
.ec-grid2
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 5.1.1
*/
.ec-grid2 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-grid2 {
    display: flex;
  }
}
.ec-grid2 .ec-grid2__cell {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid2 .ec-grid2__cell {
    width: 50%;
  }
}
.ec-grid2 .ec-grid2__cell2 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid2 .ec-grid2__cell2 {
    width: 100%;
  }
}
/*
3分割グリッド

画面　３分割の　グリッドです。


Markup:
.ec-grid3
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell

Styleguide 5.1.2
*/
.ec-grid3 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-grid3 {
    display: flex;
  }
}
.ec-grid3 .ec-grid3__cell {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid3 .ec-grid3__cell {
    width: 33.3333333333%;
  }
}
.ec-grid3 .ec-grid3__cell2 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid3 .ec-grid3__cell2 {
    width: 66.6666666667%;
  }
}
.ec-grid3 .ec-grid3__cell3 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid3 .ec-grid3__cell3 {
    width: 100%;
  }
}
/*
4分割グリッド

画面　４分割の　グリッドです。


Markup:
.ec-grid4
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell

Styleguide 5.1.3
*/
.ec-grid4 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-grid4 {
    display: flex;
  }
}
.ec-grid4 .ec-grid4__cell {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid4 .ec-grid4__cell {
    width: 25%;
  }
}
/*
6分割グリッド

2つにまとめた cell2 や 3つをまとめた cell3 タグも使用可能です。


Markup:
.ec-grid6
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
.ec-grid6
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
.ec-grid6
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3

Styleguide 5.1.4
*/
.ec-grid6 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-grid6 {
    display: flex;
  }
}
.ec-grid6 .ec-grid6__cell {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid6 .ec-grid6__cell {
    width: 16.6666666667%;
  }
}
.ec-grid6 .ec-grid6__cell2 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid6 .ec-grid6__cell2 {
    width: 33.3333333333%;
  }
}
.ec-grid6 .ec-grid6__cell3 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid6 .ec-grid6__cell3 {
    width: 50%;
  }
}
/*
中央寄せグリッド 10/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の１０グリッドです

ex [ご利用規約ページ　本文](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-off1Grid
  .ec-off1Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.5
*/
.ec-off1Grid {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off1Grid {
    display: block;
    margin: 0;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off1Grid {
    display: flex;
  }
}
.ec-off1Grid .ec-off1Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off1Grid .ec-off1Grid__cell {
    position: relative;
    min-height: 1px;
    margin-left: 8.3333333333%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off1Grid .ec-off1Grid__cell {
    width: 83.3333333333%;
  }
}
/*
中央寄せグリッド 8/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の８グリッドです


Markup:
.ec-off2Grid
  .ec-off2Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.6
*/
.ec-off2Grid {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off2Grid {
    display: flex;
  }
}
.ec-off2Grid .ec-off2Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off2Grid .ec-off2Grid__cell {
    position: relative;
    min-height: 1px;
    margin-left: 16.6666666667%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off2Grid .ec-off2Grid__cell {
    width: 66.6666666667%;
  }
}
/*
中央寄せグリッド 6/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の６グリッドです


Markup:
.ec-off3Grid
  .ec-off3Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.7
*/
.ec-off3Grid {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off3Grid {
    display: flex;
  }
}
.ec-off3Grid .ec-off3Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off3Grid .ec-off3Grid__cell {
    position: relative;
    min-height: 1px;
    margin-left: 25%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off3Grid .ec-off3Grid__cell {
    width: 50%;
  }
}
/*
中央寄せグリッド 4/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の４グリッドです


Markup:
.ec-off4Grid
  .ec-off4Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod


Styleguide 5.1.8
*/
.ec-off4Grid {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off4Grid {
    display: flex;
  }
}
.ec-off4Grid .ec-off4Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off4Grid .ec-off4Grid__cell {
    position: relative;
    min-height: 1px;
    margin-left: 33.3333333333%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off4Grid .ec-off4Grid__cell {
    width: 33.3333333333%;
  }
}
/*
グリッドオプション

グリッドのセルに対して「左寄せ」「中央寄せ」「右寄せ」のオプションを付与することができます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 5.1.9
*/
/*
グリッドセルの左寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--left
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.10
*/
.ec-grid--left {
  justify-content: flex-start;
}

/*
グリッドセルの右寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--right
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.11
*/
.ec-grid--right {
  justify-content: flex-end;
}

/*
グリッドセルの中央寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--center
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.12
*/
.ec-grid--center {
  justify-content: center;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ログイン

主にログインフォームのスタイルを表示します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 6.1
*/
/*
ログインフォーム

ログインフォームを表示します。

ex [ログイン画面](http://demo3.ec-cube.net/mypage/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-login


Styleguide 6.1.1
*/
.ec-login {
  margin: 0 0 20px;
  padding: 30px 13% 20px;
  height: auto;
  background: #F3F4F4;
  box-sizing: border-box;
}
@media only screen and (min-width: 768px) {
  .ec-login {
    margin: 0 16px;
    padding: 30px 13% 60px;
  }
}
.ec-login .ec-login__icon {
  text-align: center;
}
.ec-login .ec-icon {
  margin-bottom: 10px;
}
.ec-login .ec-icon img {
  width: 90px;
  height: 90px;
  display: inline-block;
}
.ec-login .ec-login__input {
  margin-bottom: 40px;
}
.ec-login .ec-login__input .ec-checkbox span {
  margin-left: 5px;
  font-weight: normal;
}
.ec-login .ec-login__actions {
  color: #fff;
}
.ec-login .ec-login__actions a {
  color: inherit;
  text-decoration: none;
}
.ec-login .ec-login__actions a:hover {
  text-decoration: none;
}
.ec-login .ec-login__link {
  margin-top: 5px;
  margin-left: 0;
}
@media only screen and (min-width: 768px) {
  .ec-login .ec-login__link {
    margin-left: 20px;
  }
}
.ec-login .ec-errorMessage {
  color: #BF0030;
  margin-bottom: 20px;
}

/*
ゲスト購入

ゲスト購入ボタンとそのフォームを表示します。

ex [ゲスト購入画面](http://demo3.ec-cube.net/shopping/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-guest
hoge

Styleguide 6.1.2
*/
.ec-guest {
  display: table;
  margin: 0;
  padding: 13%;
  height: auto;
  box-sizing: border-box;
  background: #F3F4F4;
}
@media only screen and (min-width: 768px) {
  .ec-guest {
    height: 100%;
    margin: 0 16px;
  }
}
.ec-guest .ec-guest__inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.ec-guest .ec-guest__inner p {
  margin-bottom: 16px;
}
.ec-guest .ec-guest__actions {
  display: block;
  vertical-align: middle;
  text-align: center;
  color: #fff;
}
.ec-guest .ec-guest__actions a {
  color: inherit;
  text-decoration: none;
}
.ec-guest .ec-guest__actions a:hover {
  text-decoration: none;
}
.ec-guest .ec-guest__icon {
  font-size: 70px;
  text-align: center;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
商品掲載

トップページに商品掲載するスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.1
*/
/*
商品アイテム（商品紹介B）

３項目横並びの商品アイテムを表示します。
必要に応じて商品詳細や、キャッチコピーなどを添えることが出来ます。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayB

Styleguide 7.1.1
*/
.ec-displayB {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .ec-displayB {
    flex-direction: row;
  }
}
.ec-displayB .ec-displayB__cell {
  width: 100%;
  margin-bottom: 16px;
}
.ec-displayB .ec-displayB__cell a {
  color: inherit;
  text-decoration: none;
}
.ec-displayB .ec-displayB__cell a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-displayB .ec-displayB__cell {
    width: 31.4466%;
    margin-bottom: 0;
  }
}
.ec-displayB .ec-displayB__cell:hover {
  text-decoration: none;
}
.ec-displayB .ec-displayB__cell:hover img {
  opacity: 0.8;
}
.ec-displayB .ec-displayB__cell:hover a {
  text-decoration: none;
}
.ec-displayB .ec-displayB__img {
  margin-bottom: 15px;
}
.ec-displayB .ec-displayB__catch {
  margin-bottom: 15px;
  text-decoration: none;
  font-weight: bold;
  color: #9a947e;
}
.ec-displayB .ec-displayB__comment {
  margin-bottom: 14px;
  text-decoration: none;
  color: #525263;
  font-size: 14px;
}
.ec-displayB .ec-displayB__link {
  text-decoration: none;
  font-weight: bold;
  color: #9a947e;
}

/*
商品アイテム（商品紹介C）

４項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayC
p hoge

Styleguide 7.1.2
*/
.ec-displayC {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
}
.ec-displayC .ec-displayC__cell {
  width: 47%;
}
.ec-displayC .ec-displayC__cell a {
  color: inherit;
  text-decoration: none;
}
.ec-displayC .ec-displayC__cell a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-displayC .ec-displayC__cell {
    width: 22.8775%;
  }
}
.ec-displayC .ec-displayC__cell:hover a {
  text-decoration: none;
}
.ec-displayC .ec-displayC__cell:hover img {
  opacity: 0.8;
}
.ec-displayC .ec-displayC__img {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.ec-displayC .ec-displayC__catch {
  display: block;
  width: 100%;
  font-weight: bold;
  color: #9a947e;
}
.ec-displayC .ec-displayC__title {
  display: block;
  width: 100%;
  color: #525263;
}
.ec-displayC .ec-displayC__price {
  display: block;
  width: 100%;
  font-weight: bold;
  color: #525263;
}
.ec-displayC .ec-displayC__price--sp {
  display: block;
  width: 100%;
  font-weight: bold;
  color: #DE5D50;
}

/*
商品アイテム（商品紹介D）

６項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayD

Styleguide 7.1.3
*/
.ec-displayD {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}
@media only screen and (min-width: 768px) {
  .ec-displayD {
    box-sizing: border-box;
    flex-wrap: nowrap;
  }
}
.ec-displayD .ec-displayD__cell {
  width: 30%;
  margin-bottom: 8px;
}
.ec-displayD .ec-displayD__cell a {
  color: inherit;
  text-decoration: none;
}
.ec-displayD .ec-displayD__cell a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-displayD .ec-displayD__cell {
    width: 14.3083%;
    margin-bottom: 16px;
  }
}
.ec-displayD .ec-displayD__cell:hover {
  text-decoration: none;
}
.ec-displayD .ec-displayD__cell:hover img {
  opacity: 0.8;
}
.ec-displayD .ec-displayD__img {
  display: block;
  width: 100%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
検索・一覧表示

検索欄や、一覧表示に使用するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.2
*/
/*
トピックパス

検索結果で表示されるトピックパスのスタイルです。

ex [商品一覧ページ　横並びリスト部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-topicpath

Styleguide 7.2.1
*/
.ec-topicpath {
  letter-spacing: -0.4em;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px dotted #ccc;
  padding: 10px;
  list-style: none;
  overflow: hidden;
  font-size: 12px;
  color: #0092C4;
}
@media only screen and (min-width: 768px) {
  .ec-topicpath {
    padding: 30px 0 10px;
    border: 0;
    font-size: 16px;
  }
}
.ec-topicpath .ec-topicpath__item a {
  color: inherit;
  text-decoration: none;
}
.ec-topicpath .ec-topicpath__item a:hover {
  text-decoration: none;
}
.ec-topicpath .ec-topicpath__divider {
  color: #000;
}
.ec-topicpath .ec-topicpath__item, .ec-topicpath .ec-topicpath__divider, .ec-topicpath .ec-topicpath__item--active {
  display: inline-block;
  min-width: 16px;
  text-align: center;
  position: relative;
  letter-spacing: normal;
}
.ec-topicpath .ec-topicpath__item--active {
  font-weight: bold;
}
.ec-topicpath .ec-topicpath__item--active a {
  color: inherit;
  text-decoration: none;
}
.ec-topicpath .ec-topicpath__item--active a:hover {
  text-decoration: none;
}

/*
ページャ

検索結果で表示される商品一覧のスタイルです。

ex [商品一覧ページ　ページャ部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-pager

Styleguide 7.2.2
*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1;
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
カート

ショッピングカートに関するスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.3
*/
/*
カートヘッダ

購入完了までの手順や、現在の状態を表示します。

ul 要素を用いたリスト要素としてマークアップします。

ex [カートページ　ヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-progress

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3.1
*/
/*
カートナビゲーション

カートナビゲーションを表示します。　カートに追加された商品の個数も表示します。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerCart

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.5
*/
@media only screen and (min-width: 768px) {
  .ec-cartNaviWrap {
    position: relative;
  }
}

.ec-cartNavi {
  display: inline-block;
  padding: 10px 0 0 20px;
  width: auto;
  color: black;
  background: transparent;
}
@media only screen and (min-width: 768px) {
  .ec-cartNavi {
    display: flex;
    justify-content: space-between;
    border-radius: 99999px;
    box-sizing: border-box;
    padding: 12px 17px 10px;
    width: auto;
    min-width: 140px;
    height: 44px;
    white-space: nowrap;
    cursor: pointer;
    background: #F8F8F8;
  }
}
.ec-cartNavi .ec-cartNavi__icon {
  display: inline-block;
  font-size: 20px;
  display: inline-block;
  opacity: 1;
  visibility: visible;
  animation: fadeIn 200ms linear 0s;
  position: relative;
}
.ec-cartNavi .ec-cartNavi__badge {
  display: inline-block;
  border-radius: 99999px;
  box-sizing: border-box;
  padding: 5px;
  height: 17px;
  font-size: 10px;
  line-height: 0.7;
  vertical-align: top;
  color: #fff;
  text-align: left;
  white-space: nowrap;
  background-color: #DE5D50;
  position: absolute;
  left: 60%;
  top: -10px;
}
@media only screen and (min-width: 768px) {
  .ec-cartNavi .ec-cartNavi__badge {
    display: inline-block;
    min-width: 17px;
    position: relative;
    left: 0;
    top: 0;
  }
}
.ec-cartNavi .ec-cartNavi__price {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-cartNavi .ec-cartNavi__price {
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
  }
}

.ec-cartNavi.is-active .ec-cartNavi__icon:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.ec-cartNavi.is-active .ec-cartNavi__badge {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-cartNavi.is-active .ec-cartNavi__badge {
    display: none;
  }
}

/*
カートナビゲーションのポップアップ(商品詳細)

カートナビゲーションのポップアップを表示します。カートに追加された商品の詳細が表示されます。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:350px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='close')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    +b.ec-cartNaviIsset
      +e.cart
        +e.cartImage
          img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
        +e.cartContent
          +e.cartContentTitle ミニテーブル
          +e.cartContentPrice ¥ 12,960
            +e.cartContentTax 税込
          +e.cartContentNumber 数量：1
      +e.action
        a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
        a.ec-blockBtn.ec-cartNavi--cancel キャンセル

Styleguide 7.3.6
*/
.ec-cartNaviIsset {
  display: none;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 16px;
  z-index: 20;
  position: absolute;
  right: 0;
}
@media only screen and (min-width: 768px) {
  .ec-cartNaviIsset {
    margin-top: 10px;
    min-width: 256px;
    max-width: 256px;
  }
  .ec-cartNaviIsset::before {
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8.5px 10px 8.5px;
    border-color: transparent transparent #f8f8f8 transparent;
    position: absolute;
    top: -9px;
  }
}
.ec-cartNaviIsset .ec-cartNaviIsset__cart {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 16px;
  padding-bottom: 32px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cart:after {
  content: " ";
  display: table;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cart:after {
  clear: both;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartImage {
  float: left;
  width: 45%;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartImage img {
  width: 100%;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContent {
  float: right;
  width: 55%;
  padding-left: 16px;
  text-align: left;
  box-sizing: border-box;
}
.ec-cartNaviIsset .ec-cartNaviIsset__action .ec-blockBtn--action {
  color: #fff;
  margin-bottom: 8px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentTitle {
  margin-bottom: 8px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice {
  font-weight: bold;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentTax {
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  margin-left: 2px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentNumber {
  font-size: 14px;
}

.ec-cartNaviIsset.is-active {
  display: block;
}

/*
カートナビゲーションのポップアップ(商品なし)

カートナビゲーションのポップアップを表示します。商品が登録されていない場合の表示です。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:170px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='cart')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    .ec-cartNaviNull
      .ec-cartNaviNull__message
        p 現在カート内に
          br
          | 商品がございません。
    //+b.ec-cartNaviIsset
    //  +e.cart
    //    +e.cartImage
    //      img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
    //    +e.cartContent
    //      +e.cartContentTitle ミニテーブル
    //      +e.cartContentPrice ¥ 12,960
    //        +e.cartContentTax 税込
    //      +e.cartContentNumber 数量：1
    //  +e.action
    //    a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
    //    a.ec-blockBtn キャンセル

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.7
*/
.ec-cartNaviNull {
  display: none;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 16px;
  z-index: 3;
  position: absolute;
  right: 0;
}
@media only screen and (min-width: 768px) {
  .ec-cartNaviNull {
    margin-top: 10px;
    min-width: 256px;
    max-width: 256px;
  }
  .ec-cartNaviNull::before {
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8.5px 10px 8.5px;
    border-color: transparent transparent #f8f8f8 transparent;
    position: absolute;
    top: -9px;
  }
}
.ec-cartNaviNull .ec-cartNaviNull__message {
  border: 1px solid #D9D9D9;
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #F99;
}
.ec-cartNaviNull .ec-cartNaviNull__message p {
  margin: 0;
}

.ec-cartNaviNull.is-active {
  display: block;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お知らせ

新着情報やバナーなどの掲載項目を紹介していきます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 8.1
*/
/*
新着情報

新着情報の掲載をします。

ex [トップページ　新着情報部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+ec-news

Styleguide 8.1.1
*/
.ec-news {
  margin-bottom: 16px;
  background: #F8F8F8;
}
@media only screen and (min-width: 768px) {
  .ec-news {
    margin-right: 3%;
  }
}
@media only screen and (min-width: 768px) {
  .ec-news {
    margin-bottom: 32px;
  }
}
.ec-news .ec-news__title {
  font-weight: bold;
  padding: 8px;
  font-size: 16px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-news .ec-news__title {
    padding: 16px;
    text-align: left;
    font-size: 24px;
  }
}
.ec-news .ec-news__items {
  padding: 0;
  list-style: none;
  border-top: 1px dotted #ccc;
}

/*
折りたたみ項目

折りたたみ項目を掲載します。

ex [トップページ　折りたたみ項目部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+b.ec-news
        +e.title 新着情報
        +e.UL.items
            +e.LI.item
                +b.ec-newsline.is_active
                    +e.info
                        +e.date 2016/09/29
                        +e.comment サイトオープンしました
                        +e.close
                            a.ec-closeBtn--circle
                                span.ec-closeBtn--circle__icon
                                    .ec-icon
                                        img(src='/moc/icon/angle-down-white.svg', alt='')
                    +e.description 一人暮らしからオフィスなどさまざまなシーンで あなたの生活をサポートするグッズをご家庭へお届けします！

Styleguide 8.1.2
*/
.ec-newsline {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0 16px;
}
.ec-newsline .ec-newsline__info {
  width: 100%;
  padding: 16px 0;
}
.ec-newsline .ec-newsline__info:after {
  content: " ";
  display: table;
}
.ec-newsline .ec-newsline__info:after {
  clear: both;
}
.ec-newsline .ec-newsline__date {
  display: inline-block;
  margin-right: 10px;
  float: left;
}
.ec-newsline .ec-newsline__comment {
  display: inline-block;
  float: left;
}
.ec-newsline .ec-newsline__close {
  float: right;
  display: inline-block;
  text-align: right;
}
.ec-newsline .ec-newsline__close .ec-closeBtn--circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
}
.ec-newsline .ec-newsline__description {
  width: 100%;
  height: 0;
  transition: all 0.2s ease-out;
}
.ec-newsline.is_active .ec-newsline__description {
  height: auto;
  transition: all 0.2s ease-out;
  padding-bottom: 16px;
}
.ec-newsline.is_active .ec-icon img {
  transform: rotateX(180deg);
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
マイページ

マイページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/
/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole .ec-navlistRole__navlist {
  display: flex;
  flex-wrap: wrap;
  border-color: #D0D0D0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  margin-bottom: 32px;
  padding: 0;
  list-style: none;
}
.ec-navlistRole .ec-navlistRole__navlist a {
  color: inherit;
  text-decoration: none;
}
.ec-navlistRole .ec-navlistRole__navlist a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-navlistRole .ec-navlistRole__navlist {
    flex-wrap: nowrap;
  }
}
.ec-navlistRole .ec-navlistRole__item {
  width: 50%;
  border-color: #D0D0D0;
  border-style: solid;
  border-width: 0 1px 1px 0;
  text-align: center;
  font-weight: bold;
}
.ec-navlistRole .ec-navlistRole__item a {
  padding: 16px;
  width: 100%;
  display: inline-block;
}
.ec-navlistRole .ec-navlistRole__item a:hover {
  background: #f5f7f8;
}
.ec-navlistRole .active a {
  color: #DE5D50;
}

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/
/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
  border-bottom: 1px dotted #ccc;
}
.ec-welcomeMsg:after {
  content: " ";
  display: table;
}
.ec-welcomeMsg:after {
  clear: both;
}
.ec-welcomeMsg textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-welcomeMsg img {
  max-width: 100%;
}
.ec-welcomeMsg html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-welcomeMsg *,
.ec-welcomeMsg *::before,
.ec-welcomeMsg *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-welcomeMsg img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-welcomeMsg {
    padding-left: 26px;
    padding-right: 26px;
  }
}

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.ec-favoriteRole .ec-favoriteRole__header {
  margin-bottom: 16px;
}
.ec-favoriteRole .ec-favoriteRole__itemList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}
.ec-favoriteRole .ec-favoriteRole__itemList a {
  color: inherit;
  text-decoration: none;
}
.ec-favoriteRole .ec-favoriteRole__itemList a:hover {
  text-decoration: none;
}
.ec-favoriteRole .ec-favoriteRole__item {
  margin-bottom: 8px;
  width: 47.5%;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
}
.ec-favoriteRole .ec-favoriteRole__item-image {
  height: 150px;
  margin-bottom: 10px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-favoriteRole .ec-favoriteRole__item-image {
    height: 250px;
  }
}
.ec-favoriteRole .ec-favoriteRole__item img {
  width: auto;
  max-height: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-favoriteRole .ec-favoriteRole__item {
    width: 25%;
  }
}
.ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle {
  position: absolute;
  right: 10px;
  top: 10px;
}
.ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle .ec-icon img {
  width: 1em;
  height: 1em;
}
.ec-favoriteRole .ec-favoriteRole__itemThumb {
  display: block;
  height: auto;
  margin-bottom: 8px;
}
.ec-favoriteRole .ec-favoriteRole__itemTitle {
  margin-bottom: 2px;
}
.ec-favoriteRole .ec-favoriteRole__itemPrice {
  font-weight: bold;
  margin-bottom: 0;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
標準セクション

通常のコンテナブロックです。

ex [商品詳細ページ　コンテナ](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-roleRole

Styleguide 11.1
*/
.ec-role {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-role:after {
  content: " ";
  display: table;
}
.ec-role:after {
  clear: both;
}
.ec-role textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-role img {
  max-width: 100%;
}
.ec-role html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-role *,
.ec-role *::before,
.ec-role *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-role img {
  width: 100%;
}

/*
マイページセクション

マイページ専用のコンテナブロックです。

ex [マイページ　コンテナ](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-mypageRole

Styleguide 11.1.2
*/
.ec-mypageRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
}
.ec-mypageRole:after {
  content: " ";
  display: table;
}
.ec-mypageRole:after {
  clear: both;
}
.ec-mypageRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-mypageRole img {
  max-width: 100%;
}
.ec-mypageRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-mypageRole *,
.ec-mypageRole *::before,
.ec-mypageRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-mypageRole img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-mypageRole {
    padding-left: 26px;
    padding-right: 26px;
  }
}
@media only screen and (min-width: 768px) {
  .ec-mypageRole .ec-pageHeader h1 {
    margin: 10px 0 48px;
    padding: 8px 0 18px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1;
}

/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [トップページ　ヘッダー](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.2.header.pug
include /assets/tmpl/elements/11.3.headerNavi.pug
include /assets/tmpl/elements/11.4.categoryNavi.pug
+b.ec-layoutRole
  +e.header
    +ec-headerRole
    +ec-headerNaviRole
    +ec-categoryNaviRole

Styleguide 11.2
*/
.ec-layoutRole {
  width: 100%;
  transition: transform 0.3s;
  background: #fff;
}
.ec-layoutRole .ec-layoutRole__contentTop {
  padding: 0;
}
.ec-layoutRole .ec-layoutRole__contents {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-wrap: nowrap;
}
.ec-layoutRole .ec-layoutRole__main {
  width: 100%;
}
.ec-layoutRole .ec-layoutRole__mainWithColumn {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-layoutRole .ec-layoutRole__mainWithColumn {
    width: 75%;
  }
}
.ec-layoutRole .ec-layoutRole__mainBetweenColumn {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-layoutRole .ec-layoutRole__mainBetweenColumn {
    width: 50%;
  }
}
.ec-layoutRole .ec-layoutRole__left, .ec-layoutRole .ec-layoutRole__right {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-layoutRole .ec-layoutRole__left, .ec-layoutRole .ec-layoutRole__right {
    display: block;
    width: 25%;
  }
}

.ec-headerRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  padding-top: 15px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
}
.ec-headerRole:after {
  content: " ";
  display: table;
}
.ec-headerRole:after {
  clear: both;
}
.ec-headerRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-headerRole img {
  max-width: 100%;
}
.ec-headerRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-headerRole *,
.ec-headerRole *::before,
.ec-headerRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-headerRole img {
  width: 100%;
}
.ec-headerRole:after {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-headerRole:after {
    content: " ";
    display: table;
  }
  .ec-headerRole:after {
    clear: both;
  }
}
.ec-headerRole::before {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-headerRole {
    width: 100%;
  }
  .ec-headerRole:after {
    content: " ";
    display: table;
  }
  .ec-headerRole:after {
    clear: both;
  }
}
.ec-headerRole .ec-headerRole__title {
  width: 100%;
}
.ec-headerRole .ec-headerRole__navSP {
  display: block;
  position: absolute;
  top: 15px;
  width: 27%;
  right: 0;
  text-align: right;
}
@media only screen and (min-width: 768px) {
  .ec-headerRole .ec-headerRole__navSP {
    display: none;
  }
}

.ec-headerNaviRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}
.ec-headerNaviRole:after {
  content: " ";
  display: table;
}
.ec-headerNaviRole:after {
  clear: both;
}
.ec-headerNaviRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-headerNaviRole img {
  max-width: 100%;
}
.ec-headerNaviRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-headerNaviRole *,
.ec-headerNaviRole *::before,
.ec-headerNaviRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-headerNaviRole img {
  width: 100%;
}
.ec-headerNaviRole .fa-bars {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 768px) {
  .ec-headerNaviRole {
    padding-bottom: 40px;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__left {
  width: calc(100% / 3);
}
.ec-headerNaviRole .ec-headerNaviRole__search {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-headerNaviRole .ec-headerNaviRole__search {
    display: inline-block;
    margin-top: 10px;
  }
  .ec-headerNaviRole .ec-headerNaviRole__search a {
    color: inherit;
    text-decoration: none;
  }
  .ec-headerNaviRole .ec-headerNaviRole__search a:hover {
    text-decoration: none;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__navSP {
  display: block;
}
@media only screen and (min-width: 768px) {
  .ec-headerNaviRole .ec-headerNaviRole__navSP {
    display: none;
  }
  .ec-headerNaviRole .ec-headerNaviRole__navSP a {
    color: inherit;
    text-decoration: none;
  }
  .ec-headerNaviRole .ec-headerNaviRole__navSP a:hover {
    text-decoration: none;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__right {
  width: calc(100% * 2 / 3);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ec-headerNaviRole .ec-headerNaviRole__nav {
  display: inline-block;
}
.ec-headerNaviRole .ec-headerNaviRole__nav a {
  color: inherit;
  text-decoration: none;
}
.ec-headerNaviRole .ec-headerNaviRole__nav a:hover {
  text-decoration: none;
}
.ec-headerNaviRole .ec-headerNaviRole__cart {
  display: inline-block;
}
.ec-headerNaviRole .ec-headerNaviRole__cart a {
  color: inherit;
  text-decoration: none;
}
.ec-headerNaviRole .ec-headerNaviRole__cart a:hover {
  text-decoration: none;
}

.ec-headerNavSP {
  display: block;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
}
.ec-headerNavSP .fas {
  vertical-align: top;
}
@media only screen and (min-width: 768px) {
  .ec-headerNavSP {
    display: none;
  }
}

.ec-headerNavSP.is-active {
  display: none;
}

/*
ヘッダー：タイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

Styleguide 11.2.1
*/
.ec-headerTitle {
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
}
.ec-headerTitle textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-headerTitle img {
  max-width: 100%;
}
.ec-headerTitle html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-headerTitle *,
.ec-headerTitle *::before,
.ec-headerTitle *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-headerTitle img {
  width: 100%;
}
.ec-headerTitle .ec-headerTitle__title {
  text-align: center;
}
.ec-headerTitle .ec-headerTitle__title h1 {
  margin: 0;
  padding: 0;
}
.ec-headerTitle .ec-headerTitle__title a {
  display: inline-block;
  margin-bottom: 30px;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-headerTitle .ec-headerTitle__title a {
    font-size: 40px;
  }
}
.ec-headerTitle .ec-headerTitle__title a:hover {
  opacity: 0.8;
}
.ec-headerTitle .ec-headerTitle__subtitle {
  font-size: 10px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-headerTitle .ec-headerTitle__subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.ec-headerTitle .ec-headerTitle__subtitle a {
  display: inline-block;
  color: #0092C4;
  text-decoration: none;
  cursor: pointer;
}

/*
ヘッダー：ユーザナビゲーション

ヘッダー内でユーザに関与するナビゲーションコンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__nav`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
.ec-headerNav {
  text-align: right;
}
.ec-headerNav .ec-headerNav__item {
  margin-left: 0;
  display: inline-block;
  font-size: 28px;
}
.ec-headerNav .ec-headerNav__itemIcon {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 18px;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-headerNav .ec-headerNav__itemIcon {
    margin-right: 0;
    font-size: 20px;
  }
}
.ec-headerNav .ec-headerNav__itemLink {
  display: none;
  margin-right: 5px;
  font-size: 14px;
  vertical-align: middle;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-headerNav .ec-headerNav__itemLink {
    display: inline-block;
  }
}

/*
ヘッダー：検索ボックス

ヘッダー内で使用される商品検索コンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__search`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerSearch

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.4
*/
.ec-headerSearch:after {
  content: " ";
  display: table;
}
.ec-headerSearch:after {
  clear: both;
}
.ec-headerSearch .ec-headerSearch__category {
  float: none;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__category {
    float: left;
    width: 43%;
  }
}
.ec-headerSearch .ec-headerSearch__category .ec-select {
  overflow: hidden;
  width: 100%;
  margin: 0;
  text-align: center;
}
.ec-headerSearch .ec-headerSearch__category .ec-select select {
  width: 100%;
  cursor: pointer;
  padding: 8px 24px 8px 8px;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  background: transparent;
  background-image: none;
  box-shadow: none;
  appearance: none;
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__category .ec-select select {
    max-width: 165px;
    height: 36px;
  }
}
.ec-headerSearch .ec-headerSearch__category .ec-select select option {
  color: #000;
}
.ec-headerSearch .ec-headerSearch__category .ec-select select::-ms-expand {
  display: none;
}
.ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
  position: relative;
  border: 0;
  background: #000;
  color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
    border-top-right-radius: inherit;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
}
.ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search::before {
  position: absolute;
  top: 0.8em;
  right: 0.4em;
  width: 0;
  height: 0;
  padding: 0;
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  pointer-events: none;
}
.ec-headerSearch .ec-headerSearch__keyword {
  position: relative;
  color: #525263;
  border: 1px solid #ccc;
  background-color: #f6f6f6;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__keyword {
    float: right;
    width: 57%;
    border-bottom-left-radius: inherit;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
}
.ec-headerSearch .ec-headerSearch__keyword input[type=search] {
  width: 100%;
  height: 34px;
  font-size: 16px;
  border: 0 none;
  padding: 0.5em 50px 0.5em 1em;
  box-shadow: none;
  background: none;
  box-sizing: border-box;
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__keyword input[type=search] {
    font-size: 12px;
  }
}
.ec-headerSearch .ec-headerSearch__keyword .ec-icon {
  width: 22px;
  height: 22px;
}
.ec-headerSearch .ec-headerSearch__keywordBtn {
  border: 0;
  background: none;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-55%);
  display: block;
  white-space: nowrap;
  z-index: 1;
}

/*
ヘッダー：カテゴリナビ

ヘッダー内で使用されている商品のカテゴリ一覧として使用します。
`li`の中に`ul > li`要素を入れることで、階層を深くする事ができます。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-categoryNaviRole" style="padding-bottom:150px;">
  <sg-wrapper-content/>
</div>

Styleguide 11.2.5
*/
.ec-categoryNaviRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  display: none;
}
.ec-categoryNaviRole:after {
  content: " ";
  display: table;
}
.ec-categoryNaviRole:after {
  clear: both;
}
.ec-categoryNaviRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-categoryNaviRole img {
  max-width: 100%;
}
.ec-categoryNaviRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-categoryNaviRole *,
.ec-categoryNaviRole *::before,
.ec-categoryNaviRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-categoryNaviRole img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-categoryNaviRole {
    display: block;
    width: 100%;
  }
  .ec-categoryNaviRole a {
    color: inherit;
    text-decoration: none;
  }
  .ec-categoryNaviRole a:hover {
    text-decoration: none;
  }
}

.ec-itemNav {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.ec-itemNav__nav {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: auto;
  height: auto;
  list-style-type: none;
  text-align: center;
  vertical-align: bottom;
}
@media only screen and (min-width: 768px) {
  .ec-itemNav__nav {
    display: inline-block;
  }
}

.ec-itemNav__nav li {
  float: none;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li {
    float: left;
    width: auto;
  }
}

.ec-itemNav__nav li a {
  display: block;
  border-bottom: 1px solid #E8E8E8;
  margin: 0;
  padding: 16px;
  height: auto;
  color: #2e3233;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-decoration: none;
  text-align: left;
  background: #fff;
  border-bottom: 1px solid #E8E8E8;
}
@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li a {
    text-align: center;
    border-bottom: none;
  }
}

.ec-itemNav__nav li ul {
  display: none;
  z-index: 0;
  margin: 0;
  padding: 0;
  min-width: 200px;
  list-style: none;
  position: static;
  top: 100%;
  left: 0;
}
@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul {
    display: block;
    z-index: 100;
    position: absolute;
  }
}

.ec-itemNav__nav li ul li {
  overflow: hidden;
  width: 100%;
  height: auto;
  transition: 0.3s;
}
@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul li {
    overflow: hidden;
    height: 0;
  }
}

.ec-itemNav__nav li ul li a {
  border-bottom: 1px solid #E8E8E8;
  padding: 16px 22px 16px 16px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: left;
  background: black;
}

.ec-itemNav__nav > li:hover > a {
  background: #fafafa;
}

.ec-itemNav__nav > li:hover li:hover > a {
  background: #333;
}

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav > li:hover > ul > li {
    overflow: visible;
    height: auto;
  }
}

.ec-itemNav__nav li ul li ul {
  top: 0;
  left: 100%;
  width: auto;
}

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul li ul:before {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: white;
    position: absolute;
    top: 19px;
    right: auto;
    left: -20px;
  }
}

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul li:hover > ul > li {
    overflow: visible;
    height: auto;
    width: auto;
  }
}

.ec-itemNav__nav li ul li ul li a {
  background: #7D7D7D;
}

.ec-itemNav__nav li:hover ul li ul li a:hover {
  background: #333;
}

/*
ヘッダー：SPヘッダー

SP時のみ出現するヘッダーに関係するコンポーネントです。<br>
ex [トップページ](http://demo3.ec-cube.net/)画面サイズが768px以下に該当。<br>
<br>
`.ec-drawerRole`：SPのドロワー内の要素をwrapするコンポーネントです。<br>
`.ec-headerSearch`、`.ec-headerNav`、`.ec-itemNav`は`.ec-drawerRole`の子要素にある場合、ドロワーに適したスタイルに変化します。<br><br>
`.ec-overlayRole`：SPのドロワー出現時にz-indexがドロワー以下の要素に半透明の黒背景をかぶせるコンポーネントです。<br>

Styleguide 11.2.6
*/
.ec-drawerRole {
  overflow-y: scroll;
  background: black;
  width: 260px;
  height: 100vh;
  transform: translateX(-300px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: z-index 0ms 1ms;
}
@media only screen and (min-width: 768px) {
  .ec-drawerRole {
    display: none;
  }
}
.ec-drawerRole .ec-headerSearchArea {
  padding: 20px 10px;
  width: 100%;
  background: #F8F8F8;
}
.ec-drawerRole .ec-headerSearch {
  padding: 16px 8px 26px;
  background: #EBEBEB;
  color: #636378;
}
.ec-drawerRole .ec-headerSearch select {
  width: 100% !important;
}
.ec-drawerRole .ec-headerCategoryArea .ec-headerCategoryArea__heading {
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  padding: 1em 10px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  background: #F8F8F8;
}
.ec-drawerRole .ec-headerCategoryArea p {
  margin-top: 0;
  margin-bottom: 0;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li a {
  border-bottom: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: black;
  font-weight: normal;
  background: #f8f8f8;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li a {
  border-bottom: 1px solid #ccc;
  padding-left: 20px;
  font-weight: normal;
  background: white;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav > li:hover > a {
  background: #f8f8f8;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav > li:hover li:hover > a {
  background: white;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li ul li a {
  padding-left: 40px;
  color: black;
  background: white;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li:hover ul li ul li a:hover {
  background: white;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li ul li ul li a {
  padding-left: 60px;
  font-weight: normal;
}
.ec-drawerRole .ec-headerLinkArea {
  background: black;
}
.ec-drawerRole .ec-headerLinkArea .ec-headerLink__list {
  border-top: 1px solid #ccc;
}
.ec-drawerRole .ec-headerLinkArea .ec-headerLink__item {
  display: block;
  border-bottom: 1px solid #ccc;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
}
.ec-drawerRole .ec-headerLinkArea .ec-headerLink__icon {
  display: inline-block;
  width: 28px;
  font-size: 17px;
}

.ec-drawerRoleClose {
  display: none;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  left: 270px;
  z-index: 1000;
}
.ec-drawerRoleClose .fas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 768px) {
  .ec-drawerRoleClose {
    display: none;
  }
}

.ec-drawerRole.is_active {
  display: block;
  transform: translateX(0);
  transition: all 0.3s;
  z-index: 100000;
}
@media only screen and (min-width: 768px) {
  .ec-drawerRole.is_active {
    display: none;
  }
}

.ec-drawerRoleClose.is_active {
  display: inline-block;
  transition: all 0.3s;
}
@media only screen and (min-width: 768px) {
  .ec-drawerRoleClose.is_active {
    display: none;
  }
}

.ec-overlayRole {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  transform: translateX(0);
  transition: all 0.3s;
  visibility: hidden;
}
@media only screen and (min-width: 768px) {
  .ec-overlayRole {
    display: none;
  }
}

.have_curtain .ec-overlayRole {
  display: block;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  visibility: visible;
}
@media only screen and (min-width: 768px) {
  .have_curtain .ec-overlayRole {
    display: none;
  }
}

/*
ヘッダー：test

test

Markup:
span.ec-itemAccordionParent test1
ul.ec-itemNavAccordion
  li.ec-itemNavAccordion__item
    a(href='') test2
    ul.ec-itemNavAccordion
      li.ec-itemNavAccordion__item
        a(href='') test3
        ul.ec-itemNavAccordion
          li.ec-itemNavAccordion__item
            a(href='') test4

Styleguide 11.2.7
*/
.ec-itemNavAccordion {
  display: none;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole {
  border-top: 1px solid #7d7d7d;
  margin-top: 30px;
  background: black;
}
@media only screen and (min-width: 768px) {
  .ec-footerRole {
    padding-top: 40px;
    margin-top: 100px;
  }
}
@media only screen and (min-width: 768px) {
  .ec-footerRole .ec-footerRole__inner {
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.4;
    color: #525263;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    max-width: 1130px;
  }
  .ec-footerRole .ec-footerRole__inner:after {
    content: " ";
    display: table;
  }
  .ec-footerRole .ec-footerRole__inner:after {
    clear: both;
  }
  .ec-footerRole .ec-footerRole__inner textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif;
  }
  .ec-footerRole .ec-footerRole__inner img {
    max-width: 100%;
  }
  .ec-footerRole .ec-footerRole__inner html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .ec-footerRole .ec-footerRole__inner *,
.ec-footerRole .ec-footerRole__inner *::before,
.ec-footerRole .ec-footerRole__inner *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
  .ec-footerRole .ec-footerRole__inner img {
    width: 100%;
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi {
  padding: 0;
  color: white;
  list-style: none;
  text-align: center;
}
.ec-footerNavi .ec-footerNavi__link {
  display: block;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi .ec-footerNavi__link {
    display: inline-block;
  }
}
.ec-footerNavi .ec-footerNavi__link a {
  display: block;
  border-bottom: 1px solid #7d7d7d;
  padding: 15px 0;
  font-size: 14px;
  color: inherit;
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi .ec-footerNavi__link a {
    display: inline-block;
    border-bottom: none;
    margin: 0 10px;
    padding: 0;
    text-decoration: underline;
  }
}
.ec-footerNavi .ec-footerNavi__link:hover a {
  opacity: 0.8;
  text-decoration: none;
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle {
  padding: 40px 0 60px;
  text-align: center;
  color: white;
}
@media only screen and (min-width: 768px) {
  .ec-footerTitle {
    padding: 50px 0 80px;
  }
}
.ec-footerTitle .ec-footerTitle__logo {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.ec-footerTitle .ec-footerTitle__logo a {
  color: inherit;
  text-decoration: none;
}
.ec-footerTitle .ec-footerTitle__logo a:hover {
  text-decoration: none;
}
.ec-footerTitle .ec-footerTitle__logo a {
  font-size: 22px;
  color: inherit;
}
@media only screen and (min-width: 768px) {
  .ec-footerTitle .ec-footerTitle__logo a {
    font-size: 24px;
  }
}
.ec-footerTitle .ec-footerTitle__logo:hover a {
  opacity: 0.8;
  text-decoration: none;
}
.ec-footerTitle .ec-footerTitle__copyright {
  font-size: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-footerTitle .ec-footerTitle__copyright {
    font-size: 12px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
トップページ

トップページ スライド部に関する Project コンポーネントを定義します。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.1.slider.pug
+ec-sliderRole

Styleguide 12.1
*/
.ec-sliderRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  margin-bottom: 24px;
}
.ec-sliderRole:after {
  content: " ";
  display: table;
}
.ec-sliderRole:after {
  clear: both;
}
.ec-sliderRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-sliderRole img {
  max-width: 100%;
}
.ec-sliderRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-sliderRole *,
.ec-sliderRole *::before,
.ec-sliderRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-sliderRole img {
  width: 100%;
}
.ec-sliderRole ul {
  padding: 0;
  list-style: none;
}

.ec-sliderItemRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  margin-bottom: 24px;
}
.ec-sliderItemRole:after {
  content: " ";
  display: table;
}
.ec-sliderItemRole:after {
  clear: both;
}
.ec-sliderItemRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-sliderItemRole img {
  max-width: 100%;
}
.ec-sliderItemRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-sliderItemRole *,
.ec-sliderItemRole *::before,
.ec-sliderItemRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-sliderItemRole img {
  width: 100%;
}
.ec-sliderItemRole ul {
  padding: 0;
  list-style: none;
}
.ec-sliderItemRole .item_nav {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-sliderItemRole .item_nav {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}
.ec-sliderItemRole .slideThumb {
  margin-bottom: 25px;
  width: 33%;
  opacity: 0.8;
  cursor: pointer;
}
.ec-sliderItemRole .slideThumb:focus {
  outline: none;
}
.ec-sliderItemRole .slideThumb:hover {
  opacity: 1;
}
.ec-sliderItemRole .slideThumb img {
  width: 80%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
アイキャッチ

トップページ アイキャッチ部に関する Project コンポーネントを定義します。

ex [トップページスライダー直下 アイキャッチ部](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.2.eyecatch.pug
+ec-eyecatchRole

Styleguide 12.2
*/
.ec-eyecatchRole {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  /* Deprecated https://github.com/EC-CUBE/ec-cube/pull/4220 */
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole {
    flex-wrap: nowrap;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__image {
  display: block;
  margin-bottom: 40px;
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__image {
    order: 2;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__intro {
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__intro {
    padding-right: 5%;
    order: 1;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__introEnTitle {
  margin-bottom: 0.8em;
  font-size: 16px;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__introEnTitle {
    margin-top: 45px;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__introTitle {
  margin-bottom: 0.8em;
  font-size: 24px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__introTitle {
    margin-bottom: 1em;
    font-size: 26px;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__introDescriptiron {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 2;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__introDescriptiron {
    margin-bottom: 30px;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__introDescription {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 2;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__introDescription {
    margin-bottom: 30px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
ボタン

トップページで使用されているボタンのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.3
*/
/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

Markup:
.ec-inlineBtn--top more

Styleguide 12.3.1
*/
.ec-inlineBtn--top {
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  border: 0;
  background-color: black;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
}
.ec-inlineBtn--top:hover, .ec-inlineBtn--top:focus {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.ec-inlineBtn--top.disabled, .ec-inlineBtn--top[disabled], fieldset[disabled] .ec-inlineBtn--top {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*
ロングボタン（全幅）

ロングタイプのボタンです。

Markup:
.ec-blockBtn--top 商品一覧へ

Styleguide 2.1.2
*/
.ec-blockBtn--top {
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  border: 0;
  background-color: black;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  text-decoration: none;
  line-height: 1.4;
  user-select: none;
  display: block;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
}
.ec-blockBtn--top:hover, .ec-blockBtn--top:focus {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.ec-blockBtn--top.disabled, .ec-blockBtn--top[disabled], fieldset[disabled] .ec-blockBtn--top {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media only screen and (min-width: 768px) {
  .ec-blockBtn--top {
    max-width: 260px;
  }
}

/*
見出し

トップページで使用されている見出しのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.4
*/
/*
横並び見出し

横並びの見出しです。

Markup:
.ec-secHeading
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.1
*/
.ec-secHeading {
  margin-bottom: 15px;
  color: black;
}
.ec-secHeading .ec-secHeading__en {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.2em;
}
.ec-secHeading .ec-secHeading__line {
  display: inline-block;
  margin: 0 20px;
  width: 1px;
  height: 14px;
  background: black;
}
.ec-secHeading .ec-secHeading__ja {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.15em;
  vertical-align: 2px;
}

/*
縦並び見出し

縦並びの見出しです。

Markup:
.ec-secHeading--tandem
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.2
*/
.ec-secHeading--tandem {
  margin-bottom: 15px;
  color: black;
  text-align: center;
}
.ec-secHeading--tandem .ec-secHeading__en {
  display: block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.2em;
}
.ec-secHeading--tandem .ec-secHeading__line {
  display: block;
  margin: 13px auto;
  width: 20px;
  height: 1px;
  background: black;
}
.ec-secHeading--tandem .ec-secHeading__ja {
  display: block;
  margin-bottom: 30px;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.15em;
  vertical-align: 2px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
トピック（アイテム2列）

トップページで使用されているトピックのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.5.1
*/
.ec-topicRole {
  padding: 40px 0;
  background: #F8F8F8;
}
@media only screen and (min-width: 768px) {
  .ec-topicRole {
    padding: 60px 0;
  }
}
.ec-topicRole .ec-topicRole__list {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .ec-topicRole .ec-topicRole__list {
    flex-wrap: nowrap;
  }
}
.ec-topicRole .ec-topicRole__listItem {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 768px) {
  .ec-topicRole .ec-topicRole__listItem {
    width: calc(100% / 2);
  }
  .ec-topicRole .ec-topicRole__listItem:not(:last-of-type) {
    margin-right: 30px;
  }
}
.ec-topicRole .ec-topicRole__listItemTitle {
  margin-top: 0.5em;
  font-size: 14px;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-topicRole .ec-topicRole__listItemTitle {
    margin-top: 1em;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カテゴリ（アイテム4列 スマホの時は2列）

トップページで使用されているアイテムリストのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.6.1
*/
.ec-newItemRole {
  padding: 40px 0;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole {
    padding: 60px 0;
  }
}
.ec-newItemRole .ec-newItemRole__list {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole .ec-newItemRole__list {
    flex-wrap: nowrap;
  }
}
.ec-newItemRole .ec-newItemRole__listItem {
  margin-bottom: 4%;
  width: 48%;
  height: auto;
}
.ec-newItemRole .ec-newItemRole__listItem:not(:first-child) a {
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole .ec-newItemRole__listItem {
    margin-bottom: 15px;
    width: calc(100% / 4);
  }
  .ec-newItemRole .ec-newItemRole__listItem:not(:last-of-type) {
    margin-right: 30px;
  }
}
.ec-newItemRole .ec-newItemRole__listItem:nth-child(odd) {
  margin-right: 4%;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole .ec-newItemRole__listItem:nth-child(odd) {
    margin-right: 30px;
  }
}
.ec-newItemRole .ec-newItemRole__listItemHeading {
  margin-top: calc(45% - 20px);
}
.ec-newItemRole .ec-newItemRole__listItemTitle {
  margin: 8px 0;
  font-size: 14px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole .ec-newItemRole__listItemTitle {
    margin: 20px 0 10px;
  }
}
.ec-newItemRole .ec-newItemRole__listItemPrice {
  font-size: 12px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カテゴリ（アイテム3列）

トップページで使用されているカテゴリのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.7.1
*/
.ec-categoryRole {
  padding: 40px 0;
  color: black;
  background: #F8F8F8;
}
@media only screen and (min-width: 768px) {
  .ec-categoryRole {
    padding: 60px 0;
  }
}
.ec-categoryRole .ec-categoryRole__list {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .ec-categoryRole .ec-categoryRole__list {
    flex-wrap: nowrap;
  }
}
.ec-categoryRole .ec-categoryRole__listItem {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 768px) {
  .ec-categoryRole .ec-categoryRole__listItem {
    width: calc(100% / 3);
  }
  .ec-categoryRole .ec-categoryRole__listItem:not(:last-of-type) {
    margin-right: 30px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

トップページで使用されている新着情報のスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.8.1
*/
.ec-newsRole {
  padding: 40px 0 0;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole {
    padding: 60px 0 0;
  }
}
.ec-newsRole .ec-newsRole__news {
  box-sizing: border-box;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__news {
    border: 16px solid #F8F8F8;
    padding: 20px 30px;
  }
}
.ec-newsRole .ec-newsRole__newsItem {
  width: 100%;
}
.ec-newsRole .ec-newsRole__newsItem:not(:last-of-type) {
  border-bottom: 1px solid #ccc;
}
.ec-newsRole .ec-newsRole__newsItem:last-of-type {
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__newsItem:last-of-type {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__newsItem {
    padding: 20px 0;
  }
}
.ec-newsRole .ec-newsRole__newsHeading {
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__newsHeading {
    display: flex;
  }
}
.ec-newsRole .ec-newsRole__newsDate {
  display: block;
  margin: 15px 0 5px;
  font-size: 12px;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__newsDate {
    display: inline-block;
    margin: 0;
    min-width: 120px;
    font-size: 14px;
  }
}
.ec-newsRole .ec-newsRole__newsColumn {
  display: flex;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__newsColumn {
    display: inline-flex;
    min-width: calc(100% - 120px);
  }
}
.ec-newsRole .ec-newsRole__newsTitle {
  display: inline-block;
  margin-bottom: 10px;
  width: 90%;
  font-size: 14px;
  font-weight: bold;
  color: #7D7D7D;
  line-height: 1.6;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__newsTitle {
    margin-bottom: 0;
    line-height: 1.8;
  }
}
.ec-newsRole .ec-newsRole__newsClose {
  display: inline-block;
  width: 10%;
  position: relative;
}
.ec-newsRole .ec-newsRole__newsCloseBtn {
  display: inline-block;
  margin-left: auto;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: white;
  text-align: center;
  background: black;
  cursor: pointer;
  position: absolute;
  right: 5px;
}
.ec-newsRole .ec-newsRole__newsDescription {
  display: none;
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 1.4;
  overflow: hidden;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__newsDescription {
    margin: 20px 0 0;
    line-height: 1.8;
  }
}
.ec-newsRole .ec-newsRole__newsDescription a {
  color: #0092C4;
}
.ec-newsRole__newsItem.is_active .ec-newsRole__newsDescription {
  margin: 0 0 10px;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole__newsItem.is_active .ec-newsRole__newsDescription {
    margin: 20px 0 0;
  }
}
.ec-newsRole__newsItem.is_active .ec-newsRole__newsCloseBtn i {
  display: inline-block;
  transform: rotateX(180deg) translateY(2px);
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
検索ラベル

商品一覧 ヘッダー部 に関する Project コンポーネントを定義します。

ex [商品一覧 ヘッダー部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.1.searchnav.pug
+ec-searchnavRole__topicpath
+ec-searchnavRole__info

Styleguide 13.1

*/
.ec-searchnavRole {
  margin-bottom: 0;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole {
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.4;
    color: #525263;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    max-width: 1130px;
  }
  .ec-searchnavRole:after {
    content: " ";
    display: table;
  }
  .ec-searchnavRole:after {
    clear: both;
  }
  .ec-searchnavRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif;
  }
  .ec-searchnavRole img {
    max-width: 100%;
  }
  .ec-searchnavRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .ec-searchnavRole *,
.ec-searchnavRole *::before,
.ec-searchnavRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
  .ec-searchnavRole img {
    width: 100%;
  }
}
.ec-searchnavRole .ec-searchnavRole__infos {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  display: flex;
  border-top: 0;
  margin-bottom: 16px;
  padding-top: 5px;
  flex-direction: column;
}
.ec-searchnavRole .ec-searchnavRole__infos:after {
  content: " ";
  display: table;
}
.ec-searchnavRole .ec-searchnavRole__infos:after {
  clear: both;
}
.ec-searchnavRole .ec-searchnavRole__infos textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-searchnavRole .ec-searchnavRole__infos img {
  max-width: 100%;
}
.ec-searchnavRole .ec-searchnavRole__infos html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-searchnavRole .ec-searchnavRole__infos *,
.ec-searchnavRole .ec-searchnavRole__infos *::before,
.ec-searchnavRole .ec-searchnavRole__infos *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-searchnavRole .ec-searchnavRole__infos img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole .ec-searchnavRole__infos {
    padding-left: 0;
    padding-right: 0;
    border-top: 1px solid #ccc;
    padding-top: 16px;
    flex-direction: row;
  }
}
.ec-searchnavRole .ec-searchnavRole__counter {
  margin-bottom: 16px;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole .ec-searchnavRole__counter {
    margin-bottom: 0;
    width: 50%;
  }
}
.ec-searchnavRole .ec-searchnavRole__actions {
  text-align: right;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole .ec-searchnavRole__actions {
    width: 50%;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2

*/
.ec-shelfRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-shelfRole:after {
  content: " ";
  display: table;
}
.ec-shelfRole:after {
  clear: both;
}
.ec-shelfRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-shelfRole img {
  max-width: 100%;
}
.ec-shelfRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-shelfRole *,
.ec-shelfRole *::before,
.ec-shelfRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-shelfRole img {
  width: 100%;
}

/*
商品一覧グリッド

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2.1

*/
.ec-shelfGrid {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}
.ec-shelfGrid a {
  color: inherit;
  text-decoration: none;
}
.ec-shelfGrid a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGrid {
    margin-left: -16px;
    margin-right: -16px;
  }
}
.ec-shelfGrid .ec-shelfGrid__item {
  margin-bottom: 36px;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.ec-shelfGrid .ec-shelfGrid__item-image {
  height: 150px;
  margin-bottom: 10px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGrid .ec-shelfGrid__item-image {
    height: 250px;
  }
}
.ec-shelfGrid .ec-shelfGrid__item img {
  width: auto;
  max-height: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGrid .ec-shelfGrid__item {
    padding: 0 16px;
    width: 25%;
  }
}
.ec-shelfGrid .ec-shelfGrid__item .ec-productRole__btn {
  margin-top: auto;
  margin-bottom: 15px;
}
.ec-shelfGrid .ec-shelfGrid__item:nth-child(odd) {
  padding-right: 8px;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGrid .ec-shelfGrid__item:nth-child(odd) {
    padding: 0 16px;
  }
}
.ec-shelfGrid .ec-shelfGrid__item:nth-child(even) {
  padding-left: 8px;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGrid .ec-shelfGrid__item:nth-child(even) {
    padding: 0 16px;
  }
}
.ec-shelfGrid .ec-shelfGrid__title {
  margin-bottom: 7px;
}
.ec-shelfGrid .ec-shelfGrid__plice {
  font-weight: bold;
}

/*
13.2.2 商品一覧グリッド（中央寄せ）

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。
商品のあまりはセンタリングされ、中央に表示されます。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGridCenter

Styleguide 13.2.2

*/
.ec-shelfGridCenter {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: center;
}
.ec-shelfGridCenter a {
  color: inherit;
  text-decoration: none;
}
.ec-shelfGridCenter a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGridCenter {
    margin-left: -16px;
    margin-right: -16px;
  }
}
.ec-shelfGridCenter .ec-shelfGridCenter__item {
  margin-bottom: 36px;
  width: 50%;
}
.ec-shelfGridCenter .ec-shelfGridCenter__item-image {
  height: 150px;
  margin-bottom: 10px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGridCenter .ec-shelfGridCenter__item-image {
    height: 250px;
  }
}
.ec-shelfGridCenter .ec-shelfGridCenter__item img {
  width: auto;
  max-height: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGridCenter .ec-shelfGridCenter__item {
    padding: 0 16px;
    width: 25%;
  }
}
.ec-shelfGridCenter .ec-shelfGridCenter__item .ec-productRole__btn {
  margin-top: auto;
  padding-top: 1em;
}
.ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(odd) {
  padding-right: 8px;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(odd) {
    padding: 0 16px;
  }
}
.ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(even) {
  padding-left: 8px;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(even) {
    padding: 0 16px;
  }
}
.ec-shelfGridCenter .ec-shelfGridCenter__title {
  margin-bottom: 7px;
}
.ec-shelfGridCenter .ec-shelfGridCenter__plice {
  font-weight: bold;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-productRole:after {
  content: " ";
  display: table;
}
.ec-productRole:after {
  clear: both;
}
.ec-productRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-productRole img {
  max-width: 100%;
}
.ec-productRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-productRole *,
.ec-productRole *::before,
.ec-productRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-productRole img {
  width: 100%;
}
.ec-productRole .ec-productRole__img {
  margin-right: 0;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__img {
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.ec-productRole .ec-productRole__profile {
  margin-left: 0;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__profile {
    margin-left: 16px;
  }
}
.ec-productRole .ec-productRole__title .ec-headingTitle {
  font-size: 20px;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__title .ec-headingTitle {
    font-size: 32px;
  }
}
.ec-productRole .ec-productRole__tags {
  margin-top: 16px;
  padding: 0;
  padding-bottom: 16px;
  border-bottom: 1px dotted #ccc;
}
.ec-productRole .ec-productRole__tag {
  display: inline-block;
  padding: 2px 5px;
  list-style: none;
  font-size: 80%;
  color: #525263;
  border: solid 1px #D7DADD;
  border-radius: 3px;
  background-color: #F5F7F8;
}
.ec-productRole .ec-productRole__priceRegular {
  padding-top: 14px;
}
.ec-productRole .ec-productRole__priceRegularTax {
  margin-left: 5px;
  font-size: 10px;
}
.ec-productRole .ec-productRole__price {
  color: #DE5D50;
  font-size: 28px;
  padding: 0;
  border-bottom: 0;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__price {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc;
  }
}
.ec-productRole .ec-productRole__code {
  padding: 14px 0;
  border-bottom: 1px dotted #ccc;
}
.ec-productRole .ec-productRole__category {
  padding: 14px 0;
  border-bottom: 1px dotted #ccc;
}
.ec-productRole .ec-productRole__category a {
  color: #33A8D0;
}
.ec-productRole .ec-productRole__category ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ec-productRole .ec-productRole__actions {
  padding: 14px 0;
}
.ec-productRole .ec-productRole__actions .ec-select select {
  height: 40px;
  max-width: 100%;
  min-width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__actions .ec-select select {
    min-width: 350px;
    max-width: 350px;
  }
}
.ec-productRole .ec-productRole__btn {
  width: 100%;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__btn {
    width: 60%;
    margin-bottom: 16px;
    min-width: 350px;
  }
}
.ec-productRole .ec-productRole__description {
  margin-bottom: 16px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
注文履歴一覧

マイページ 注文履歴部に関する Project コンポーネントを定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole

Styleguide 16.1
*/
.ec-historyRole .ec-historyRole__contents {
  padding-top: 1em;
  padding-bottom: 16px;
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  color: #525263;
}
@media only screen and (min-width: 768px) {
  .ec-historyRole .ec-historyRole__contents {
    flex-direction: row;
  }
}
.ec-historyRole .ec-historyRole__header {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-historyRole .ec-historyRole__header {
    width: 33.3333%;
  }
}
.ec-historyRole .ec-historyRole__detail {
  border-top: 1px dotted #ccc;
  width: 100%;
}
.ec-historyRole .ec-historyRole__detail .ec-imageGrid:nth-of-type(1) {
  border-top: none;
}
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailTitle {
  margin-bottom: 8px;
  font-size: 1.6rem;
  font-weight: bold;
}
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailPrice {
  margin-bottom: 8px;
  font-size: 1.6rem;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-historyRole .ec-historyRole__detail {
    width: 66.6666%;
    border-top: none;
  }
}

/*
注文履歴一覧 規格

マイページ 注文履歴内アイテムの規格を定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole-option

Styleguide 16.1.1
*/
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailOption {
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 0.5rem;
  font-size: 1.6rem;
}
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailOption::after {
  display: inline-block;
  padding-left: 0.5rem;
  content: "/";
  font-weight: bold;
}

/*
注文履歴一覧ヘッダ

注文履歴一覧で使用するヘッダのコンポーネントを定義します。

ex [マイページ　注文履歴一覧ヘッダ](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyHeader
p hofe

Styleguide 16.1.2
*/
.ec-historyListHeader .ec-historyListHeader__date {
  font-weight: bold;
  font-size: 16px;
}
@media only screen and (min-width: 768px) {
  .ec-historyListHeader .ec-historyListHeader__date {
    font-weight: bold;
    font-size: 20px;
  }
}
.ec-historyListHeader .ec-historyListHeader__action {
  margin: 16px 0;
}
.ec-historyListHeader .ec-historyListHeader__action a {
  font-size: 12px;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-historyListHeader .ec-historyListHeader__action a {
    font-size: 14px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
パスワードリセット

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [パスワードリセット画面](http://demo3.ec-cube.net/forgot)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/18.1.password.pug
+ec-passwordRole

Styleguide 18.1

*/
.ec-forgotRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-forgotRole:after {
  content: " ";
  display: table;
}
.ec-forgotRole:after {
  clear: both;
}
.ec-forgotRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-forgotRole img {
  max-width: 100%;
}
.ec-forgotRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-forgotRole *,
.ec-forgotRole *::before,
.ec-forgotRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-forgotRole img {
  width: 100%;
}
.ec-forgotRole .ec-forgotRole__intro {
  font-size: 16px;
}
.ec-forgotRole .ec-forgotRole__form {
  margin-bottom: 16px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
会員登録

新規会員登録 に関する Project コンポーネントを定義します。

ex [新規会員登録画面　会員登録](http://demo3.ec-cube.net/entry)

Markup:
include /assets/tmpl/elements/19.1.register.pug
+ec-registerRole

Styleguide 19.1

*/
.ec-registerRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-registerRole:after {
  content: " ";
  display: table;
}
.ec-registerRole:after {
  clear: both;
}
.ec-registerRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-registerRole img {
  max-width: 100%;
}
.ec-registerRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-registerRole *,
.ec-registerRole *::before,
.ec-registerRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-registerRole img {
  width: 100%;
}
.ec-registerRole .ec-registerRole__actions {
  padding-top: 20px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-registerRole .ec-registerRole__actions {
    text-align: left;
  }
}
.ec-registerRole .ec-registerRole__actions p {
  margin-bottom: 16px;
}
.ec-registerRole .ec-blockBtn--action {
  margin-bottom: 16px;
}

.ec-registerCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-registerCompleteRole:after {
  content: " ";
  display: table;
}
.ec-registerCompleteRole:after {
  clear: both;
}
.ec-registerCompleteRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-registerCompleteRole img {
  max-width: 100%;
}
.ec-registerCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-registerCompleteRole *,
.ec-registerCompleteRole *::before,
.ec-registerCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-registerCompleteRole img {
  width: 100%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。

ex [お問い合わせ](http://demo3.ec-cube.net/contact)

Markup:
include /assets/tmpl/elements/19.2.contact.pug
+ec-contactRole

Styleguide 19.2

*/
.ec-contactRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-contactRole:after {
  content: " ";
  display: table;
}
.ec-contactRole:after {
  clear: both;
}
.ec-contactRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-contactRole img {
  max-width: 100%;
}
.ec-contactRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-contactRole *,
.ec-contactRole *::before,
.ec-contactRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-contactRole img {
  width: 100%;
}
.ec-contactRole .ec-contactRole__actions {
  padding-top: 20px;
}
.ec-contactRole p {
  margin: 16px 0;
}

.ec-contactConfirmRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-contactConfirmRole:after {
  content: " ";
  display: table;
}
.ec-contactConfirmRole:after {
  clear: both;
}
.ec-contactConfirmRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-contactConfirmRole img {
  max-width: 100%;
}
.ec-contactConfirmRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-contactConfirmRole *,
.ec-contactConfirmRole *::before,
.ec-contactConfirmRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-contactConfirmRole img {
  width: 100%;
}
.ec-contactConfirmRole .ec-contactConfirmRole__actions {
  padding-top: 20px;
}
.ec-contactConfirmRole .ec-blockBtn--action {
  margin-bottom: 16px;
}

.ec-contactCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-contactCompleteRole:after {
  content: " ";
  display: table;
}
.ec-contactCompleteRole:after {
  clear: both;
}
.ec-contactCompleteRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-contactCompleteRole img {
  max-width: 100%;
}
.ec-contactCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-contactCompleteRole *,
.ec-contactCompleteRole *::before,
.ec-contactCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-contactCompleteRole img {
  width: 100%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お客様情報の入力

ログインせずゲストとして商品を購入する際の、お客様情報の入力 に関する Project コンポーネントを定義します。

ex [カートSTEP2 お客様情報の入力(ゲスト購入)](http://demo3.ec-cube.net/shopping/nonmember)

Markup:
include /assets/tmpl/elements/19.3.customer.pug
+ec-customerRole
hoge

Styleguide 19.3

*/
.ec-customerRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-customerRole:after {
  content: " ";
  display: table;
}
.ec-customerRole:after {
  clear: both;
}
.ec-customerRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-customerRole img {
  max-width: 100%;
}
.ec-customerRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-customerRole *,
.ec-customerRole *::before,
.ec-customerRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-customerRole img {
  width: 100%;
}
.ec-customerRole .ec-customerRole__actions {
  padding-top: 20px;
}
.ec-customerRole .ec-blockBtn--action {
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-customerRole .ec-blockBtn--action {
    margin-bottom: 16px;
  }
}

.ec-contactConfirmRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-contactConfirmRole:after {
  content: " ";
  display: table;
}
.ec-contactConfirmRole:after {
  clear: both;
}
.ec-contactConfirmRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-contactConfirmRole img {
  max-width: 100%;
}
.ec-contactConfirmRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-contactConfirmRole *,
.ec-contactConfirmRole *::before,
.ec-contactConfirmRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-contactConfirmRole img {
  width: 100%;
}
.ec-contactConfirmRole .ec-contactConfirmRole__actions {
  padding-top: 20px;
}
.ec-contactConfirmRole .ec-blockBtn--action {
  margin-bottom: 16px;
}

.ec-contactCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-contactCompleteRole:after {
  content: " ";
  display: table;
}
.ec-contactCompleteRole:after {
  clear: both;
}
.ec-contactCompleteRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-contactCompleteRole img {
  max-width: 100%;
}
.ec-contactCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-contactCompleteRole *,
.ec-contactCompleteRole *::before,
.ec-contactCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-contactCompleteRole img {
  width: 100%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
退会手続き

退会手続きで使用するページコンポーネントです。

ex [退会手続き](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawRole

Styleguide 21.1

*/
.ec-withdrawRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  text-align: center;
  padding: 0 16px;
}
.ec-withdrawRole:after {
  content: " ";
  display: table;
}
.ec-withdrawRole:after {
  clear: both;
}
.ec-withdrawRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-withdrawRole img {
  max-width: 100%;
}
.ec-withdrawRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-withdrawRole *,
.ec-withdrawRole *::before,
.ec-withdrawRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-withdrawRole img {
  width: 100%;
}
.ec-withdrawRole .ec-withdrawRole__title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
}
.ec-withdrawRole .ec-withdrawRole__description {
  margin-bottom: 32px;
  font-size: 16px;
}
.ec-withdrawRole .ec-icon img {
  width: 100px;
  height: 100px;
}

/*
退会手続き実行確認

退会手続き実行確認で使用するページコンポーネントです。

ex [退会手続き　退会手続きへボタン→押下](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawConfirm

Styleguide 21.1.2

*/
.ec-withdrawConfirmRole .ec-withdrawConfirmRole__cancel {
  margin-bottom: 20px;
}
.ec-withdrawConfirmRole .ec-withdrawConfirmRole__title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
}
.ec-withdrawConfirmRole .ec-withdrawConfirmRole__description {
  margin-bottom: 32px;
  font-size: 16px;
}
.ec-withdrawConfirmRole .ec-icon img {
  width: 100px;
  height: 100px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
会員情報編集完了

会員情報編集完了で使用するページコンポーネントです。

ex [会員情報編集完了](http://demo3.ec-cube.net/mypage/change_complete)

Markup:
include /assets/tmpl/elements/22.1.editComplete.pug
+ec-userEditCompleteRole

Styleguide 22.1

*/
.ec-userEditCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  text-align: center;
  padding: 0 16px;
}
.ec-userEditCompleteRole:after {
  content: " ";
  display: table;
}
.ec-userEditCompleteRole:after {
  clear: both;
}
.ec-userEditCompleteRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-userEditCompleteRole img {
  max-width: 100%;
}
.ec-userEditCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-userEditCompleteRole *,
.ec-userEditCompleteRole *::before,
.ec-userEditCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-userEditCompleteRole img {
  width: 100%;
}
.ec-userEditCompleteRole .ec-userEditCompleteRole__title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
}
@media only screen and (min-width: 768px) {
  .ec-userEditCompleteRole .ec-userEditCompleteRole__title {
    font-size: 32px;
  }
}
.ec-userEditCompleteRole .ec-userEditCompleteRole__description {
  margin-bottom: 32px;
  font-size: 16px;
}