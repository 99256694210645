@mixin focus() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin within() {
  &:hover,
  &:focus-within {
    @content;
  }
}
