// @use "sass:math";

.p-sidemenu {
  @at-root {
    & {
      border: 1px solid $color--gray;
      border-bottom: 0;
    }
    .p-sidemenu__item {
      border-bottom: 1px solid $color--gray;

      a {
        display: block;
        padding: 15px 36px;
        color: inherit;
        font-size: rem(14);
        text-decoration: none;
        line-height: 1.4;

        @include focus() {
          background-color: $color--white-dark;
        }

      }
    }
    .p-sidemenu__icon {
      $w: 30;

      display: inline-block;
      width: px($w);
      margin-right: 18px;
      text-align: center;

      img {
        vertical-align: middle;
      }
      &.-paper-products {
        $icon_w: 25;
        // $pl: math.div($w - $icon_w, 2);
        $pl: ($w - $icon_w) / 2;
        padding: 0 px($pl);
        // &::befeore {}
      }
      &.-film-products {
        $icon_w: 25;
        // $pl: math.div($w - $icon_w, 2);
        $pl: ($w - $icon_w) / 2;
        padding: 0 px($pl);
        // &::befeore {}
      }
      &.-packing-materials {
        $icon_w: 24;
        // $pl: math.div($w - $icon_w, 2);
        $pl: ($w - $icon_w) / 2;
        padding: 0 px($pl);
        // &::befeore {}
      }
      &.-paper {
        $icon_w: 29;
        // $pl: math.div($w - $icon_w, 2);
        $pl: ($w - $icon_w) / 2;
        padding: 0 px($pl);
        // &::befeore {}
      }
      &.-eco {
        $icon_w: 26;
        // $pl: math.div($w - $icon_w, 2);
        $pl: ($w - $icon_w) / 2;
        padding: 0 px($pl);
      }
      &.-measures {
        $icon_w: 20;
        // $pl: math.div($w - $icon_w, 2);
        $pl: ($w - $icon_w) / 2;
        padding: 0 px($pl);
        // &::befeore {}
      }
      &.-other {
        $icon_w: 24;
        // $pl: math.div($w - $icon_w, 2);
        $pl: ($w - $icon_w) / 2;
        padding: 0 px($pl);
        // &::befeore {}
      }
      &.-estimate {
        $icon_w: 15;
        // $pl: math.div($w - $icon_w, 2);
        $pl: ($w - $icon_w) / 2;
        padding: 0 px($pl);
      }
    }
  }
}