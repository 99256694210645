.p-detailCart {
  @at-root {
    // & {}
    //price
    .p-detailCart__priceRegular {
      font-size: rem(16);
    }
    // .p-detailCart__priceRegularPrice {}
    .p-detailCart__priceRegularTax {
      font-size: 0.625em;
    }
    .p-detailCart__price {
        margin-bottom: 20px;
        color: $color--red;
        font-size: 28px;
        font-weight: 700;
    }
    // .p-detailCart__pricePrice {}
    .p-detailCart__priceTax {
      font-size: 0.625em;
    }

    .p-detailCart__actions {
      max-width: rem(260);
      margin-bottom: 30px;
    }

    .p-detailCart__quantity {
      margin-top: 30px;
      dt,
      dd {
        display: inline-block;
      }
      dt {
        margin-right: 50px;
        color: $color--red;
        font-size: rem(16);
        font-weight: 700;
      }
      dd {
        max-width: rem(100);

        & > input {
          padding-right: 0;
        }
      }
    }
    .p-detailCart__note {
      margin-bottom: 20px;
      color: $color--red;
      font-size: rem(14);
    }
    .p-detailCart__addCart {
      max-width: rem(374);
      margin-bottom: 30px;
      color: #fff;
      font-size: rem(18);
      text-align: center;

      button {
        display: block;
        width: 100%;
        padding: 24px;
      }
      i {
        font-size: rem(22);
        // font-weight: 700;
      }
    }
    .p-detailCart__addFav {
      max-width: rem(276);
      color: #fff;
      font-size: rem(14);
      text-align: center;

      button {
        display: block;
        width: 100%;
        // padding: 24px;
      }
    }

  }
}