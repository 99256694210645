$base_font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif !default;
$base_font_color: #000 !default;
$base_font_weight: 500 !default;
$base_line_height: 1.6 !default;

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  color: $base_font_color;
  font-family: $ff--sans;
  font-weight: $base_font_weight;
  @if ($base_line_height) {
    line-height: $base_line_height;
  }
}

h1,h2,h3,h4,h5,p,dl {
  margin-top: 0;
  margin-bottom: 0;
}

// h1,h2 {
//   font-weight: normal;
// }

img {
  // max-width: 100%;
  width: 100%;
  height: auto;
  // vertical-align: bottom;
}

// *,
*::before,
*::after {
  // from bootstrap
  box-sizing: inherit;
}

main {
  display: block;
}

svg,
img {
  max-width: 100%;
  vertical-align: bottom;
}

figure {
  margin: 0;
}

option {
  padding: 0;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

li {
  list-style: none;
}

dd {
  margin-left: 0;
}

i,
address {
  font-style: normal;
}

a,
button,
label {
  cursor: pointer;
}

/* reset fieldset and legend */
legend {
    display: table;
    padding: 0;
}
fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0.01em 0 0 0;
}
body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
}
