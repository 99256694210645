.mypage { //p-mypageFavorite
  @at-root {
    .p-mypageFavorite__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px 10px;
      max-width: 890px;
      margin: auto;

      @include bp('md') {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      @include bp('lg') {
        grid-gap: 40px 30px;
      }
    }
    .p-mypageFavorite__item {
    }
  }
}
