$zindex_base: 1;

$zindex_mobile_menu: 1000;

$zindex_mobile_menu_close: 1100;

$zindex_page_overlay: 900;

// $zindex_fixed_scroll_top: 110;

// $zindex_fixed_add_cart: 120;

$zindex_list_img: 10;

$zindex_list_cross: 20;

$zindex_list_tags: 12;
