.p-detailTags {
  @at-root {
    // & {}
    .p-detailTags__item {
      display: inline-block;
      margin-right: 4px;
      margin-bottom: 4px;

      .tag-label {
        display: inline-block;
        margin-right: 2px;
        padding: 2px 8px;
        border: 2px solid currentColor;
        background-color: transparent;
        color: $color--red;
        font-size: rem(11);
        line-height: 1.4;
        text-align: center;

      }
      a {
        text-decoration: none;
        @include opacity-hover();
      }
    }
  }
}