.mypage { //p-mypageDelivery
  @at-root {
    .p-mypageDelivery__content {
    }
    .p-mypageDelivery__addAddress {
      max-width: rem(284);
      margin-bottom: 40px;
    }
  }
}
