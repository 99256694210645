@use 'sass:list';
// @use '../2-mixin/bp' as *;

/* margin */
$disappear_values: ('sm', 'md', 'lg');


@each $disappear_value in $disappear_values {
  $length: list.length($disappear_values);
  $index: list.index($disappear_values, $disappear_value);
  $index_next: $index + 1;

  .u-disappear--#{$disappear_value} {
    @include bp($disappear_value) {
      display: none !important;
      visibility: hidden !important;
    }
  }
  .u-disappearLess--#{$disappear_value} {
    @include bpless($disappear_value) {
      display: none !important;
      visibility: hidden !important;
    }
  }
  @if $length != $index {
    .u-disappearOnly--#{$disappear_value} {
      @include bponly(list.nth($disappear_values, $index), list.nth($disappear_values, $index_next)) {
        display: none !important;
        visibility: hidden !important;
      }
    }
  }
}
