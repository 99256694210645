.p-sectionRecommend {
  @at-root {
    .p-sectionRecommend__header {
      margin-bottom: 40px;
    }
    .p-sectionRecommend__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px 10px;

      @include bp('md') {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 40px 30px;
      }
    }
  }
}