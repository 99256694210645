.ec-pagerRole {
  %item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: rem(42);
    height: rem(42);
    padding: 4px;
    border-radius: 5px;
    color: inherit;
    text-decoration: none;
    line-height: 1;
    user-select: none;
  }
  @at-root {
    & {
      margin-top: 80px;

      @include bp('md') {
        margin-top: 130px;
      }
    }
    .ec-pager {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .ec-pager__item {
      margin: 0 4px 8px;
      color: $color--gray;
      font-size: rem(14);

      @include bp('md') {
        margin: 0 6px 10px;
      }
      a {
        @extend %item;
        background-color: $color--gray-light;
        box-shadow: $shadow--btn;

        @include focus() {
          background-color: $color--red;
          color: #fff;
        }
      }
      span {
        @extend %item;
      }
      &.-dot {
        margin-right: 4px;
        margin-left: 4px;

        span {
          background-color: transparent;
          box-shadow: none;
        }
      }
      &.-first,
      &.-prev,
      &.-next,
      &.-last {
        a {
          @include bp('md') {
            min-width: 60px;
          }
        }
      }

      &.-active {
        span {
          background-color: $color--red;
          color: #fff;
        }
      }
    }
  }
}
