.c-newsItem {
  @at-root {
    & {
      background-color: #fff;
    }
    .c-newsItem__head {
      position: relative;
      padding: 10px 40px 10px 20px;

      &[role="button"] {
        cursor: pointer;
      }

      @include bp('md') {
        display: flex;
      }
    }
    .c-newsItem__headDate {
      color: $color--gray;
      font-size: rem(14);

      @include bp('md') {
        margin-right: 60px;
      }
    }
    .c-newsItem__headTitle {
      font-size: rem(14);
      font-weight: 500;
    }
    .c-newsItem__headIcon {
      position: absolute;
      top: 50%;
      right: 20px;
      display: block;
      transform: translateY(-50%);
      i {
        &::before {
          display: inline-block;
          transform: rotate(90deg);
          transition: transform 0.2s;
        }
      }
    }
    // .c-newsItem__content {}
    .c-newsItem__content__inner {
      padding: 10px 20px;
      font-size: rem(14);

      a {
        color: inherit;

        @include focus() {
          text-decoration: none;
        }
      }
    }
  }
  @at-root {
    & {
      &[aria-expanded="true"] {
        .c-newsItem__headIcon {
          i {
            &::before {
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }
}