.ec-orderMails {
  @at-root {
    .ec-orderMails {
      margin-bottom: 60px;
    }
    .ec-orderMails__item {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $color--gray;
      font-size: rem(14);

      &:last-child {
        margin-bottom: 0;
      }
    }
    .ec-orderMails__time {
      margin: 0;
    }
    .ec-orderMails__body {
      display: none;
    }
  }
}

.ec-orderMail {
  @at-root {
    .ec-orderMail__time {
      margin: 0;
    }
    .ec-orderMail__body {
      display: none;
    }
    .ec-orderMail__time {
      margin-bottom: 4px;
    }
    .ec-orderMail__link {
      a {
        color: #0092C4;
        text-decoration: none;
        cursor: pointer;
      }
      a:hover {
        color: #33A8D0;
      }
      margin-bottom: 4px;
    }

    .ec-orderMail__close {
      margin-top: 10px;

      a {
        color: $color--textLink;
        font-size: inherit;
        text-decoration: underline;

        @include focus() {
          text-decoration: none;
        }
      }
    }
  }
}

