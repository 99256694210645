
// MediaQuery
$tablet: 480px;
$desktop: 768px;
$desktop2: 992px;
$desktop3: 1200px;

$font-size: 16px;

// $clrRed: #DE5D50;
$clrRed: $color--red;
$clrRich: #9a947e;
// $clrGray: #F3F3F3;
$clrGray: $color--white-dark;
$clrRightGray: $color--gray-light;
$clrExtraGray: $color--gray;
$clrDarkGray:#525263;
@import "../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
