.other_page { // p-tradelaw
  @at-root {
    .p-tradelaw__datalist {
      font-size: rem(14);

      dt {
        @include bp('md') {
          max-width: 242px;
        }
      }
      li {
        margin-bottom: 0.25em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
