.p-homeProductsSlider {
  @at-root {
    & {

      &.slick-initialized {
        .slide-item {
          width: 100%;
          max-width: 284px;
          padding-right: 6px;
          padding-left: 6px;
        }
      }
      &:not(.slick-initialized) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 16px;

        @include bpless('lg') {
          padding-right: 20px;
          padding-left: 20px;
        }
      }

      .slick-arrow {
        position: absolute;
        top: 50%;
        z-index: 100;
        display: block;
        width: 40px;
        height: 100px;
        padding: 10px;
        border: 0;
        background-color: $color--red;
        color: #fff;
        font-size: 0;
        text-align: center;
        transform: translateY(-50%);

        &::before {
          content: $iconm-double-arrow;
          display: inline-block;
          font-family: '#{$icomoon-font-family}' !important;
          font-size: rem(16);
        }
      }
      .slick-prev {
        left: 0;
        border-radius: 0 6px 6px 0;
        &::before {
          transform: rotate(180deg);
          margin-left: -0.25em;
        }
      }
      .slick-next {
        right: 0;
        border-radius: 6px 0 0 6px;
        &::before {
          margin-right: -0.25em;
        }
      }
      .slick-disabled {
        opacity: 0.35;
      }
    }

  }
}