/*
注文履歴詳細 お客様情報

マイページ 注文詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAccount

Styleguide 15.2.2
*/
.ec-orderAccount {
  @at-root {

    & {
      margin-bottom: 60px;
      font-size: rem(14);

      p {
        margin-bottom: 0;
      }
    }

    // @include clearfix;
    .ec-orderAccount__change {
      display: inline-block;
      margin-left: 10px;
      float: right;
    }
    .ec-orderAccount__account {
      margin-bottom: 16px;
    }
    .non-customer-edit dt {

      @include bp('md') {
        max-width: 242px;
      }
    }
    .ec-zipInput {
      margin-bottom: 20px;
    }
    .mod-button {
      margin-top: 20px;

      .ec-inlineBtn {
        margin-right: 20px;
        min-width: 100px;
      }
    }
  }
}