
/*
注文履歴詳細 支払情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　支払情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderPayment
    .ec-rectHeading
      h2 お支払方法
    p 支払方法： 郵便振替

Styleguide 15.2.4
*/
.ec-orderPayment {
  margin-bottom: 60px;
  font-size: rem(14);

  .ec-rectHeading {
    margin-bottom: 40px;
    border-left: 4px solid #BF0030;
    background-color: #F5F5F5;

    h2 {
      padding: 12px 20px;
      font-size: rem(18);
      font-weight: 700;
      line-height: 1.2;
    }
  }
  .dl_table {
    dl {
      margin-bottom: 10px;
    }
    dt,
    dd {
      display: inline-block;
      vertical-align: middle;
    }
    dt {
      &::after {
        content: ":";
        margin-left: 0.5em;
        margin-right: 0.5em;
      }
    }
  }
  .ec-radio {
    div {
      p {
        margin-top: 5px;
      }
    }
    #shopping_order_Payment_6 {
      & ~ p {
        margin-bottom: 20px;
        img {
          max-width: 568px;
        }
      }
    }
    #shopping_order_Payment_5 {
      & ~ p {
        img {
          max-width: 298px;
        }
      }
    }
  }
}