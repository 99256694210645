.p-productsList {
  @at-root {

    & {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px 10px;

      @include bp('md') {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 40px 30px;
      }
    }
    // .p-productsList__item {}

  }
}