.c-back {
  @at-root {
    & {
      border: 0;
      background-color: transparent;
      color: $color--gray;
      text-decoration: none;

      @include opacity-hover();
      & > i {
        &::before {
          display: inline-block;
          margin-right: 16px;
          margin-top: -0.25em;
          transform: scale(-1);
          vertical-align: middle;
        }
      }
    }
  }
}