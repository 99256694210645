.mypage { // p-forot
  @at-root {
    .p-forgot__content {
      max-width: 788px;
      margin: 0 auto 60px;

      @include bponly('sm', 'lg') {
        max-width: 828px;
      }
    }
    .p-forgot__description {
      margin-bottom: 40px;
      font-size: rem(14);
    }
    .p-forgot__box {
      margin: auto;
      padding: 60px 20px;
      background-color: $color--white-dark;

      @include bp('sm') {
        padding: 60px 30px;
      }
    }
  }
}
