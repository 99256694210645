/*
注文内容確認

カート内 注文内容確認に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/shopping)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderRole

Styleguide 15.2
*/
.ec-orderRole {
  @at-root {

    & {
      // @include container;
      // flex-direction: column;
      // margin-top: 0;

      @include bp('lg') {
        display: flex;
        margin-top: 20px;
        flex-direction: row;
      }
    }
    .ec-inlineBtn {
      font-weight: normal;
    }
    .ec-orderRole__detail {
      padding: 0;
      width: 100%;
      margin-bottom: 60px;

      @include bp('lg') {
        max-width: calc(100% - 384px);
        margin-bottom: 0;
        padding-right: 20px;
      }
    }
    .ec-orderRole__summary {
      width: 100%;

      .ec-inlineBtn {
        display: inline-block;
      }
      @include bp('lg') {
        max-width: 384px;

        .ec-inlineBtn {
          display: none;
        }
      }
    }
    .ec-borderedList {
      margin-bottom: 20px;
      // border-top: 1px dotted $color--gray-light;

      @include bp('lg') {
        border-top: none;
      }

      li {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    .ec-orderRole__back {
    }
  }
}
