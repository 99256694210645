.p-searchBox {
  @at-root {
    & {
      .search-category {
        margin-bottom: 10px;

        select {
          @include select-style();
          width: 100%;
          font-size: rem(14);
        }
      }
      .search-keyword {
        margin-bottom: 15px;
      }
      .search-button {
        max-width: 128px;
        margin-left: auto;
        font-size: rem(14);

        button {
          width: 100%;;
        }
      }
      .search-text {
        display: none;
      }
    }
    .p-searchBox__title {
      margin-bottom: 20px;
      line-height: 1.4;

      .label-en {
        @include ff-ropa-sans;
        color: $color--red;
        font-size: rem(14);
      }
      .label-ja {
        font-size: rem(16);
        font-weight: 700;
      }
    }
  }
}