.registration_page { // .p-entry
  @at-root {
    .p-entry__content {
      max-width: 788px;
      margin: 0 auto 60px;

      @include bponly('sm', 'lg') {
        max-width: 828px;
      }
    }
    .p-entry__datalist {
      margin-bottom: 60px;

      @include bponly('sm', 'lg') {
        padding-right: 20px;
        padding-left: 20px;
      }

      &.-confirm {
        dd {
          padding: 20px 20px 20px 10px;
        }
      }
      dt {
        @include bp('md') {
          max-width: 243px;
        }
      }
      // dd {}
    }
    // .p-entry__caution {}
  }
}