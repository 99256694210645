.p-homeHeroArea {
  @at-root {
    & {
      margin-bottom: 40px;

      @include bp('lg') {
        display: flex;
      }
    }
    .p-homeHeroArea__slider {
      width: 100%;
      max-width: 900px;
      margin: 0 auto 40px;
      overflow: hidden;

      @include bp('lg') {
        max-width: 586px;
        margin-right: 20px;
        margin-bottom: 0;
      }
    }
    .p-homeHeroArea__banners {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: -8px;
      margin-bottom: -8px;

      @include bp('lg') {
        flex: 0 0 284px;
        display: block;
        margin: 0;
      }

      a {
        display: block;
        max-width: 284px;
        margin: 8px;

        @include bp('lg') {
          flex: 0 0 284px;
          margin: 0 0 16px 0;
        }
      }
    }
  }
}