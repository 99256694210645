.l-mypageLayout {
  @at-root {
    // & {}
    .l-mypageLayout__header {
      margin-bottom: 60px;
      color: $color--red;
      text-align: center;

      &::after {
        content: "";
        display: block;
        width: 184px;
        height: 4px;
        margin: 26px auto 0;
        background-color: currentColor;
      }
    }
    .l-mypageLayout__headerLabel {
      color: inherit;
      font-size: rem(24);
      font-weight: 700;
    }
    .l-mypageLayout__nav {
      margin-bottom: 60px;
    }
    .l-mypageLayout__greeting {
      margin-bottom: 60px;
      font-size: rem(14);
      text-align: center;
    }
    .l-mypageLayout__content {}
  }
}