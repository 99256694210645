.p-orderHistory {
  @at-root {
    & {
      border-bottom: 1px solid $color--gray;

      @include bp('md') {
        display: flex;
      }
      &:first-child {
        border-top: 1px solid $color--gray;
      }
    }
    // .p-orderHistory__contents {}
    .p-orderHistory__header {
      position: relative;

      @include bp('md') {
        flex: 1;
        max-width: 300px;
      }
      @include bp('lg') {
        max-width: 384px;
      }
      &::after {
        content: "";
        display: block;
        width: calc(100% - 40px);
        height: 1px;
        margin: auto;
        background-color: $color--gray;

        @include bp('md') {
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: calc(100% - 60px);
          margin: 0;
          transform: translateY(-50%);
        }
      }
    }
    .p-orderHistory__detail {
      @include bp('md') {
        flex: 1;
      }
    }
  }
}

.p-orderHistoryHeader {
  @at-root {
    & {
      height: 100%;
      padding: 30px 20px;
      background-color: $color--white-dark-light;
    }
    .p-orderHistoryHeader__date {
      margin-bottom: 20px;
      font-size: rem(18);
      font-weight: 700;
    }
    .p-orderHistoryHeader__data {
      margin-bottom: 20px;
    }
    .p-orderHistoryHeader__dataItem {
      display: flex;
      font-size: rem(14);

      dt  {
        font-weight: 500;
        &::after {
          content: ":";
          margin-right: 0.5em;
          margin-left: 0.5em;
        }
      }
    }
    .p-orderHistoryHeader__action {
      max-width: 182px;
    }
  }
}

.p-orderHistoryDetail {
  @at-root {
    & {
      display: flex;
      padding: 30px 20px;
      border-bottom: 1px dashed $color--gray-light;

      &:last-child {
        border-bottom: 0;
      }

      @include bp('md') {
        padding: 30px 40px;
      }
    }
    .p-orderHistoryDetail__img {
      flex: 0 0 auto;
      max-width: 100px;
      margin-right: 20px;

      @include bp('md') {
        max-width: 120px;
        margin-right: 30px;
      }
    }
    .p-orderHistoryDetail__content {
    }
    .p-orderHistoryDetail__title {
      margin-bottom: 20px;
      font-size: rem(18);
      font-weight: 700;
      line-height: 1.2;
    }
    .p-orderHistoryDetail__price {
      font-size: rem(14);
    }
  }
}