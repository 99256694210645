.p-homeCategoryNav {
  @at-root {
    // & {}
    .p-homeCategoryNav__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px 10px;

      @include bp('md') {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
      }
    }
    // .p-homeCategoryNav__item {}
  }
}