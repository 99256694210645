.l-page {
  @at-root {
    // & {}
    .l-page__header {
      border-bottom: 2px solid $color--red-light;
    }
    .l-page__container {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      max-width: px($contentWidth);
      margin: 50px auto 0;

      @include bp('lg') {
        flex-direction: row;
      }
    }
    .l-page__containerMain {
      width: 100%;
      margin: 0 auto 150px;
    }
    .l-page__containerSide {
      flex: 0 0 auto;
      width: 100%;
      max-width: 304px;
      padding-right: 40px;
      margin-bottom: 150px;

      & + .l-page__containerMain {
        @include bp('lg') {
          max-width: calc(100% - 264px);
        }
      }
    }
    // .l-page__breadcrumbTop {
    //   max-width: px($contentWidth);
    //   margin: auto;
    //   padding: 10px;
    // }
    .l-page__breadcrumbBottom {
      padding: 15px 20px;
      background-color: $color--white-dark;

      .p-breadcrumb {
        max-width: px($contentWidth);
        margin: auto;
      }
    }
    .l-page__drawerMenu {
      position: fixed;
      top: 0;
      left: 0;
      z-index: $zindex_mobile_menu;
      width: 90%;
      max-width: 308px;
      height: 100vh;
      overflow-y: scroll;
      background-color: #fff;
      transform: translateX(-101%);
      transition: all .3s;

      &.is-active {
        transform: translateX(0);
      }
    }
    .l-page__drawerMenuClose {
      position: fixed;
      top: 10px;
      right: 10px;
      z-index: $zindex_mobile_menu_close;
      display: none;
      border: 0;
      background-color: transparent;
      color: #fff;
      font-size: rem(20);
      text-shadow: 0 0 8px rgba(0,0,0,0.65);
      text-align: center;
      cursor: pointer;
      transition: all .3s;

      &.is-active {
        display: inline-block;
      }
    }
    .l-page__overlay {
      position: fixed;
      top: 0;
      left: 0;
      z-index: $zindex_page_overlay;
      width: 100%;
      height: 100vh;
      visibility: hidden;
      background: transparent;
      opacity: 0;
      transform: translateX(0);
      transition: all .3s;
    }
  }

  @at-root {
    body.front_page {
      .l-page__container {
        @include bpless('md') {
          margin-top: 0;
        }
      }
    }
    body.have_curtain {
      .l-page__overlay {
        display: block;
        opacity: 1;
        background: rgba(0, 0, 0, 0.5);
        visibility: visible;
      }
    }
  }
}