.p-homeSearch {
  @at-root {
    & {
      max-width: 100%;
      margin: auto;
      padding: 40px 30px;
      border-radius: 6px;
      background-color: $color--white-dark;

      @include bp('md') {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        padding: 30px 40px 30px 30px;
      }
      .searchform {
        @include bp('md') {
          flex: 1;
          display: flex;
          align-items: center;
        }
      }
      .search-text {
        display: none;
        // margin-bottom: 10px;
        // font-size: rem(14);

        // .number {
        //   flex: 0 0 auto;
        //   display: block;
        //   width: rem(28);
        //   height: rem(28);
        //   margin-right: 10px;
        //   border-radius: 50%;
        //   background-color: $color--red-light;
        //   color: #fff;
        //   font-size: rem(18);
        //   line-height: rem(28);
        //   text-align: center;
        // }
      }
      .search-container {
        margin-left: auto;

        @include bp('md') {
          width: 100%;
          max-width: 354px;
        }
      }
      .search-category {
        display: none;;
        // margin-bottom: 20px;
        // font-size: rem(14);

        // @include bp('md') {
        //   margin-right: 20px;
        //   margin-bottom: 0;
        // }

        // select {
        //   width: 100%;
        // }
      }
      .search-keyword {
        @include bpless('md') {
          margin-bottom: 20px;
        }
        @include bp('md') {
          margin-left: auto;
        }
      }
      .search-button {
        width: 100%;
        max-width: 128px;
        margin-left: auto;
        font-size: rem(14);

        @include bp('md') {
          margin-left: 40px;
        }
        button {
          width: 100%;;
        }
      }
    }
    .p-homeSearch__header {

      @include bpless('md') {
        margin-bottom: 30px;
      }
      @include bp('md') {
        margin-right: 30px;
      }
    }
  }
}