$icomoon-font-family: "sppec-icon" !default;
$icomoon-font-path: "../fonts" !default;

$iconm-exclamation-circle: "\e90e";
$iconm-external-link: "\e90d";
$iconm-heart: "\e90b";
$iconm-cross-bold: "\e90c";
$iconm-cross: "\e90a";
$iconm-email: "\e908";
$iconm-home: "\e900";
$iconm-lock: "\e901";
$iconm-menu: "\e902";
$iconm-person: "\e903";
$iconm-search: "\e904";
$iconm-cart: "\e905";
$iconm-arrow: "\e909";
$iconm-double-arrow: "\e906";
$iconm-hard-hat: "\e907";