// @use '../1-function/' as *;

.u-fz--10 {
  font-size: rem(10) !important;
}
.u-fz--11 {
  font-size: rem(11) !important;
}
.u-fz--12 {
  font-size: rem(12) !important;
}
.u-fz--14 {
  font-size: rem(14) !important;
}
.u-fz--16 {
  font-size: rem(16) !important;
}
.u-fz--18 {
  font-size: rem(18) !important;
}
.u-fz--20 {
  font-size: rem(20) !important;
}
.u-fz--24 {
  font-size: rem(24) !important;
}
