.mypage { // p-login
  @at-root {
    .p-login__content {
      max-width: 788px;
      margin: auto;
    }
    .p-login__box {
      margin: auto;
      padding: 60px 20px;
      background-color: $color--white-dark;

      @include bp('sm') {
        padding: 60px 30px;
      }
    }
  }
}
