/*
画像レイアウト

画像とテキストを水平に並べるレイアウトです。

画像は20%で表示されます。

ex [注文履歴 ログイン後→注文履歴ボタンを押下](http://demo3.ec-cube.net/mypage)

Markup:
.ec-imageGrid
  .ec-imageGrid__img: img(src="http://demo3.ec-cube.net/upload/save_image/0701113537_559351f959620.jpeg")
  .ec-imageGrid__content
    p.ec-font-bold ホーローマグ
    p ¥ 1,728 x 1

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.2.1
*/
.ec-imageGrid {
  @at-root {

    & {
      display: flex;
      width: 100%;
    }
    .ec-imageGrid__img {
      width: 120px;
      margin-right: 20px;

      img {
        width: 100%;
      }
    }
    .ec-imageGrid__content {
      font-size: rem(14);
      // vertical-align: middle;
      // display: table-cell;

      // span {
      //   margin-left: 10px;
      // }
      // p {
      //   margin-bottom: 0;
      // }
    }
  }
}
