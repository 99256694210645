.c-checkText {
  @at-root {
    & {
      display: inline-flex;
      align-items: center;
      font-size: rem(14);
      user-select: none;

      & > input {
        margin-right: 10px;
      }
    }
    .c-checkText__link {
      display: inline-block;
      margin-right: 5px;
      color: inherit;

      @include focus() {
        text-decoration: none;
      }

      i {
        display: inline-block;
        margin-left: 5px;
        font-size: 0.875em;
      }
    }
  }
}