.p-listHeading {
  @at-root {
    & {
      border-left: 4px solid $color--red;
      background-color: $color--white-dark;
    }
    .p-listHeading__label {
      padding: 25px 40px;
      font-size: rem(20);
      font-weight: 700;
      line-height: 1.2;
    }
  }
}