/*
注文履歴詳細 オーダ情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderInfo

Styleguide 15.2.1
*/
.ec-orderOrder {
  @at-root {
    & {
      margin-bottom: 60px;
      font-size: rem(14);
    }

    .ec-orderOrder__items {
      // @include borderBottom;
      // @include borderTop;
    }
  }
}