#gmo_payment_gateway_cvs_form {
  h2 {
    margin-bottom: 20px;
    border-left: 4px solid $color--red;
    background-color: $color--white-dark;
    padding: 12px 20px;
    font-size: rem(18);
    font-weight: 700;
    line-height: 1.2;
  }
}