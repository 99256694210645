.p-contactBtn {
  @at-root {
    & {
      position: relative;
      display: block;
      padding-left: 60px;
      overflow: hidden;
      border-radius: 4px;
      border: 0;
      background-color: $color--red;
      box-shadow: $shadow--btn;
      color: #fff;
      text-decoration: none;
      line-height: 1.4;
      user-select: none;

      @include focus() {
        box-shadow: $shadow--btn-hover;
      }
    }
    .p-contactBtn__head {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 60px;
      height: 100%;
      background-color: $color--red-dark;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .p-contactBtn__body {
      display: block;
      padding: 18px 20px;
    }
  }
}