.l-siteHeader {

  $_bp_md: 800;
  $_bp_lg: 1000;
  @at-root {
    & {
      max-width: 1366px;
      margin: auto;

      @include mqless($_bp_lg) {
        position: relative;
        padding-top: 32px;
      }
    }
    .l-siteHeader__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
    }
    .l-siteHeader__notice {
      height: rem(32);
      padding-right: 10px;
      padding-left: 10px;
      border-radius: 4px;
      background-color: $color--red;
      color: #fff;
      font-size: rem(12);
      text-align: center;
      line-height: rem(32);

      @include mqless($_bp_lg) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 0;
      }
      @include bp('md') {
        padding-right: 30px;
        padding-left: 30px;
        font-size: rem(14);
      }

    }
    // .l-siteHeader__menuButton {}
    .l-siteHeader__logo {
      flex: 0 1 auto;
      max-width: 94px;

      @include mq($_bp_md) {
        max-width: 140px;
      }
      @include mq($_bp_lg) {
        margin-left: auto;
      }
    }
    .l-siteHeader__login {
      @include bp('md') {

        margin-right: 30px;
        margin-left: auto;
      }
    }
  }
  @at-root {
    & {
      &.-withoutUserData {
        .l-siteHeader__inner {
          height: 90px;
        }
        .l-siteHeader__logo {
          @include mq($_bp_md) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}