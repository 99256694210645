.p-listConditions {
  @at-root {
    & {
      overflow: hidden;
    }
    .p-listConditions__controls {
      margin-bottom: 20px;
      font-size: rem(12);

      @include bp('md') {
        float: right;
      }
      .disp-number {
        width: rem(80);
        margin-right: 8px;
        padding: rem(5) px(10);
      }
      .order-by {
        width: rem(140);
        padding: rem(5) px(10);
      }
    }
    .p-listConditions__result {
      font-size: rem(14);

      // .result-number {
      //   font-weight: 700;
      // }
      @include bp('md') {
        float: left;
      }
    }
  }
}