.p-shoppingLogin {
  @at-root {

    .p-shoppingLogin__subTitle {
      margin-bottom: 40px;
      color: $color--red;
      font-size: rem(20);
      font-weight: 700;
      line-height: 1.4;
      text-align: center;
    }
    .p-shoppingLogin__container {
      @include bp('md') {
        display: flex;
      }
    }
    .p-shoppingLogin__box {
      flex: 1;
      padding: 60px 30px;
      background-color: $color--white-dark;

      &.-login {
        margin-bottom: 60px;

        @include bp('md') {
          max-width: px(788);
          margin-right: 20px;
          margin-bottom: 0;
        }
      }
      &.-guest {
        max-width: px(384);
        text-align: center;
      }

      .action-guest {
        width: 100%;
        max-width: rem(275);
        padding: 18px;
        font-size: rem(16);
      }
    }

  }
}