.other_page { // p-privacy
  @at-root {
    .p-privacy__section {
      margin-bottom: 60px;
      font-size: rem(14);

      li {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
