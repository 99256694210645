$color--gray: #ccc !default;

@mixin input-style() {
  max-width: 100%;
  padding: rem(9) px(14);
  line-height: 1.4;
  border-radius: rem(5);
  border: 1px solid #000;
  box-shadow: inset 1px 1px 5px rgba(0,0,0,0.4);

  &::placeholder {
    color: $color--gray-light;
    font-size: rem(14);
  }
}