.c-halfInput {
  display: flex;
  max-width: 100%;

  @include bp('md') {
    max-width: 464px;
  }

  input {
    width: 100%;
  }

  & > * {
    &:first-child {
      margin-right: 10px;

      @include bp('sm') {
        margin-right: 20px;
      }
    }
  }
}