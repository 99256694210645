.p-shoppingNonmember {
  @at-root {
    // .p-shoppingNonmember__content {}
    .p-shoppingNonmember__container {
      max-width: 788px;
      margin: auto;
    }
    .p-shoppingNonmember__datalist {
      margin-bottom: 60px;
      dt {
        max-width: 242px;
      }
    }
  }
}