.ec-halfInput {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;

  @include bp('md') {
    max-width: 464px;
  }

  input {
    @include input-style();
  }
  & > input {
    width: calc(50% - 10px);
  }
  .ec-errorMessage {
    width: calc(50% - 10px);
  }

  & > * {
    &:nth-child(odd) {
      margin-right: 10px;

      @include bp('sm') {
        margin-right: 20px;
      }
    }
  }
}
