$slick-dot-color: $color--red-light;
$slick-loader-path: '../img/common/icon/';
$slick-dot-size: 12px;
$slick-font-family: ''; //slick-fontは使用しない

@import 'theme';


/* Dots */

.slick-dotted.slick-slider {
    padding-bottom: 32px;
}

.slick-dots {
  position: absolute;
  bottom: 0;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  line-height: 1;

  li {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    margin: 0 8px;
    padding: 0;
    font-size: $slick-dot-size;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      width: 1em;
      height: 1em;
      outline: none;
      color: transparent;
      cursor: pointer;

      &:hover,
      &:focus {
        outline: none;

        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background-color: currentColor;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}