.p-formAction {
  @at-root {
    & {
    }
    %go_btn_style {
      position: relative;
      display: block;
      // width: 100%;
      margin: auto;
      padding: 24px 36px;
      font-size: rem(18);
    }
    .p-formAction__btn {
      text-align: center;

      .btn-middle {
        @extend %go_btn_style;
        max-width: rem(334);

        @include bp('md') {
          max-width: rem(384);
        }
      }
      .c-btn {
        @extend %go_btn_style;
      }
      i {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
      }
    }
    .p-formAction__text {
      margin-top: 40px;
      text-align: center;
    }
  }
}