.product_page { // .p-productsDetail
  @at-root {
    .p-productsDetail__header {
      margin-bottom: 40px;

      @include bp('md') {
        margin-bottom: 60px;
      }
    }
    .p-productsDetail__container {
      margin-bottom: 80px;

      @include bp('lg') {
        display: grid;
        grid-template-columns: 485px 384px;
        grid-column-gap: 20px;
      }
    }
    .p-productsDetail__left {
    }
    %tri {
      position: absolute;
      top: -2px;
      left: 50%;
      width: 0;
      height: 0;
      display: block;
      border-style: solid;
      border-color: transparent;
      transform: translateX(-50%);

      @include bpless('lg') {
        content: "";
      }
    }
    .p-productsDetail__slider {
      margin-bottom: 60px;
    }
    .p-productsDetail__info {
      font-size: rem(14);

      @include bpless('lg') {
        margin-bottom: 60px;
      }
    }
    .p-productsDetail__description {
      padding-top: 40px;
      padding-bottom: 40px;
      border-top: 1px dotted #000;
      border-bottom: 1px dotted #000;
    }
    .p-productsDetail__freearea {
      margin-top: 20px;
      font-size: rem(14);
    }
    .p-productsDetail__data {
      margin-top: 20px;
      // border-top: 1px dotted #000;
      // font-size: rem(14);
    }
    .p-productsDetail__right {
      @include bpless('lg') {
        margin-top: 40px;
      }
    }
    .p-productsDetail__attention {
      margin-bottom: 40px;
    }
    .p-productsDetail_wrapper {
      position: relative;

      @include bpless('lg') {
        padding: 60px 20px;
        border-top: 2px solid $color--red;
        background-color: $color--white-dark;
      }

      &::before {
        @extend %tri;
        border-width: 12px 11px 0 11px;
        border-top-color: $color--red;
      }
      &::after {
        @extend %tri;
        border-width: 10px 9px 0 9px;
        border-top-color: #fff;
      }
    }
    .p-productsDetail__attention {
      & > div {
        & + div {
          margin-top: 10px;
        }
      }
    }
    // .p-productsDetail__cart {}
  }
}