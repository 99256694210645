.p-relationCategory {
  @at-root {
    .p-relationCategory__header {
      margin-bottom: 40px;
    }
    .p-relationCategory__category {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
      .category-items {
        margin-top: 6px;
        margin-left: -2px;

        @include bp('md') {
          margin-left: -10px;
        }
      }
      .category-item {
        display: inline-block;
        margin: 5px 2px;
        padding: 1px 2px 4px;

        @include bp('md') {
          min-width: rem(182);
          margin: 5px 10px;
        }
      }
      // .category-parent {
      //   display: inline-block;
      //   padding: 1px 2px 4px;

      //   @include bp('md') {
      //     min-width: rem(182);
      //   }
      // }
      // .category-children {
      //   margin-top: 6px;
      //   margin-left: -2px;

      //   @include bp('md') {
      //     margin-left: -10px;
      //   }
      // }
      // .category-child {
      //   display: inline-block;
      //   margin: 5px 2px;
      //   padding: 1px 2px 4px;

      //   @include bp('md') {
      //     min-width: rem(182);
      //     margin: 5px 10px;
      //   }
      // }
    }
  }
}