.c-headingSecondary {
  @at-root {
    & {
      position: relative;
      padding-bottom: 10px;
      border-bottom: 4px solid $color--red;
      line-height: 1.4;

      .label-en {
        display: inline-block;
        margin-right: 5px;
        color: $color--red;
        font-size: rem(20);
      }
      .label-ja {
        display: inline-block;
        font-weight: 700;
        font-size: rem(20);
      }
    }

    //スマホで無理な為無効
    // .c-headingSecondary__link {
    //   position: absolute;
    //   top: 5px;
    //   right: 10px;
    //   color: inherit;
    //   font-size: rem(14);
    //   font-weight: 700;
    //   text-decoration: none;

    //   i {
    //     margin-left: 10px;
    //     color: $color--red;
    //   }
    // }
  }
}