.p-favBtn {
  @at-root {
    & {
      @include btn-base();
      padding: 10px;
      border-radius: 4px;
      border: 1px solid $color--gray;
      background-color: $color--gray;
      color: #fff;

      & > i {
        display: inline-block;
        vertical-align: middle;
      }
    }
    &:disabled {
      background-color: #fff;
      color: $color--gray;
    }
  }
}