.c-headingQuaternary {
  @at-root {
    & {
      display: flex;
      align-items: flex-end;
      min-height: rem(27);
      padding-left: 15px;
      border-left: 4px solid $color--red;;

      .label-en {
        margin-right: 5px;
        margin-bottom: -0.13em;
        color: $color--red;
        font-size: rem(18);
      }
      .label-ja {
        font-size: rem(18);
        font-weight: 700;
      }
    }
  }
}