.l-drawerMenu {
  @at-root {
    & {
      max-width: 308px;
    }
    .l-drawerMenu__header {
      display: flex;
      align-items: center;
      padding: 35px 20px;
    }
    .l-drawerMenu__headerLogo {
      max-width: 96px;
      margin-right: 45px;

      a {
        @include opacity-hover();
      }
    }
    .l-drawerMenu__headerCartLink {
        flex: 1;
        max-width: 128px;

      a {
        width: 100%;
        font-size: rem(14);
        text-align: center;
      }
    }
    // .l-drawerMenu__subTitle {
    //   padding: 16px 30px;
    //   background-color: $color--red;
    //   color: #fff;
    //   line-height: 1.4;

    //   .label-en {
    //     margin-bottom: 4px;
    //     @include ff-ropa-sans;
    //     font-size: rem(14);
    //   }
    //   .label-ja {
    //     font-size: rem(16);
    //     font-weight: 700;
    //   }
    // }
    // .l-drawerMenu__other {
    //   .l-drawerMenu__subTitle {
    //     background-color: $color--gray;
    //   }
    // }
  }
}