.p-homeCategoryNavItem {
  @at-root {
    & {
      display: block;
      height: 100%;
      padding: 24px 20px;
      border: 1px solid $color--gray;
      color: inherit;
      text-decoration: none;
      @include opacity-hover();
    }
    .p-homeCategoryNavItem__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 74px;
      min-height: 61px;
      margin: 0 auto 16px;

      @include bp('md') {
        margin-bottom: 0;
      }
    }
    .p-homeCategoryNavItem__text {
    }
    .p-homeCategoryNavItem__name {
      margin-bottom: 10px;
      font-size: rem(16);
      font-weight: 700;
    }
    .p-homeCategoryNavItem__description {
      font-size: rem(12);
    }
  }
  @at-root {

    &.-paper-products {
      .p-homeCategoryNavItem__icon {
        img {
          max-width: 69px;
        }
      }
    }
    &.-film-products {
      .p-homeCategoryNavItem__icon {
        img {
          max-width: 69px;
        }
      }
    }
    &.-packing-materials {
      .p-homeCategoryNavItem__icon {
        img {
          max-width: 69px;
        }
      }
    }
    &.-paper {
      .p-homeCategoryNavItem__icon {
        img {
          max-width: 74px;
        }
      }
    }
    &.-eco {
      .p-homeCategoryNavItem__icon {
        img {
          max-width: 66px;
        }
      }
    }
    &.-measures {
      .p-homeCategoryNavItem__icon {
        img {
          max-width: 51px;
        }
      }
    }
    &.-other {
      .p-homeCategoryNavItem__icon {
        img {
          max-width: 38px;
        }
      }
    }
    &.-estimate {
      .p-homeCategoryNavItem__icon {
        img {
          max-width: 44px;
        }
      }
    }
  }
}