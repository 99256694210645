/*
注文詳細 配送情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　配送情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderDelivery

Styleguide 15.2.3
*/
.ec-orderDelivery {
  @at-root {
    .ec-orderDelivery {
      margin-bottom: 60px;
    }
    .ec-orderDelivery__title {
      margin-bottom: 30px;
      // padding: 16px 0 17px;
      font-weight: bold;
      font-size: 18px;
      position: relative;
    }
    .ec-orderDelivery__change {
      display: inline-block;
      position: absolute;
      right: 0;
      top:0;
      font-size: rem(14);
    }
    .ec-orderDelivery__item {
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid $color--gray-light;
      // @include borderBottom;
      // @include borderTop;

      &:last-child {
        border-bottom: 1px solid $color--gray-light;
      }
    }
    .ec-orderDelivery__address {
      margin: 30px 0 40px;

      p {
        margin:0;
        font-size: rem(14);
      }
    }
    .ec-orderDelivery__edit {
    }
    .ec-orderDelivery__actions {

      .delivery-actions-item {
        display: flex;
        align-items: center;
      }
      dt {
        flex: 1;
        max-width: 100px;
        padding-right: 10px;
      }
      dd {
        flex: 1;
        max-width: rem(384);
        font-size: rem(14);
      }
    }
    .ec-orderPayment__select {
      & > div {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
      label {
        width: 100%;
        max-width: rem(384);
      }
    }
  }
}