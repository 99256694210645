.c-pdflink {
  @at-root {
    & {
      position: relative;
      display: inline-block;
      padding: 9px 30px 9px 60px;
      border-radius: 4px;
      border: 1px solid #000 ;
      background: linear-gradient(#fff, #f0f0f0);
      color: inherit;
      text-decoration: none;
      line-height: 1.4;
      user-select: none;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 28px;
        display: block;
        width: px(17);
        height: px(20);
        background: transparent url('../img/common/icon/pdf.svg') 0 0 no-repeat;
        transform: translateY(-50%);
      }

      @include focus() {
        background: linear-gradient(#f0f0f0, #fff);
      }
    }
  }
}