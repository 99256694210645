.l-mypageNav {
  @at-root {
    & {}
    .l-mypageNav__list {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid #000;
      border-right: 0;
      border-bottom: 0;
    }
    .l-mypageNav__item {
      flex: 1 0 auto;
      width: 100%;
      max-width: 50%;
      border: 1px solid #000;
      border-top: 0;
      border-left: 0;
      text-align: center;

      @include bp('md') {
        max-width: 20%;
      }

      a {
        display: block;
        padding: 15px;
        color: inherit;
        font-size: rem(14);
        text-decoration: none;
        user-select: none;

        @include focus() {
          text-decoration: underline;
        }
      }
      &.is-current {
        color: $color--red;
      }
    }
  }
}