/*
総計

会計時の合計金額、総計を表示します。

ex [カートページ　統計部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-totalBox

Styleguide 7.3.8
*/
.ec-totalBox {
  @at-root {
    & {
      background-color: $color--white-dark;
      padding: 40px 30px;
      // margin-bottom: 16px;
    }
    .ec-totalBox__spec {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      dt {
        font-weight: normal;
        text-align: left;
      }
      dd {
        text-align: right;
      }
      .ec-totalBox__specTotal {
        // color: $clrRed;
      }
    }
    .ec-totalBox__total {
      border-top: 2px solid $color--gray-light;
      margin-top: 14px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: right;
      font-size: rem(14);
      font-weight:bold;
    }
    .ec-totalBox__paymentTotal {
      padding: 10px 0;
      text-align: right;
      font-size: rem(14);
      font-weight:bold;

      // .ec-totalBox__price,
      // .ec-totalBox__taxLabel {
      //     color: $clrRed;
      // }
    }
    .ec-totalBox__price {
      margin-left: 16px;
      color: $color--red;
      font-size: rem(24);
      font-weight:bold;
      line-height: 1.2;

      @include bp('md') {
        font-size: rem(24);
      }
    }
    .ec-totalBox__taxLabel {
      margin-left: 8px;
      font-size: rem(12);

      @include bp('md') {
        font-size: rem(14);
      }
    }
    .ec-totalBox__taxRate {
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      margin-bottom:8px;
      font-size: rem(10);

      @include bp('md') {
        font-size: rem(12);
      }
      dt {
        font-weight: normal;
        text-align: left;
        margin-right: 8px;

        &::before {
          content: "[ ";
        }
      }
      dd {
        text-align: right;
        &::after {
          content: " ]";
        }
      }
    }
    .ec-totalBox__action {
      margin-top: 20px;
      margin-bottom: -10px;
      text-align: center;

      & > * {
        width: 100%;
        max-width: rem(275);
        margin: 10px;
        padding: 24px;
      }
    }
    .ec-totalBox__btn {
      margin-top: 20px;
      margin-bottom: -10px;
      text-align: center;

      & > * {
        width: 100%;
        max-width: rem(275);
        margin: 10px;
        padding: 24px;
      }
    }
  }
}

