.l-sidebar {
  @at-root {
    & {
      @include bp('lg') {
        max-width: 308px;
      };

      & > div {
        @include bp('lg') {
          margin-bottom: 30px;
        }
      }
    }
    // .l-sidebar__subTitle {
    //   padding: 16px 30px;
    //   background-color: $color--red;
    //   color: #fff;
    //   line-height: 1.4;

    //   .label-en {
    //     margin-bottom: 4px;
    //     @include ff-ropa-sans;
    //     font-size: rem(14);
    //   }
    //   .label-ja {
    //     font-size: rem(16);
    //     font-weight: 700;
    //   }
    // }
    .p-navSearch {
      padding: 36px 20px;
      background-color: $color--white-dark;

      & > div {
        max-width: 400px;
        margin: auto;
      }
    }
  }
}