.l-headerMenu {
  @at-root {
    & {
      background-color: $color--white-dark;

      @include bpless('md') {
      }
    }
    .l-headerMenu__list {
      display: flex;
      justify-content: center;
      max-width: 660px;
      margin: auto;
    }
    .l-headerMenu__item {
      flex: 0 0 calc(100% / 4);
      position: relative;
      padding-right: 2px;
      text-align: center;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 2px;
        height: 60px;
        background-color: $color--gray-light;
        transform: translateY(-50%);
      }
      i {
        display: block;
        min-height: 40px;
        margin-bottom: 4px;
      }
      a {
        display: block;
        padding: 18px 15px 14px;
        color: inherit;
        font-size: rem(14);
        text-decoration: none;
        line-height: 1.3;

        @include focus() {
          background-color: #fff;
        }
      }
      &:last-child {
        padding-right: 0;
        &::after {
          content: none;
        }
      }

      &.-products {
        img {
          max-width: 33px;
        }
      }
      &.-estimate {
        i {
          padding-top: 4px;
        }
        img {
          max-width: 22px;
        }
      }
      &.-siteinfo {
        i {
          padding-top: 8px;
        }
        img {
          max-width: 29px;
        }
      }
      &.-guide {
        i {
          padding-top: 7px;
        }
        img {
          max-width: 15px;
        }
      }
    }

  }
}