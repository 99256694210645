/*
カートヘッダ

購入完了までの手順や、現在の状態を表示します。

ul 要素を用いたリスト要素としてマークアップします。

ex [カートページ　ヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-progress

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3.1
*/
.ec-progress {
  @at-root {
    & {
      display: flex;
      flex-wrap: wrap;
      max-width: 1000px;
      margin: auto;
      padding-left: 5px;

      @include bp('sm') {
        padding-left: 20px;
        padding-right: 10px;
      }
    }
    .ec-progress__item {
      display: inline-flex;
      align-items: center;
      width: 182px;
      height: 60px;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 10px 20px;
      overflow: hidden;
      color: $color--gray;
      font-size: rem(14);
      background: transparent url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22182%22%20height%3D%2260%22%20viewBox%3D%220%200%20182%2060%22%3E%3Cpath%20d%3D%22M0%2C0H172l10%2C30L172%2C60H0Z%22%20fill%3D%22%23c8c8c8%22%2F%3E%3C%2Fsvg%3E') 0 0 no-repeat;
      background-size: contain;

      @include bpless('sm') {
        width: 146px;
        height: 48px;
        margin-right: 5px;
        margin-left: 5px;
        font-size: rem(12);
        line-height: 1.3;
      }
      &:after {
      }
      &:last-child {
        &::after {
        }
      }
    }
    .ec-progress__number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(22);
      height: rem(22);
      margin-right: 10px;
      border-radius: 50%;
      background-color: #fff;
    }
    .ec-progress__label {
      display: inline-block;
    }
    .is-complete {
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22182%22%20height%3D%2260%22%20viewBox%3D%220%200%20182%2060%22%3E%3Cpath%20d%3D%22M0%2C0H172l10%2C30L172%2C60H0Z%22%20fill%3D%22%23BF0030%22%2F%3E%3C%2Fsvg%3E');

      .ec-progress__number {
        color: $color--red;
      }
      .ec-progress__label {
        color: #fff;
      }
    }
  }
}