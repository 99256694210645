.c-attentionBox {
  @at-root {
    & {
      padding: 20px 16px;
      border-radius: 6px;
      background-color: #F5F5F5;
    }

    .c-attentionBox__heading {
      position: relative;
      margin-bottom: 10px;
      padding: 4px 10px;
      background-color: $color--gray;
      color: #fff;

      .heading-label {
        font-size: rem(14);
      }
    }
    .c-attentionBox__text {
      font-size: rem(13);
    }
  }
  @at-root {
    &.-theme-caution {
      .c-attentionBox__heading {
        padding-left: 30px;
        background-color: $color--red;

        &::before {
          content: $iconm-exclamation-circle;
          position: absolute;
          top: 50%;
          left: 8px;
          display: block;
          font-family: '#{$icomoon-font-family}' !important;
          transform: translateY(-50%);
        }
      }
      .c-attentionBox__text {
        color: $color--red;
      }
    }
  }
}