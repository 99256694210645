#gmo_payment_gateway_credit_confirm_form {
  h2 {
    margin-bottom: 20px;
    border-left: 4px solid $color--red;
    background-color: $color--white-dark;
    padding: 12px 20px;
    font-size: rem(18);
    font-weight: 700;
    line-height: 1.2;
  }
  .dl_table {
    dl {
      display: block !important;
      height: auto !important;
      margin-bottom: 10px;

      @include bp('md') {
        display: flex !important;
        flex-wrap: nowrap;
        align-items: center;
      }
    }
    dt,dd {
      display: block !important;
    }
    dt {
      flex: 1 0 auto;
      width: 100% !important;
      max-width: 170px;
      margin-bottom: 5px;
      padding-right: 5px;
    }
    .ec-input {
      input {
        margin-top: 5px;
      }
      & + .ec-input {
          // margin-left: 10px;
      }
    }
  }
}
#shopping_order_security_code {
  margin-bottom: 5px;
}