.p-orderDetail {
  @at-root {
    & {
    }
    .p-orderDetail__date {
      margin-bottom: 20px;
      font-size: rem(18);
      font-weight: 700;
    }
  }
}