.other_page { // p-guide
  @at-root {
    .p-guide__nav {
      margin-bottom: 80px;

      @include bp('md') {
        margin-bottom: 60px;
      }
      .p-guide__navTitle {
        padding: 16px 30px;
        background-color: $color--red;
        color: #fff;
        line-height: 1.4;

        .label-en {
          margin-bottom: 4px;
          @include ff-ropa-sans;
          font-size: rem(14);

          @include bp('md') {
            display: inline-block;
            margin-bottom: 0;
            margin-right: 5px;
          }
        }
        .label-ja {
          font-size: rem(16);
          font-weight: 700;

          @include bp('md') {
            display: inline-block;
          }
        }
      }
    }
    // .p-guide__navTitle {}
    // .p-guide__content {}
    .p-guide__section {
      margin-bottom: 80px;
      font-size: rem(14);

      .logo-card-brand {
        max-width: 298px;
      }
      .logo-convenience-store {
        max-width: 568px;
      }
    }
    .p-guide__shipping {
      dt {
        @include bp('md') {
          max-width: 606px;
        }
      }
    }
    .p-guide__chargeTypeBTable {
      display: block;
      width: 100%;
      height: 600px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}
