.l-siteFooter {
  @at-root {
    & {
      border-top: 6px solid $color--red;
      background-color: $color--white-dark;
      @include bpless('md') {
      }
    }
    .l-siteFooter__inner {
      max-width: 920px;
      padding: 74px 20px 22px;
      margin: auto;

      @include bp('lg') {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    .l-siteFooter__head {
      @include bp('lg') {
        max-width: rem(384);
      }
    }
    .l-siteFooter__text {
      color: $color--gray;
      font-size: rem(11);
    }
    .l-siteFooter__logo {
      max-width: 96px;
      padding-bottom: 20px;
    }
    .l-siteFooter__logoBorder {
      width: 100%;
      height: 1px;
      margin-bottom: 20px;
      border-bottom: 1px solid $color--red-light;
    }
    .l-siteFooter__nav {
      margin-top: 60px;
      margin-bottom: 100px;

      @include bp('sm') {
        display: flex;
      }
      @include bp('lg') {
        margin-top: 0;
      }
    }
    .l-siteFooter__menu {
      margin-bottom: 40px;

      @include bp('sm') {
        min-width: 182px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .l-siteFooter__menuTitle {
      margin-bottom: 20px;
      font-size: rem(16);
    }
    .l-siteFooter__menuList {
      padding-left: 10px;

      li {
        margin-bottom: 12px;
        font-size: rem(14);

        &:last-child {
          margin-bottom: 0;
        }
      }
      a {
        color: inherit;
        text-decoration: none;

        @include focus() {
          text-decoration: underline;
        }
      }
    }
    .l-siteFooter__copyright {
      width: 100%;
      color: $color--gray;
      font-size: rem(11);

      @include bp('sm') {
        text-align: center;
      }
    }
  }
}