// @use '@assets/css/1-function' as *;

/* margin-bottom */
$margin-bottom-map: (
  mb: margin-bottom,
);
$margin-bottom-value-map: (
  1em: 1em,
  5: px(5),
  10: px(10),
  20: px(20),
  30: px(30),
  40: px(40),
  50: px(50),
  60: px(60),
  80: px(80),
  100: px(100),
);

@each $marginClassName, $marginPropName in $margin-bottom-map {
  @each $marginKeyName, $marginval in $margin-bottom-value-map {
    .u-#{$marginClassName}--#{$marginKeyName} {
      #{$marginPropName}: #{$marginval};
    }
  }
}

/* margin top */
$margin-top-map: (
  mt: margin-top,
);
$margin-top-value-map: (
  1em: 1em,
  5: px(5),
  10: px(10),
  20: px(20),
  30: px(30),
  40: px(40),
);

@each $marginClassName, $marginPropName in $margin-top-map {
  @each $marginKeyName, $marginval in $margin-top-value-map {
    .u-#{$marginClassName}--#{$marginKeyName} {
      #{$marginPropName}: #{$marginval};
    }
  }
}


/* margin side */
$margin-side-map: (
  mr: margin-right,
  ml: margin-left,
);
$margin-side-value-map: (
  1em: 1em,
  5: px(5),
  10: px(10),
  15: px(15),
  20: px(20),
);

@each $marginClassName, $marginPropName in $margin-side-map {
  @each $marginKeyName, $marginval in $margin-side-value-map {
    .u-#{$marginClassName}--#{$marginKeyName} {
      #{$marginPropName}: #{$marginval};
    }
  }
}
