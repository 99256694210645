$color--red: #BF0030;
$color--red-light: #CF0027;
$color--red-dark: #9F0028;
$color--gray: #787878;
$color--gray-light: #C8C8C8;
$color--white-dark: #F5F5F5;
$color--white-dark-light: #FAFAFA;
$color--white-dark-dark: #E6E6E6;
$color--blue: #0057A9;

$color--siteMain: $color--red;
$color--textLink: $color--blue;
// $color--caution: #AD002D;
// $color--success: #76b33f;

//PDFボタンなど
$color--gradation-silver: linear-gradient(#FFF, #F0F0F0);
