// @use '../1-function/unit' as Unit;

// Media Querie
@mixin mq($width) {

  @if is-unitless($width) {

    $width: em($width);

    @media screen and (min-width: $width) {
      @content;
    }
  } @else {

    @error "幅はピクセルの数値を入力します。単位は不要です";
  }
}

@mixin mqless($width, $less-than: true) {

  @if is-unitless($width) {

    $width: em($width);

    @if $less-than == false {
      $width: $width - 0.01em;
    }

    @media screen and (max-width: $width) {
      @content;
    }
  } @else {

    @error "幅はピクセルの数値を入力します。単位は不要です";
  }
}

@mixin mqonly($width-min, $width-max) {

  @if is-unitless($width-min) {

    @if is-unitless($width-max) {

      $width-min: em($width-min);
      $width-max: em($width-max);

      @media screen and (min-width: $width-min) and (max-width: $width-max) {
        @content;
      }
    } @else {

      @error "幅はピクセルの数値を入力します。単位は不要です";
    }

  } @else {

    @error "幅はピクセルの数値を入力します。単位は不要です";
  }
}
