.c-itemSlider {
  @at-root {
    .c-itemSlider__visual {
      margin-bottom: 20px;
      .slide-item {
        position: relative;

        &::before {
          content: "";
          display: block;
          padding-top: 79.175%;
        }
        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .c-itemSlider__nav {
      // display: flex;

      .slide-thumb {
        margin-right: 4px;
        margin-left: 4px;
      }
      .slide-thumb {
        border: 2px solid transparent;
        cursor: pointer;
      }
      .slick-current {
        border-color: $color--red;
      }
      .slick-arrow {
        position: absolute;
        top: 50%;
        z-index: 100;
        display: block;
        height: 24px;
        border: 0;
        background-color: transparent;
        color: #fff;
        font-size: 0;
        text-align: center;
        transform: translateY(-50%);
        opacity: 0.75;

        &::before {
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          overflow: hidden;
          border-radius: 50%;
          background: #fff url('../img/common/icon/arrow-dropright-circle.svg') 0 0  no-repeat;
        }
      }
      .slick-prev {
        left: 0;
        &::before {
          transform: scale(-1);
          // margin-left: -0.25em;
        }
      }
      .slick-next {
        right: 0;
        &::before {
          // margin-right: -0.25em;
        }
      }
      .slick-disabled {
        opacity: 0.35;
      }
    }

  }
}