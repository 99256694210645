.other_page { // .p-contact
  @at-root {
    .p-about__content {
    }
    .p-about__siteLogo {
      max-width: 140px;
      margin: 0 auto 40px;
    }
    .p-about__description {
      margin-bottom: 60px;
      font-size: rem(14);
    }
    .p-about__datalist {
      dt {
        @include bp('md') {
          max-width: 242px;
        }
      }
    }
  }
}