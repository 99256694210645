.p-homeNews {
  @at-root {
    & {
      padding: 40px 30px;
      border-radius: 6px;
      background-color: $color--white-dark;
      // max-width: 788px;
      // margin: auto;
      // padding: 38px 30px 42px;
    }
    .p-homeNews__header {
      margin-bottom: 20px;
    }
    // .p-homeNews__list {}
    .p-homeNews__item {
      margin-bottom: 10px;
    }
  }
}