.c-textLink {

  @at-root {
    & {
      color: inherit;
      font-size: inherit;
      text-decoration: underline;

      @include focus() {
        text-decoration: none;
      }
    }
  }

  @at-root {
    &.-primary {
      color: $color--textLink;
    }
  }

}
