.c-navHeader {
  @at-root {
    & {
      padding: 16px 30px;
      background-color: $color--red;
      color: #fff;
      line-height: 1.4;

      .label-en {
        margin-bottom: 4px;
        font-size: rem(14);
      }
      .label-ja {
        font-size: rem(16);
        font-weight: 700;
      }
    }

    &.-theme-gray {
      background-color: $color--gray;
    }
  }
}
