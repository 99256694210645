// @import "../mixins/media";
// @import "../mixins/projects";

/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
.ec-cartRole {
  @at-root {

    // & {
    //   &::before {
    //   }
    // }
    // & &__progress{
    //   width: 100%;
    //   text-align: center;
    // }
    .ec-cartRole__error {
      width: 100%;
      text-align: center;

      .ec-alert-warning {
        max-width: 80%;
        display: inline-block;
      }
    }
    .ec-cartRole__totalText {
      margin-bottom: 0;
      padding: 16px 0 6px;
      width: 100%;
      text-align: center;
      font-weight: normal;
      @include bp('md') {
        margin-bottom: 30px;
        padding: 0;
      }
    }
    .ec-cartRole__cart{
      margin: 0;
      width: 100%;
      // @include bp('md') {
      //   margin: 0 10%;
      // }

    }
    .ec-cartRole__actions{
      text-align: right;
      width: 100%;

      // @include bp('md') {
      //   width:  20%;
      //   margin-right: 10%;
      // }
    }
    .ec-cartRole__total{
      padding: 15px 0 30px ;
      font-weight: bold;
      font-size: 16px;
    }
    .ec-cartRole__totalAmount{
      margin-left: 30px;
      color: $color--red;
      font-size: 20px;

      @include bp('md') {
        font-size: 24px;
      }
    }
    .ec-cartRole__actions {
      .action-go {
        position: relative;
        display: block;
        width: 100%;
        max-width: rem(334);
        margin: 0 auto 30px;
        padding: 24px 36px;
        font-size: rem(18);
        text-align: center;

        @include bp('md') {
          margin-right: initial;
        }

        i {
          position: absolute;
          top: 50%;
          right: 30px;
          transform: translateY(-50%);
        }
      }
      // .ec-blockBtn--action {
      //   margin-bottom: 10px;
      // }
      .action-cancel {
        color: $color--gray;
        text-decoration: none;

        i {
          display: inline-block;
          margin-left: 15px;
        }
      }
    }

  }
}


/*
カート商品表示枠（テーブルヘッダ）

カート内の商品をを表示するテーブル枠です。

ex [カートページ　テーブル部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable{
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%;
  @include bp('md') {
    border-top: none;
  }
}


/*
カート商品表示枠（テーブルヘッダ）

カート内の商品を表示するテーブルのヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)


Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.3
*/
.ec-cartHeader {
  @at-root {
    & {
      display: none;
      width: 100%;
      background: $color--white-dark;

      @include bp('md') {
        display: table-row;
      }
    }
    .ec-cartHeader__label {
      display: table-cell;
      padding: 16px;
      text-align: center;
      background: #F4F3F0;
      overflow-x: hidden;
      font-weight: bold;
    }
  }
}
// .ec-cartCompleteRole {
//   @include container;
// }

/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/

.ec-cartRow {

  @at-root {

    & {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      padding-top: 30px;
      padding-left: 44px;
      padding-bottom: 90px;
      border-bottom: 1px solid $color--gray-light;
      font-size: rem(14);

      @include bp('md') {
        display: table-row;
        padding: 0;
        border-bottom: 0;
      }

      li {
        @include bp('md') {
          display: table-cell;
          padding-top: 30px;
          padding-bottom: 30px ;
          border-bottom: 1px solid $color--gray-light;
          vertical-align: middle;
        }
      }
    }

    .ec-cartRow__delColumn {
      width: 8.3333333%;
      text-align: center;

      @include bpless('md') {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        transform: translateY(-50%);
      }
      .ec-icon {
        display: block;
        padding: 15px;
        color: $color--gray;
        font-size: rem(14);
        text-decoration: none;
      }
    }
    .ec-cartRow__contentColumn {
      flex: 1;
    }
    .ec-cartRow__contentColumnInner {
      display: flex;
    }
    .ec-cartRow__img {
      max-width: 80px;
      margin-right: 20px;

      @include bp('md') {
        max-width: 120px;
        margin-right: 40px;
        vertical-align: top;
      }
    }
    .ec-cartRow__summary {
      width: calc(100% - 100px);

      @include bp('md') {
        max-width: 320px;
        vertical-align: top;
      }
      .ec-cartRow__name {
        margin-bottom: 10px;
        font-size: rem(18);
        font-weight: 700;
        line-height: 1.4;

        .product-name {
          margin-bottom: 10px;
        }
      }
      .ec-cartRow__unitPrice {
        margin-bottom: 20px;
      }
      .ec-cartRow__sutbtotalSP {
        position: absolute;
        bottom: 30px;
        right: 20px;
        display: block;
        width: calc(100% - 64px);
        padding: 20px;
        background-color: $color--white-dark;
        line-height: 1;
        text-align: right;
      }
    }
    .ec-cartRow__amountColumn {
      width: 100%;
      margin-bottom: 20px;

      @include bp('md') {
        width: 16.66666667%;
      }
    }
    .ec-cartRow__amountContainer {
      position: relative;
      width: 100%;
      max-width: 116px;
      margin-left: 100px;

      @include bp('md') {
        margin: auto;
        max-width: 76px;
      }
    }
    .ec-cartRow__amount {
      margin-bottom: 10px;
      text-align: center;

      @include bpless('md') {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        // display: block;
      }
    }
    .ec-cartRow__amountUpDown {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 116px;
      // transform: translateY(-50%);
    }
    .ec-cartRow__amountUpButton {
      margin: 0 2px;
      display: inline-block;
      // border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      // line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;

      // .ec-cartRow__amountUpButton__icon {
      //   img {
      //   }
      // }
    }
    .ec-cartRow__amountDownButton {
      margin: 0 2px;
      display: inline-block;
      // border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      // line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;

      // .ec-cartRow__amountDownButton__icon {
      //   img {
      //   }
      // }
    }
    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
      opacity: 0.5;
    }
    .ec-cartRow__subtotalColumn {
      display: none;
      // border-bottom: 1px dotted #ccc;
      text-align: right;
      width: 16.66666667%;

      @include bp('md') {
        display: table-cell;
        padding-right: 20px;
      }
    }

  }
}

/*
カート内商品(商品が１の場合)

商品が１の場合はカート商品を減らす「-」ボタンの無効化状態になります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRowOnly

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.5
*/

.ec-cartRow{
  & &__amountColumn{
    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
    }
  }
}

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.6
*/

.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #F99;
  margin-bottom: 20px;


  & &__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top;
  }
  & &__text {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
  }
}




/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.7
*/
