.c-listItem {
  @at-root {
    & {
      position: relative;
    }
    .c-listItem__top {
      position: relative;
    }
    .c-listItem__anchor {
      display: block;
      color: inherit;
      text-decoration: none;
    }
    .c-listItem__tags {
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: $zindex_list_tags;
    }
    .c-listItem__tagsItem {
      display: inline-block;
      margin-right: 2px;
      padding: 2px 8px;
      border: 2px solid currentColor;
      background-color: $color--white-dark;
      color: $color--red;
      font-size: rem(11);
      text-align: center;
      line-height: 1.4;
    }
    .c-listItem__visual {
      &::before {
        content: "";
        display: block;
        padding-top: 100%;
      }

      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $zindex_list_img;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .c-listItem__bottom {
      padding-top: 20px;
      line-height: 1.4;
    }
    .c-listItem__name {
      margin-bottom: 10px;
      font-size: rem(14);
      font-weight: 700;
    }
    .c-listItem__price {
      font-size: rem(14);

      span {
        display: inline-block;
        margin-left: 4px;
      }
    }
    .c-listItem__delete {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: $zindex_list_cross;
      display: inline-block;
      width: rem(28);
      height: rem(28);
      border-radius: 50%;
      background-color: $color--gray;
      box-shadow: 0 0 4px rgba(255, 255, 255, 0.4);
      color: #fff;
      font-size: rem(12);
      text-decoration: none;
      line-height: rem(28);
      text-align: center;
    }
  }
  @at-root {
    .c-listItem__anchor {
      @include focus() {
        .c-listItem__bottom {
          text-decoration: underline;
        }
      }
    }
  }
}