.c-card {
  @at-root {
    & {
      height: 100%;
      padding: 1px 6px 6px;
    }
    .c-card__anchor {
      display: block;
      color: inherit;
      text-decoration: none;
      box-shadow: 0 3px 6px rgba(0,0,0,0.24);
      transition: box-shadow 0.2s;

      @include focus() {
        box-shadow: 0 1px 2px rgba(0,0,0,0.24);
      }
    }
    .c-card__top {
      position: relative;
      padding: 20px;
      background-color: #fff;
      text-align: center;
    }
    .c-card__tags {
      position: absolute;
      top: 5px;
      left: 5px;
    }
    .c-card__tagsItem {
      display: inline-block;
      margin-right: 2px;
      padding: 2px 8px;
      border: 2px solid currentColor;
      background-color: rgba(white, 0.5);
      color: $color--red;
      font-size: rem(11);
      text-align: center;
      line-height: 1.4;
    }
    .c-card__visual {
      display: inline-block;
      max-width: 160px;
      height: 160px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .c-card__bottom {
      padding: 20px;
      background-color: $color--white-dark;
      line-height: 1.4;
    }
    .c-card__name {
      margin-bottom: 5px;
      font-size: rem(14);
    }
    .c-card__price {
      font-size: rem(18);

      span {
        display: inline-block;
        margin-left: 4px;
      }
    }
    .c-card__detail {
      display: block;
      max-width: 120px;
      margin: 18px auto 0;
      padding: 4px 20px;
      border-radius: rem(14);
      border: 1px solid #000;
      font-size: rem(12);
      text-align: center;
    }
  }
}