/*
生年月日選択

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　生年月日選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-birth
  select
    option ----
    option 1960
    option 1961
    option 1962
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...

Styleguide 3.2.5
*/
.ec-birth {
  // @extend .ec-input;
  display: flex;
  align-items: center;

  select {
    @include select-style();
    flex: 1;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    width: 28%;
    max-width: 120px;

    margin-bottom: 5px;

    &:focus {
      box-shadow: none;
    }
  }
  span {
    margin-right: 5px;
    margin-left: 5px;

    @include bp('sm') {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}