.p-homeSignon {
  @at-root {
    & {
      padding: 10px;
      background-color: $color--white-dark;
      text-align: center;

      a {
        width: 100%;
        max-width: 182px;
        margin: 5px;
        font-size: rem(14);
      }
    }
  }
}