// .c-modal {
//   @at-root {
//     // & {}
//     .c-modal__overlay {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width: 100%;
//       height: 100%;
//       background-color: rgba(0, 0, 0, 0.2);
//     }
//     .c-modal__wrap {
//       position: relative;
//       width: 100%;
//       max-width: 90%;
//       margin: auto;
//       padding: 60px 20px;
//       background-color: #fff;
//       box-shadow: 3px 6px 12px rgba(0,0,0,0.3);

//       @include bp('md') {
//         max-width: 586px;
//         padding: 60px 40px;
//       }
//     }
//     .c-modal__close {
//       position: absolute;
//       top: 20px;
//       right: 20px;
//       border: 0;
//       background-color: transparent;
//       color: $color--gray;
//     }
//     // .c-modal__box {}
//     .c-modal__actions {
//       margin: -15px -5px;
//       text-align: center;

//       a,button {
//         display: inline-block;
//         min-width: 224px;
//         margin: 15px 5px;
//         padding: 15px 40px;
//         font-size: rem(18);
//       }
//     }
//   }
// }

.ec-modal {
  @at-root {
    & {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99999;
      width: 100%;
      height: 100%;
    }
    .ec-modal-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
    }
    .ec-modal-wrap {
      position: relative;
      width: 100%;
      max-width: 90%;
      margin: auto;
      padding: 60px 20px;
      background-color: #fff;
      box-shadow: 3px 6px 12px rgba(0,0,0,0.3);

      @include bp('md') {
        max-width: 586px;
        padding: 60px 40px;
      }
    }
    .ec-modal-close {
      position: absolute;
      top: 20px;
      right: 20px;
      border: 0;
      background-color: transparent;
      color: $color--gray;
    }
    // .ec-modal-box {}
    .ec-modal-actions {
      margin: -15px -5px;
      text-align: center;

      a,button {
        display: inline-block;
        min-width: 224px;
        margin: 15px 5px;
        padding: 15px 40px;
        font-size: rem(18);
      }
    }
  }
}