.other_page { // .p-contact
  @at-root {
    .p-contact__datalist {
      margin-bottom: 60px;

      @include bponly('sm', 'lg') {
        padding-right: 20px;
        padding-left: 20px;
      }

      &.-input {
        dd {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }

      dt {
        @include bp('md') {
          max-width: 243px;
        }
      }
      // dd {}
    }
    // .p-contact__caution {}
  }
}