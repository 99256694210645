.c-btn {
  @at-root {
    & {
      @include btn-base();
      padding: 10px;
      border-radius: 4px;
      border: 0;
      background-color: $color--white-dark;

      @include focus() {
        box-shadow: $shadow--btn-hover;
      }

      & > i {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  @at-root {
    &.-red {
      background-color: $color--red;
      color: #fff;
    }
    &.-gray {
      background-color: $color--gray;
      color: #fff;
    }
    &.-gray-light {
      background-color: $color--gray-light;
      color: $color--gray;
    }
    &.-btn-md {
      width: 100%;
      max-width: rem(284);
    }
    &.-btn-lg {
      width: 100%;
      max-width: rem(334);

      @include bp('md') {
        max-width: rem(384);
      }
    }
  }
}