@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?6vfyeb') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?6vfyeb') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?6vfyeb##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconm-"], [class*=" iconm-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconm-exclamation-circle {
  &:before {
    content: $iconm-exclamation-circle;
  }
}
.iconm-external-link {
  &:before {
    content: $iconm-external-link;
  }
}
.iconm-heart {
  &:before {
    content: $iconm-heart;
  }
}
.iconm-cross-bold {
  &:before {
    content: $iconm-cross-bold;
  }
}
.iconm-cross {
  &:before {
    content: $iconm-cross;
  }
}
.iconm-email {
  &:before {
    content: $iconm-email;
  }
}
.iconm-home {
  &:before {
    content: $iconm-home;
  }
}
.iconm-lock {
  &:before {
    content: $iconm-lock;
  }
}
.iconm-menu {
  &:before {
    content: $iconm-menu;
  }
}
.iconm-person {
  &:before {
    content: $iconm-person;
  }
}
.iconm-search {
  &:before {
    content: $iconm-search;
  }
}
.iconm-cart {
  &:before {
    content: $iconm-cart;
  }
}
.iconm-arrow {
  &:before {
    content: $iconm-arrow;
  }
}
.iconm-double-arrow {
  &:before {
    content: $iconm-double-arrow;
  }
}
.iconm-hard-hat {
  &:before {
    content: $iconm-hard-hat;
    color: #4b4b4b;
  }
}