.p-cartNaviItem {
  @at-root {
    & {
      margin-bottom: 16px;
      padding-bottom: 32px;
      border-bottom: 1px solid #E8E8E8;
      overflow: hidden;
    }
    .p-cartNaviItem__image {
      float: left;
      width: 40%;
      img {
        width: 100%;
      }
    }
    .p-cartNaviItem__content {
      float: right;
      width: 60%;
      padding-left: 16px;
      text-align:left;
    }
    .p-cartNaviItem__contentTitle {
      margin-bottom: 4px;
      font-size: rem(14);
    }
    .p-cartNaviItem__contentPrice {
      margin-bottom: 4px;
      font-weight: 700;
      font-size: rem(14);
    }
    .p-cartNaviItem__contentTax {
      margin-bottom: 4px;
      display: inline-block;
      font-weight: 400;
      font-size: rem(12);
    }
    .p-cartNaviItem__contentNumber {
      font-size: rem(12);
    }
  }
}