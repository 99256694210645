// $fw--200: 200;
// $fw--300: 300;
// $fw--400: 400;
// $fw--500: 500;
// $fw--600: 600;
// $fw--700: 700;
// $fw--800: 800;
// $fw--900: 900;

$ff--sans: 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'Yu Gothic', sans-serif;
$ff--serif: 'Noto Serif JP', 'Hiragino Mincho ProN', 'Yu Mincho', serif;
$ff--ropa: 'Ropa Sans', sans-serif;;

$lh--primaryText: 1.6;
$lh--primaryTitle: 1.3;
