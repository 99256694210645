/*
水平定義リスト

シンプルな定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　水平定義リスト部分](http://demo3.ec-cube.net/help/about)

Markup:
dl.ec-definitions
    dt 店名
    dd EC-CUBE3 DEMO SHOP
dl.ec-definitions
    dt 会社名
    dd EC-CUBE3
dl.ec-definitions--soft
    dt 所在地
    dd 〒 550-0001

Styleguide 1.3.1
*/
.ec-definitions {
  margin: 5px 0;
  display: block;
  & dt, dd {
    display: inline-block;
    margin: 0;
    font-size: rem(14);
  }
  & dt {
    font-weight: bold;

    &::after {
      content: ":";
      margin-right: 0.5em;
      margin-left: 0.5em;
    }
  }
}

.ec-definitions--soft {
  @extend .ec-definitions;
  & dt {
    font-weight: normal;
  }
}
