.mypage { //p-mypageWithdraw
  @at-root {
    .p-mypageWithdraw__content {
      max-width: 626px;
      margin: auto;
    }
    .p-mypageWithdraw__icon {
      max-width: 90px;
      margin: 0 auto 60px;
    }
    .p-mypageWithdraw__actions {
      max-width: rem(284);
      margin: auto;

      .btn-a {
        width: 100%;
        padding: 18px;
      }
      .btn-b {
        width: 100%;
        margin-top: 40px;
      }
    }
  }
}
