.c-headingQuinary {
  @at-root {
    & {
      margin-bottom: 20px;
      padding-bottom: 10px;
      padding-left: 10px;
      color: $color--red;
      font-size: rem(16);
      font-weight: 700;
      border-bottom: 2px solid $color--red;
    }
  }
}