.p-breadcrumb {
  @at-root {
    // & {}
    .p-breadcrumb__item {
      display: inline-block;
      color: $color--gray;
      font-size: rem(11);

      & + .p-breadcrumb__item {

        &::before {
          content: "\e909";
          display: inline-block;
          margin-right: 4px;
          margin-left: 4px;
          font-family: '#{$icomoon-font-family}';
          font-size: rem(8);
        }
      }

      a {
        color: inherit;
        text-decoration: none;

        @include focus() {
          text-decoration: underline;
        }
      }
      span {
        color: #000;
      }
    }
  }
}