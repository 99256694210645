.p-cartNavi {
  @at-root {
    & {
      position: relative;
    }
    .p-cartNavi__toggle {
      position: relative;
      padding: 20px 20px 8px;
      border-radius: rem(4);
      border: 0;
      background-color: $color--white-dark;

      @include focus() {
        opacity: 0.65;
      }
      @include bp('md') {
        display: flex;
        align-items: center;
        min-width: 180px;
        padding: 13px 15px;
      }
    }
    .p-cartNavi__toggleIcon {
      display: flex;
      align-items: center;
      font-size: rem(24);
    }
    .p-cartNavi__togglelabel {
      display: block;
      font-size: rem(11);
      text-align: center;
    }
    .p-cartNavi__toggleBadge {
      display: inline-block;
      min-width: 26px;
      height: rem(16);
      margin-right: 4px;
      margin-left: 8px;
      padding-right: 6px;
      padding-left: 6px;
      border-radius: rem(8);
      background-color: $color--red;
      color: #fff;
      font-size: rem(10);
      line-height: rem(16);
      text-align: center;

      @include bpless('md') {
        position: absolute;
        top: 3px;
        right: 3px;
      }
    }
    .p-cartNavi__togglePrice {
      flex: 1;
      font-size: rem(18);
      text-align: right;

      .price-number {}
      .price-currency-unit {
        display: inline-block;
        font-size: rem(10);
      }
    }
    .p-cartNavi__content {
      position: absolute;
      right: 0;
      z-index: 20;
      width: 100%;
      min-width: 270px;
      max-width: 270px;
      margin-top: 10px;
      padding: 16px;
      background: $color--white-dark;
      box-shadow: 0 1px 4px rgb(0 0 0 / 20%);

      &::before {
        content: "";
        position: absolute;
        top: -9px;
        right: 20px;
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 12px 8px;
        border-color: transparent transparent $color--white-dark transparent;
      }
    }
    .p-cartNavi__action {
      max-width: 160px;
      margin: auto;
      font-size: rem(14);
      text-align: center;

      a {
        display: block;
        margin-bottom: 10px;
      }
      button {
        width: 100%;
      }
    }
  }

  @at-root {
    .p-cartNavi__content {
      display: none;
      &.is-active {
        display: block;
      }
    }
  }
}