.c-headingPrimary {
  @at-root {
    & {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 889px;
      min-height: 120px;
      margin-right: auto;
      margin-left: auto;
      padding: 20px 40px;
      border: 4px solid currentColor;
      background-color: #fff;
      color: $color--red;
      text-align: center;

      &::before {
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 36px 36px 0 0;
        border-color: transparent;
        border-top-color: currentColor;
      }
    }
    .c-headingPrimary__label {
      font-size: rem(24);
      font-weight: 700;
    }
  }
}