.p-loginForm {
  @at-root {
    // & {
    // }
    & {
      max-width: 505px;
      margin: auto;
    }
    .p-loginForm__actions {
      max-width: rem(284);
      margin: 30px auto 0;
      font-size: rem(16);

      button {
        display: block;
        width: 100%;
        padding: 18px;
      }
      a {
        display: block;
        font-size: rem(14);
        margin-top: 5px;
      }
    }
  }
}