.p-listCategories {
  @at-root {
    // & {}
    .p-listCategories__item {
      display: inline-block;
      margin: 5px 2px;
      padding: 1px 2px 4px;

      @include bp('md') {
        min-width: rem(182);
        margin: 5px 10px;
      }
    }
  }
}