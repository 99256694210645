// _20.1.404.scss

.ec-404Role {
  @at-root {
    & {
      font-size: rem(14);
      line-height: 1.8;
    }
    .ec-404Role__image {
      max-width: 398px;
      margin: 0 auto 50px;
    }
    .ec-404Role__button {
      a {
        position: relative;
        display: block;
        margin: auto;
        padding: 24px 36px;
        font-size: rem(18);
        font-weight: bold;
      }
      i {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
      }
    }
  }
}
